import React from "react";

export default function Loader({ showLoadingText }) {
  return (
    <div className="spinner-loader">
      <div className="spinner"></div>
      {showLoadingText && <div className="loading_txt">Layers Loading</div>}
    </div>
  );
}
