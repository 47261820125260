import React, { Component, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import { globalHistory } from "@reach/router";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import getLayersData from "../Common/NewMapDesign/API/layerDataGetApi";
import Select from "react-select";
import { SketchPicker } from "react-color";
import CreatableSelect from "react-select/creatable";

function TreeTiesAddProjectForms1Lay(props) {
  const initialState = {
    layerName: "",
    layerType: "",
    isOnlyTitle: false,
    fileName: "",
    fileType: "",
    legend: [],
  };

  const [layerData, setLayerData] = useState({
    isShowCount: false,
    isFilterApply: false,
    isOnlyTitle: false,
  });
  const [projectId, setProjectId] = useState("");
  const [rowLandParcelList, setRowLandParcelList] = useState([]);
  const fileExtensions = ["png", "geojson", "kml", "jpeg"];
  const [selectedExtension, setSelectedExtension] = useState("Select");
  const [guid, setGuid] = useState("");
  const [ProjectLayers, setProjectLayers] = useState([]);
  const [speciesData, SetSpeciesData] = useState([]);
  const [parentLayersData, setParentLayersData] = useState([]);
  const [uploadfile, setUploadfile] = useState([]);
  const [fileControl, setFileControl] = useState(null);
  const [fileNameControl, setFileNameControl] = useState("");
  const [showCount, setShowCount] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [LayerId, setLayerId] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const fileInputRef = useRef(null);
  const [layerNamedrpdwn, setLayerNamedrpdwn] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState("");
  const [showNewLayerForm, setShowNewLayerForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [createSpecies, setCreateSpecies] = useState([]);
  const [isAddGridDisabled, setIsAddGridDisabled] = useState(false);
  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [errorMapbound, setErrorMapbound] = useState("");
  const [sampleFileData, setSampleFiledata] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
  const closeTooltip = () => {
    setShowTooltip(false);
  };
  const validateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    if (!layerData?.layerName) {
      ToastNotification.ShowError("Layer name is required.");
      isValid = false;
    }
    if (fileNameControl === "") {
      ToastNotification.ShowError("File is required.");
      isValid = false;
    }
    // if (layerData?.layerType === "LayerMarker") {
    //   if (!layerData?.speciesName) {
    //     ToastNotification.ShowError("Select Species Name.");
    //     isValid = false;
    //   }
    // }
    if (layerData?.fileType === "png") {
      if (
        layerData?.mapBounds === "" ||
        layerData?.mapBounds === null ||
        layerData?.mapBounds === undefined
      ) {
        ToastNotification.ShowError(
          "Enter map bounds value with correct format."
        );
        isValid = false;
      }
    }
    if (layerData?.sequence) {
      const isDuplicate = ProjectLayers.some(
        (item) =>
          Number(item?.sequence) === Number(layerData?.sequence) &&
          item?.layerGuid !== layerData.layerGuid
      );
      if (isDuplicate) {
        ToastNotification.ShowError("Sequence value is already in use.");
        isValid = false;
      }
    } else {
      // Find the maximum sequence number in ProjectLayers
      const maxSequence = ProjectLayers.reduce((max, item) => {
        const sequence = item?.sequence ?? 0;
        return sequence > max ? sequence : max;
      }, 0);

      // Assign the next sequence number
      layerData.sequence = maxSequence + 1;
    }
    return isValid;
  };

  const clearFile = () => {
    fileInputRef.current.value = "";
    setFileControl(null);
    setFileNameControl("");
    setFileSelected(false);
    setUploadfile([]);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files;
    const array = [];
    if (selectedFile && selectedFile?.[0]?.name) {
      const fileName = selectedFile?.[0]?.name;
      const fileExtension = fileName?.split(".").pop().toLowerCase();
      const maxSize = 50 * 1024 * 1024; // 50MB in bytes

      if (layerData.fileType === fileExtension) {
        if (selectedFile?.[0]?.size > maxSize) {
          setError("File size exceeds 50MB limit.");
          clearFile();
        } else {
          setError("");
          setFileControl(selectedFile);
          setFileNameControl(selectedFile?.[0]?.name);
          setFileSelected(true);
          array.length = 0;
          if (selectedFile.length > 0) {
            array.push(selectedFile?.[0]);
          }
          setUploadfile(array);
          handleLayerTypeActions(selectedFile);
        }
      } else {
        setError("Invalid file type. Allowed selected file type only");
        clearFile();
      }
    }
    // else {
    //   fileInputRef.current.value = fileNameControl;
    //   //   clearFile();
    // }
  };

  const handleLayerTypeActions = (selectedFile) => {
    if (layerData.layerType === "LayerMarker") {
      fetchTreeData(selectedFile);
    } else if (
      layerData.layerType === "polygon" &&
      layerData.fileType === "xlsx"
    ) {
      fetchBoundaryData(selectedFile);
    } else if (layerData.layerType === "gridLayer") {
      fetchSamplingGridValidate(selectedFile);
    }
  };

  const fetchSamplingGridValidate = async (selectedFile) => {
    const samplingGrid = await SaveSamplingGridValidation(
      projectId,
      selectedFile,
      layerData?.sampleFile
    );
    if (JSON.parse(samplingGrid?.success) === false) {
      setError(JSON.parse(samplingGrid?.message));
      clearFile();
    } else {
      setError("");
    }
  };

  const fetchTreeData = async (selectedFile) => {
    const treeDataRes = await SaveTreeData(
      projectId,
      selectedFile,
      layerData?.sampleFile
    );
    if (JSON.parse(treeDataRes?.success) === false) {
      setError(JSON.parse(treeDataRes?.message));
      clearFile();
    } else {
      setError("");
      updateLayerData(treeDataRes);
    }
  };
  const fetchBoundaryData = async (selectedFile) => {
    const boundarResp = await SaveProjectBoundaryValidation(
      projectId,
      selectedFile,
      layerData?.sampleFile
    );
    if (JSON.parse(boundarResp?.success) === false) {
      setError(JSON.parse(boundarResp?.message));
      clearFile();
    } else {
      setError("");
      updateLayerData(boundarResp);
    }
  };

  const updateLayerData = (responseData) => {
    setLayerData((prevData) => ({
      ...prevData,
      speciesName: responseData?.speciesNameList || prevData.speciesName,
    }));
  };

  useEffect(() => {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    setProjectId(Id);
  }, []);

  useEffect(() => {
    const randomGuid = uuidv4();
    setGuid(randomGuid);
  }, []);

  useEffect(() => {
    // GetAllSpeciesData();
    GetLayerMaster();
  }, [projectId, editMode]);

  const GetLayerMaster = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetLayerMaster`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000, // Timeout in milliseconds
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        if (responseData.length > 0) {
          setLayerNamedrpdwn(responseData);
          const fetchData = async () => {
            const projectLayerRes = await getLayersData(projectId);
            if (!editMode) {
              filterLayerDrpdwn(projectLayerRes?.data, responseData);
            }
          };
          if (projectId) {
            fetchData();
          }
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const filterLayerDrpdwn = (projectLayerRes, layerNameMst) => {
    const layerNameFilter = layerNameMst?.filter(
      (dropItem) =>
        !projectLayerRes.some(
          (gridItem) => dropItem.layerName === gridItem.layerName
        )
    );
    setLayerNamedrpdwn(layerNameFilter);
  };

  const handleMapBounds = (mapBounds) => {
    if (Array.isArray(mapBounds)) {
      return mapBounds;
    } else if (typeof mapBounds === "string") {
      return mapBounds?.split(",").map((coord) => parseFloat(coord));
    }
    return [];
  };

  const AddLayersDetails = async (e) => {
    if (layerData.fileType === "png") {
      var stringMapBounds = layerData?.mapBounds;
      const mapBoundsArray = handleMapBounds(stringMapBounds);
      layerData.mapBounds = mapBoundsArray;
    }
    if (fileSelected === true) {
      const fileResponse = await UploadLayerDocument(e);
      if (fileResponse?.success) {
        layerData.fileName = fileResponse?.UploadFileName;
        if (layerData.layerType === "LayerMarker") {
          await SaveAllTreeDetails(projectId, fileResponse?.UploadFileName);
        }
        if (layerData.layerType === "polygon") {
          await SaveProjectBoundary(projectId, fileResponse?.UploadFileName);
        }
        if (layerData.layerType === "gridLayer") {
          await SaveProjectSamplingGrid(
            projectId,
            fileResponse?.UploadFileName
          );
        }
        if (
          layerData.layerType === "polygon" &&
          layerData.fileType === "geojson"
        ) {
          await SaveBoundaryGeojsonFile(
            projectId,
            fileResponse?.UploadFileName
          );
        }
      }
    } else {
      layerData.fileName = fileNameControl;
    }
    if (LayerId === "") {
      const randomGuid = uuidv4();
      layerData.layerGuid = randomGuid;
    } else {
      layerData.layerGuid = LayerId;
    }

    layerData.projectId = projectId;
    layerData.projectName = projectId;
    layerData.legend = rows;
    if (layerData?.fileType === "xlsx") {
      if (layerData.layerType === "polygon") {
        layerData.fileType = "xlsx";
      } else {
        layerData.fileType = "db";
      }
    }

    const isLayerNameExists = ProjectLayers.some(
      (item) =>
        item?.layerName === layerData?.layerName &&
        item.layerGuid !== layerData.layerGuid
    );
    if (layerData.layerGuid !== null && layerData.fileName !== null) {
      setIsLoading(true);
      let data = layerData;
      let url = `${config.API_HOST_URL}/api/Ecosystem/AddLayerDetails`;
      fetch(url, {
        method: "POST",
        headers: {
          WebsiteGuid: props.websiteGUID,
          LanguageGuid: props.languageGUID,
          Authorization: `Bearer ${props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp.success === true) {
            clearAll();
            getLayerDetailList();
            GetLayerMaster();
            setIsAddGridDisabled(false);
            setIsLoading(false);
            ToastNotification.ShowSuccess(resp.message);
          } else {
            ToastNotification.ShowError(resp.message);
          }
        })
        .then(() => {
          setIsLoading(false); // <--- Set isLoading to false here
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }
  };

  const GetAllSpeciesData = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllSpeciesData`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        SetSpeciesData(responseData);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const getLayerDetailList = async () => {
    const response = await getLayersData(projectId);
    setProjectLayers(response?.data);
    // setProjectLayers((prevLayers) => [...prevLayers, ...response?.data]);
  };

  useEffect(() => {
    const fetchData = async () => {
      getLayerDetailList();
      //let responseData = response?.data;
      // var parentLayerData = responseData
      //   .filter((x) => x.isOnlyTitle === true)
      //   .map((x) => ({
      //     layerName: x.layerName,
      //     layerGuid: x.layerGuid,
      //   }));
      // setParentLayersData(parentLayerData);
    };
    if (projectId) {
      fetchData();
    }
  }, [projectId]);

  const handleKeyDown = (event) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
    ];
    if (
      !allowedKeys.includes(event.key) &&
      !(event.key >= 0 && event.key <= 100)
    ) {
      event.preventDefault();
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    let newData = { ...layerData };
    newData[name] = value;
    setLayerData(newData);
    // Validate mapBounds value
    if (name === "mapBounds") {
      const validChars = /^[-\d.,]*$/;
      if (!validChars.test(value)) {
        setErrorMapbound(
          "Only numbers, commas, periods, and minus signs are allowed."
        );
        return;
      }
      const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?,-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
      if (!regex.test(value)) {
        setErrorMapbound(
          "Invalid format. Please use the format: 15.250110379,74.077749917,15.257275915,74.082876954"
        );
      } else {
        setErrorMapbound("");
      }
    }
    setLayerData({
      ...layerData,
      [name]: value,
    });
  };
  const handleChangeRbtn = (e) => {
    const name = e.target.name;
    const value = e.target.value === "true";
    let newData = { ...layerData };
    newData[name] = value;
    setLayerData(newData);
  };
  const handleChangeForDrp = (selectedOption) => {
    setLayerData((prevData) => ({
      ...prevData,
      speciesName: selectedOption,
    }));
  };

  const handleCreate = (inputValue) => {
    const newOption = inputValue.toLowerCase().trim();
    var speciesExits = speciesData?.filter(
      (x) => x.speciesName?.toLowerCase() === newOption
    );
    if (newOption !== null && speciesExits.length !== 1) {
      AddNewTreeSpecies(newOption);
      setTimeout(() => {
        GetAllSpeciesData();
      }, 1000);
    }
  };

  const AddNewTreeSpecies = (newSpeciesData) => {
    const randomGuid = uuidv4();
    let SpeciesGuid = randomGuid;
    let SpeciesName = newSpeciesData;
    let data = {
      speciesGuid: SpeciesGuid,
      speciesName: SpeciesName,
    };
    let url = `${config.API_HOST_URL}/api/Ecosystem/AddNewTreeSpecies`;
    fetch(url, {
      method: "POST",
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success === true) {
        } else {
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  };

  const Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1ImageUpload/:" + projectId, {
      state: { activeMenu: 11 },
    });
  };

  const Back = () => {
    navigate("/TreeTiesAddProjectForms1Cos/:" + projectId, {
      state: { activeMenu: 9 },
    });
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: initialState,
  });

  const AddToGrid = (e) => {
    e.preventDefault();
    if (validateForm(e)) {
      setIsLoading(true); // set loading state to true
      setIsAddGridDisabled(true);
      setSaveBtnDisabled(false);
      // if (LayerId === null || LayerId === "") {
      //   const isDuplicate = ProjectLayers.some(
      //     (item) => item.layerName === layerData?.layerName
      //   );
      //   if (!isDuplicate) {
      //     let newItem = {
      //       layerName: layerData?.layerName,
      //       layerType: layerData?.layerType,
      //       fileType: layerData?.fileType,
      //       isOnlyTitle: layerData?.isOnlyTitle,
      //       layerInfo: layerData?.layerInfo,
      //       sequence: layerData?.sequence,
      //     };
      //     //ProjectLayers.push(newItem);
      //     // const extractedList = ProjectLayers.map((item) => ({
      //     //   layerName: item.layerName,
      //     //   layerType: item.layerType,
      //     //   fileType: item.fileType,
      //     //   isOnlyTitle: item.isOnlyTitle,
      //     //   layerInfo: item?.layerInfo,
      //     //   sequence: item?.sequence,
      //     // }));
      //     // updateProjectLayers(extractedList);
      //     //ToastNotification.ShowSuccess("Layer added in grid, Please save");
      //   }
      // } else {
      //   const isDuplicate = ProjectLayers.some(
      //     (item) =>
      //       item.layerName === layerData?.layerName &&
      //       item.layerGuid !== LayerId
      //   );
      //   if (!isDuplicate) {
      //     const newState = ProjectLayers.map((obj) => {
      //       if (obj.layerGuid === LayerId) {
      //         return {
      //           ...obj,
      //           layerName: layerData?.layerName,
      //           layerType: layerData?.layerType,
      //           fileType: layerData?.fileType,
      //           isOnlyTitle: layerData?.isOnlyTitle,
      //           layerInfo: layerData?.layerInfo,
      //           sequence: layerData?.sequence,
      //         };
      //       }
      //       return obj;
      //     });
      //     setProjectLayers(newState);
      //   }
      //   // ToastNotification.ShowSuccess("Layer updated in Grid, Please save");
      // }
      AddLayersDetails();
    }
  };

  const clearAll = () => {
    setLayerData({
      isShowCount: false,
      isFilterApply: false,
      isOnlyTitle: false,
      layerName: "",
      layerType: "",
      sampleFile: "",
      fileType: "",
      layerInfo: "",
      sequence: "",
    });
    clearFile();
    setRows([]);
    setEditMode(false);
  };

  const updateProjectLayers = (newData) => {
    setProjectLayers(newData);
  };

  const SaveProjectBoundaryValidation = async (
    projectId,
    selectedFile,
    sampleFile
  ) => {
    const originalPath = sampleFile;
    const newSamplePath = originalPath.startsWith("/")
      ? originalPath.slice(1)
      : originalPath;
    let file = selectedFile;
    const array = [];
    array.length = 0;
    if (selectedFile.length > 0) {
      array.push(selectedFile[0]);
    }
    const formData = new FormData();
    for (var index = 0; index < file.length; index++) {
      var element = file[index];
      formData.append("formFiles", element);
      formData.append("projectId", projectId);
      formData.append("sampleFilePath", newSamplePath);
    }
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveProjectBoundaryValidation/`;

    let fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        ContentType: "multipart/form-data",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };
  const SaveProjectBoundary = async (projectId, fileName) => {
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveProjectBoundary/${projectId}/${fileName}`;
    let fetchOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };
  const SaveBoundaryGeojsonFile = async (projectId, fileName) => {
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveBoundaryGeojsonFile/${projectId}/${fileName}`;
    let fetchOptions = {
      method: "Post",
      headers: {
        Accept: "application/json",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const SaveSamplingGridValidation = async (
    projectId,
    selectedFile,
    sampleFile
  ) => {
    const originalPath = sampleFile;
    const newSamplePath = originalPath?.startsWith("/")
      ? originalPath?.slice(1)
      : originalPath;
    let file = selectedFile;
    const array = [];
    array.length = 0;
    if (selectedFile.length > 0) {
      array.push(selectedFile[0]);
    }
    const formData = new FormData();
    for (var index = 0; index < file.length; index++) {
      var element = file[index];
      formData.append("formFiles", element);
      formData.append("projectId", projectId);
      formData.append("sampleFilePath", newSamplePath);
    }
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveProjectGridData`;
    let fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        ContentType: "multipart/form-data",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data !== null) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const SaveProjectSamplingGrid = async (projectId, fileName) => {
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveProjectSamplingGrid/${projectId}/${fileName}`;
    let fetchOptions = {
      method: "Get",
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data !== null) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const SaveAllTreeDetails = async (projectId, fileName) => {
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveAllTreeDetails/${projectId}/${fileName}`;
    let fetchOptions = {
      method: "Get",
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data !== null) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const SaveTreeData = async (projectId, selectedFile, sampleFile) => {
    const originalPath = sampleFile;
    const newSamplePath = originalPath?.startsWith("/")
      ? originalPath?.slice(1)
      : originalPath;
    let file = selectedFile;
    const array = [];
    array.length = 0;
    if (selectedFile.length > 0) {
      array.push(selectedFile[0]);
    }
    const formData = new FormData();
    for (var index = 0; index < file.length; index++) {
      var element = file[index];
      formData.append("formFiles", element);
      formData.append("projectId", projectId);
      formData.append("sampleFilePath", newSamplePath);
    }
    let uri = `${config.API_HOST_URL}/api/Ecosystem/SaveTreeData`;
    let fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        ContentType: "multipart/form-data",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data !== null) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const UploadLayerDocument = async (e) => {
    let file = uploadfile;
    const formData = new FormData();
    for (var index = 0; index < file.length; index++) {
      var element = file[index];
      formData.append("FormFiles", element);
    }

    let uri =
      `${config.API_HOST_URL}/api/ProjectTreeTypes/UploadDocument/` + projectId;
    let fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        ContentType: "multipart/form-data",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };

  const setFile = async (e) => {
    let filesArr = e.target.files;
    const array = [];
    // Clear the array if there's already a file
    if (filesArr.length > 0) {
      array.push(filesArr[0]);
    }
    setUploadfile(array);
  };

  // const setFile = async (e) => {
  //   let filesArr = e.target.files;
  //   const array = [...uploadfile];
  //   array.push(filesArr);
  //   setUploadfile(array);
  //   const filesize = array.length;
  //   if (filesize === 1) {
  //     for (let i = 0; i < filesArr.length; ++i) {
  //       const myFilesList = [...uploadfile];
  //       myFilesList.push(filesArr[i]);
  //       setUploadfile(myFilesList);
  //     }
  //   } else {
  //     if (e.target.id === "uploadjsonfile") {
  //       const index = array.indexOf(e.target.value, 1);
  //       array.splice(index, 1);
  //       setUploadfile(array);
  //       // ToastNotification.ShowWarning("Select only one file");
  //     }
  //   }
  //   // e.target.value = null;
  //   //let datares = await UploadLayerDocument();
  // };

  const handleAddRow = (e) => {
    e.preventDefault();
    const newRow = {
      legendId: rows.length,
      name: "",
      colorCode: "",
    };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (e, idToRemove) => {
    e.preventDefault();
    const updatedRows = rows.filter((row) => row.legendId !== idToRemove);
    setRows(updatedRows);
  };

  const handleColorBoxClick = (e, id) => {
    e.preventDefault();
    setSelectedRowId(id);
  };

  const handleColorChange = (color) => {
    const updatedRows = rows?.map((row) => {
      if (row.legendId === selectedRowId) {
        return {
          ...row,
          colorCode: color.hex,
        };
      }
      return row;
    });
    setRows(updatedRows);
    setSelectedRowId(null);
  };
  const handleInputChange = (id, field, value) => {
    const updatedRows = rows?.map((row) => {
      if (row.legendId === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const layerNameHandleChange = (e) => {
    if (e.target.value !== "") {
      setIsAddGridDisabled(false);
      let layerFilter = layerNamedrpdwn.filter(
        (x) => x.layerName === e.target.value
      );
      setLayerData({
        layerName: e.target.value,
        fileType: layerFilter[0]?.fileType,
        layerType: layerFilter[0]?.layerType,
        sampleFile: layerFilter[0]?.sampleFile,
        isShowCount: layerFilter[0]?.showCount,
        isFilterApply: layerFilter[0]?.isFilterApply,
        layerInfo: layerFilter[0]?.layerInfo,
        // sequence: layerFilter[0]?.sequence,
      });
      setShowCount(layerFilter[0]?.showCount);
      setFileControl(null);
      setFileNameControl("");
      setFileSelected(false);
      setUploadfile([]);
      setError("");
    } else {
      clearAll();
    }
  };

  const downloadSampleFile = (e) => {
    e.preventDefault();
    if (!layerData.layerName) {
      ToastNotification.ShowError("Please Select Layer Name");
    } else {
      if (layerData.sampleFile === null || layerData.sampleFile === undefined) {
        layerData.sampleFile = sampleFileData;
      }
      let FileName = layerData?.layerName + "." + layerData?.fileType;
      let url = config.Amazon_WebService_CDNUrl + layerData?.sampleFile;
      fetch(url).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = FileName;
          a.click();
        });
      });
    }
  };

  const deleteLayer = (layerId, i) => {
    if (
      window.confirm("Are you sure you want to delete this  Layer Details?")
    ) {
      if (layerId !== null && layerId !== undefined) {
        let url =
          `${config.API_HOST_URL}/api/Ecosystem/DeleteLayerDetails/` +
          layerId +
          "/" +
          projectId;
        fetch(url, {
          method: "Post",
          headers: {
            WebsiteGuid: props.websiteGUID,
            LanguageGuid: props.languageGUID,
            Authorization: `Bearer ${props.token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => response.json())
          .then((resp) => {
            if (resp.success === true) {
              const fetchDataRefresh = async () => {
                clearAll();
                getLayerDetailList();
                GetLayerMaster();
              };
              fetchDataRefresh();
              ToastNotification.ShowSuccess(resp.message);
            } else {
              ToastNotification.ShowError(resp.message);
            }
          })
          .catch((error) => {
            console.log("Request failed", error);
          });
      } else {
        setProjectLayers((prevList) =>
          prevList.filter((x) => x.layerName !== ProjectLayers[i].layerName)
        );
        const fetchDataRefresh = async () => {
          clearAll();
          getLayerDetailList();
          GetLayerMaster();
        };
        fetchDataRefresh();
        ToastNotification.ShowSuccess("Layer Detail Deleted Successfully");
      }
    }
  };

  const editLayer = (i) => {
    setEditMode(true);
    const sampFile =
      layerNamedrpdwn.find((x) => x.layerName === ProjectLayers[i].layerName)
        ?.sampleFile ?? null; // or some other default value
    setSampleFiledata(sampFile);
    setLayerData({ sampleFile: sampFile });

    const url = getFileFromS3Path(
      ProjectLayers[i]?.projectId,
      ProjectLayers[i]?.fileName
    );
    setLayerId(ProjectLayers[i]?.layerGuid);
    if (ProjectLayers[i]?.fileType === "db") {
      ProjectLayers[i].fileType = "xlsx";
    }
    setLayerData(ProjectLayers[i]);
    setRows(ProjectLayers[i].legend);
    setFileControl(url);
    setFileNameControl(ProjectLayers[i]?.fileName);

    const selectedSpecies = ProjectLayers[i]?.speciesName?.map((species) => ({
      value: species?.speciesGuid,
      label: species?.speciesName,
    }));
    setLayerData({
      ...ProjectLayers[i],
      speciesName: selectedSpecies,
    });
  };

  const getFileFromS3Path = (ProjectId, FileName) => {
    if (ProjectId !== null && FileName !== null) {
      let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/${ProjectId}/${FileName}`;
      fetch(url).then((response) => {
        response.blob().then((blob) => {
          const file = new File([blob], FileName, { type: blob.type });
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          fileInputRef.current.files = dataTransfer.files;
          return fileInputRef.current.files;
        });
      });
    }
  };

  const SaveContinue = () => {
    Nextpage();
  };

  const Loader = () => {
    return (
      <div className="spinner-loader">
        <div className="spinner"></div>
      </div>
    );
  };

  return (
    <div className="treeties-addproject-forms1-loc ecosyscom_cont ecosyscomlayers_cont">
      {/* <Header/> */}
      <div className="main-content-area6">
        {/* <LeftNavbar/>  */}
        {isLoading && (
          <div className="loader-overlay">
            <Loader />
          </div>
        )}
        <div className="middle-content-area6">
          <div className="container-18 ecoprosumry_cont">
            <ProjectSummary />
          </div>
          <div className="ecosys_middlecont exoloctab_cont">
            <LeftTabMenuContainer
              activeMenu={props.location.state?.activeMenu}
            />
            <div className="ecosys_rightcont addproformloc_cont">
              <div className="container-17 pb-4">
                <div className="input-field-text20"></div>
              </div>

              <form>
                <div className="container11 addlayesrfrom_cont">
                  {/* Landparcel Start */}
                  <div className="land-parcels-container1">
                    <div className="land-parecel-content-area1">
                      <div className="close7">close</div>
                      <div className="left-content1">
                        <div className="hero-title13">
                          <b className="logo-a6">Add Layers</b>
                        </div>
                        <div className="container-26">
                          <div className="column-118">
                            <div className="content-title-text110">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Layer Name
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name={"layerName"}
                                rules={{
                                  validate: {
                                    required: (value) => {
                                      if (!value)
                                        return "Layer Name is required!";
                                    },
                                  },
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <select
                                      id="layerName"
                                      name="layerName"
                                      className="dropdown-130"
                                      disabled={editMode}
                                      value={layerData?.layerName}
                                      onChange={(event) =>
                                        layerNameHandleChange(event)
                                      }
                                    >
                                      <option value="">Select</option>
                                      {layerNamedrpdwn &&
                                        layerNamedrpdwn?.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item?.layerName}
                                          >
                                            {item?.layerName}
                                            &nbsp;({item?.fileType})
                                          </option>
                                        ))}
                                    </select>
                                    <p style={{ color: "red" }}>
                                      {error?.message ? error?.message : ""}
                                    </p>
                                  </>
                                )}
                              />{" "}
                            </div>
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Sample File
                                </div>
                              </div>
                              <button
                                className="addbtnstyle"
                                onClick={(e) => downloadSampleFile(e)}
                              >
                                <span hidden>
                                  {layerData?.sampleFile
                                    ? layerData?.sampleFile
                                    : sampleFileData}
                                </span>
                                Download Here
                              </button>
                            </div>
                            {/*{layerNamedrpdwn &&
                              layerNamedrpdwn.filter(
                                (x) => x.layerName === layerData?.layerName
                              )[0]?.isSpeciesRequired === true && (
                                <div className="content-title-text112">
                                  <div className="hero-title13">
                                    <div className="project-summary16">
                                      Species Name
                                    </div>
                                  </div>
                                  <Controller
                                    control={control}
                                    name={"speciesName"}
                                    rules={{
                                      validate: {
                                        required: (value) => {
                                          if (!value)
                                            return "Species Name is required!";
                                        },
                                      },
                                    }}
                                    render={({
                                      field: { onChange, onBlur, value },
                                      fieldState: { error },
                                    }) => (
                                      <>
                                        <CreatableSelect
                                          isClearable
                                          id="speciesName"
                                          name={layerData?.speciesName}
                                          value={layerData?.speciesName}
                                          onChange={handleChangeForDrp}
                                          onCreateOption={handleCreate}
                                          options={speciesData.map(
                                            (species) => ({
                                              value: species.speciesGuid,
                                              label: species.speciesName,
                                            })
                                          )}
                                          isMulti
                                        />

                                        <p style={{ color: "red" }}>
                                          {error?.message ? error?.message : ""}
                                        </p>
                                      </>
                                    )}
                                  />
                                </div>
                              )} */}

                            <div className="content-title-text112">
                              <input
                                className="enter-input-text-1"
                                readOnly
                                hidden
                                type="text"
                                name="layerType"
                                value={layerData?.layerType}
                                placeholder="Layer Type"
                              />
                            </div>
                            <div className="content-title-text110">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Layer Information
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name={"layerInfo"}
                                rules={{
                                  validate: {
                                    required: (value) => {
                                      if (!value)
                                        return "Layer information is required!";
                                    },
                                  },
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <input
                                      className="enter-input-text-1"
                                      required
                                      //pattern="[a-zA-Z0-9]+"
                                      type="text"
                                      name="layerInfo"
                                      value={layerData?.layerInfo}
                                      placeholder="Enter Layer Information"
                                      onChange={(event) => handleChange(event)}
                                    />
                                    <p style={{ color: "red" }}>
                                      {error?.message ? error?.message : ""}
                                    </p>
                                  </>
                                )}
                              />{" "}
                            </div>
                            <div className="content-title-text112"></div>
                            {/* <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Is Only Title?
                                </div>
                              </div>
                              <div className="radio-button-input-text2">
                                <input
                                  className="radio-button-yes2"
                                  type="radio"
                                  name="isOnlyTitle"
                                  value={true}
                                  checked={layerData?.isOnlyTitle}
                                  onChange={handleChangeRbtn}
                                />
                                Yes
                                <input
                                  className="radio-button-yes2"
                                  type="radio"
                                  name="isOnlyTitle"
                                  value={false}
                                  checked={!layerData?.isOnlyTitle}
                                  onChange={handleChangeRbtn}
                                />
                                No
                              </div>
                            </div> */}
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  Sequence Number
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name={"sequence"}
                                rules={{
                                  validate: {
                                    required: (value) => {
                                      if (!value)
                                        return "Sequence number is required!";
                                    },
                                  },
                                }}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <input
                                      className="enter-input-text-1"
                                      pattern="/^[1-9]+$/"
                                      type="text"
                                      name="sequence"
                                      value={layerData?.sequence}
                                      placeholder="Enter sequence number"
                                      onKeyDown={(e) => {
                                        const allowedKeys = [
                                          "ArrowUp",
                                          "ArrowDown",
                                          "ArrowLeft",
                                          "ArrowRight",
                                          "Backspace",
                                          "Delete",
                                          "Tab",
                                        ];
                                        const numberKeys = [
                                          ...Array(10).keys(),
                                        ].map((key) => key.toString());
                                        if (
                                          ["e", "E", "+", "-"].includes(
                                            e.key
                                          ) ||
                                          (!allowedKeys.includes(e.key) &&
                                            !numberKeys.includes(e.key))
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(event) => {
                                        event.preventDefault();
                                        const { value } = event.target;

                                        // Check if the value is numeric and within 1 to 100 range, and has up to 3 digits
                                        if (
                                          /^\d{0,3}$/.test(value) &&
                                          (value === "" ||
                                            (Number(value) >= 1 &&
                                              Number(value) <= 100))
                                        ) {
                                          handleChange(event);
                                        }
                                      }}
                                    />
                                    <p style={{ color: "red" }}>
                                      {error?.message ? error?.message : ""}
                                    </p>
                                  </>
                                )}
                              />{" "}
                            </div>
                          </div>

                          <div className="column-118">
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  File Type
                                </div>
                              </div>
                              <input
                                className="enter-input-text-1"
                                readOnly
                                type="text"
                                name="fileType"
                                value={layerData?.fileType}
                                placeholder="File Type"
                              />
                            </div>
                            <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary16">
                                  File Name
                                </div>
                              </div>
                              <label htmlFor="fileInput">Choose a file:</label>
                              <input
                                className="text-input-field-319"
                                type="file"
                                id="fileInput"
                                name="fileName"
                                accept=".jpg, .jpeg, .png, .xlsx, .xls, .geojson"
                                //value={fileControl ? fileNameControl : ""}
                                onChange={(event) => {
                                  const file = event.target.files[0];
                                  setFile(event);
                                  handleFileChange(event, file);
                                }}
                                ref={fileInputRef}
                              />
                              {error && <p style={{ color: "red" }}>{error}</p>}
                            </div>
                            {/* <div className="content-title-text112">
                              <div className="hero-title13">
                                <div className="project-summary13">
                                  Parent layer
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name={"ParentLayerType"}
                                render={({
                                  field: { onChange, onBlur, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <select
                                      name="parentGuid"
                                      className="dropdown-130"
                                      value={layerData?.layerGuid}
                                      onChange={(event) => handleChange(event)}
                                    >
                                      <option value="">Select</option>
                                      {parentLayersData.map((layer) => (
                                        <option
                                          key={layer.layerGuid}
                                          value={layer.layerGuid}
                                        >
                                          {layer.layerName}
                                        </option>
                                      ))}
                                    </select>
                                    <p style={{ color: "red" }}>
                                      {error?.message ? error?.message : ""}
                                    </p>
                                  </>
                                )}
                              />{" "}
                            </div> */}
                            {showCount && layerData?.fileType !== "png" && (
                              <div className="content-title-text112">
                                <div className="hero-title13">
                                  <div className="project-summary16">
                                    Is Show Count ?
                                  </div>
                                </div>
                                <div className="radio-button-input-text2">
                                  <input
                                    className="radio-button-yes2"
                                    type="radio"
                                    name="isShowCount"
                                    value={true}
                                    checked={layerData?.isShowCount}
                                    onChange={handleChangeRbtn}
                                  />
                                  Yes
                                  <input
                                    className="radio-button-yes2"
                                    type="radio"
                                    name="isShowCount"
                                    value={false}
                                    checked={!layerData?.isShowCount}
                                    onChange={handleChangeRbtn}
                                  />
                                  No
                                </div>
                              </div>
                            )}

                            {layerData?.fileType !== "png" &&
                              layerData?.layerType === "LayerMarker" && (
                                <div className="content-title-text112">
                                  <div className="hero-title13">
                                    <div className="project-summary16">
                                      Is Filter Apply ?
                                    </div>
                                  </div>
                                  <div className="radio-button-input-text2">
                                    <input
                                      className="radio-button-yes2"
                                      type="radio"
                                      name="isFilterApply"
                                      value={true}
                                      checked={layerData?.isFilterApply}
                                      onChange={handleChangeRbtn}
                                    />
                                    Yes
                                    <input
                                      className="radio-button-yes2"
                                      type="radio"
                                      name="isFilterApply"
                                      value={false}
                                      checked={!layerData?.isFilterApply}
                                      onChange={handleChangeRbtn}
                                    />
                                    No
                                  </div>
                                </div>
                              )}
                            {layerData?.fileType === "png" && (
                              <div className="content-title-text112 mapboundsfield_cont">
                                <div className="hero-title13">
                                  <div className="project-summary16">
                                    Map Bounds
                                    <div
                                      className="mapboundinfo_cont"
                                      onClick={toggleTooltip}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.41667 5.52333 8.57 5.37C8.72333 5.21667 8.8 5.02667 8.8 4.8C8.8 4.57333 8.72333 4.38333 8.57 4.23C8.41667 4.07667 8.22667 4 8 4C7.77333 4 7.58333 4.07667 7.43 4.23C7.27667 4.38333 7.2 4.57333 7.2 4.8C7.2 5.02667 7.27667 5.21667 7.43 5.37C7.58333 5.52333 7.77333 5.6 8 5.6ZM8 16C6.89333 16 5.85333 15.79 4.88 15.37C3.90667 14.95 3.06 14.38 2.34 13.66C1.62 12.94 1.05 12.0933 0.63 11.12C0.21 10.1467 0 9.10667 0 8C0 6.89333 0.21 5.85333 0.63 4.88C1.05 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.05 4.88 0.63C5.85333 0.21 6.89333 0 8 0C9.10667 0 10.1467 0.21 11.12 0.63C12.0933 1.05 12.94 1.62 13.66 2.34C14.38 3.06 14.95 3.90667 15.37 4.88C15.79 5.85333 16 6.89333 16 8C16 9.10667 15.79 10.1467 15.37 11.12C14.95 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.95 11.12 15.37C10.1467 15.79 9.10667 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
                                          fill="#868686"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <input
                                  className="enter-input-text-1"
                                  type="text"
                                  name="mapBounds"
                                  value={layerData?.mapBounds}
                                  onChange={handleChange}
                                />
                                {errorMapbound && (
                                  <p style={{ color: "red" }}>
                                    {errorMapbound}
                                  </p>
                                )}

                                {showTooltip && (
                                  <div className="mapbound_infowindow">
                                    <button
                                      onClick={closeTooltip}
                                      className="mapinfowidw_clsbtn"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="18"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                      >
                                        <path
                                          d="M9.99967 10.256L5.83301 6.08936M9.99967 10.256L14.1663 14.4227M9.99967 10.256L14.1663 6.08936M9.99967 10.256L5.83301 14.4227"
                                          stroke="#7E7E7E"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </button>
                                    <div>
                                      {" "}
                                      The term "extent" refers to the spatial
                                      boundaries or limits of a geographic
                                      feature or dataset. It is represented by
                                      the minimum and maximum x and y
                                      coordinates that define the spatial extent
                                      of the feature or dataset in a map. Eg.
                                      15.250110379,74.077749917,15.257275915,74.082876954
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {layerData?.fileType === "png" && (
                              <div className="content-title-text112">
                                <div className="hero-title13">
                                  <div className="project-summary16">
                                    Legend
                                  </div>
                                  <table className="newTable nameclrtbl">
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Color Code</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rows?.map((row, index) => (
                                        <tr key={index}>
                                          <td>
                                            <input
                                              type="text"
                                              placeholder="Name"
                                              value={row?.name}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  index,
                                                  "name",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              placeholder="Color"
                                              value={row?.colorCode}
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor: "#cdfgff",
                                              }}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  index,
                                                  "colorCode",
                                                  e.target.value
                                                )
                                              }
                                            />{" "}
                                            <button
                                              onClick={(e) =>
                                                handleColorBoxClick(
                                                  e,
                                                  row.legendId
                                                )
                                              }
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                                backgroundColor: [
                                                  row?.colorCode
                                                    ? row?.colorCode
                                                    : "#50b041",
                                                ],
                                              }}
                                            ></button>
                                          </td>

                                          <td>
                                            {rows.length > 0 && (
                                              <button
                                                onClick={(e) =>
                                                  handleRemoveRow(
                                                    e,
                                                    row.legendId
                                                  )
                                                }
                                              >
                                                <i class="fas fa-trash"></i>
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                      {selectedRowId !== null && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "50px",
                                            left: "50px",
                                          }}
                                        >
                                          <SketchPicker
                                            color={
                                              rows.find(
                                                (row) =>
                                                  row.legendId === selectedRowId
                                              ).colorCode
                                            }
                                            onChange={handleColorChange}
                                          />
                                        </div>
                                      )}
                                    </tbody>
                                  </table>

                                  <div className="add-more4 pt-3 pb-3">
                                    <button
                                      className="addbtnstyle"
                                      onClick={(e) => handleAddRow(e)}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cta-add-more4 pb-3">
                    <div className="logo-a6">plus</div>
                    <div className="add-more4">
                      <a
                        className="addbtnstyle"
                        onClick={(e) => AddToGrid(e)}
                        disabled={isAddGridDisabled}
                        style={{
                          pointerEvents: isAddGridDisabled ? "none" : "auto",
                        }}
                      >
                        Add
                      </a>
                    </div>
                    <div className="add-more4">
                      <a
                        className="addbtnstyle"
                        onClick={(e) => {
                          e.preventDefault();
                          clearAll(e);
                        }}
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </form>

              {/* Land parcel grid start    */}
              <div className="table4 pb-4 addlayerspretbl_cont">
                <div className="hero-title19">
                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Sr No.</div>
                    </div>
                  </div>
                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Layer Name</div>
                    </div>
                  </div>
                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Layer Information</div>
                    </div>
                  </div>

                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">File Type</div>
                    </div>
                  </div>

                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Sequence</div>
                    </div>
                  </div>
                  <div className="tb-col2">
                    <div className="table-left-title4">
                      <div className="project-summary19">Action</div>
                    </div>
                  </div>
                </div>

                {ProjectLayers &&
                  ProjectLayers.map((x, i) => {
                    return (
                      <div key={i}>
                        <div className="hero-title19">
                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {i + 1}
                              </div>
                            </div>
                          </div>
                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {x.layerName}
                              </div>
                            </div>
                          </div>
                          <div className="tb-col2 addprolayers_info">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {x.layerInfo}
                              </div>
                            </div>
                            <div className="addprolayers_hoverinfo">
                              {x.layerInfo}
                            </div>
                          </div>

                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {x.fileType === "db" ? "xlsx" : x.fileType}
                              </div>
                            </div>
                          </div>
                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                {x.sequence}
                              </div>
                            </div>
                          </div>

                          <div className="tb-col2">
                            <div className="table-row-content-a30">
                              <div className="personnel-lorem-ipsum34">
                                <a>
                                  <i
                                    className="fas fa-edit"
                                    onClick={() => editLayer(i)}
                                  ></i>
                                </a>
                                <a>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() => deleteLayer(x.layerGuid, i)}
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* Land parcel grid end    */}
              <div className="container11"></div>
              <div className="action-buttons5 pt-4">
                <button className="cta11">
                  <div className="back5" onClick={Back}>
                    Back
                  </div>
                </button>
                <button className="cta12">
                  <div
                    className="save-continue6"
                    // onClick={() => AddLayersDetails()}
                    onClick={(e) => SaveContinue(e)}
                  >{`Save & Continue`}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Lay);
