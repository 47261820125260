import React, { useEffect, useState } from "react";
import config from "../../../utils/config";

export default function RisksView(props) {
  const [riskData, setRiskData] = useState([]);

  useEffect(() => {
    if (props?.projectId) {
      GetProjectRisk(props.projectId);
    }
  }, [props?.projectId]);

  const GetProjectRisk = (projectId) => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectRisk`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        projectId: projectId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response?.json();
      })
      .then((data) => {
        let responseData = data;
        if (responseData !== null) {
          setRiskData(responseData?.data);
        }
      })
      .catch((error) => {
        console.error("Error in request", error);
      });
  };
  return (
    <>
      <div className="sitedetailsaccord_wrap riskdetailsaccord_wrap">
        <div className="sitedetailsaccord_heading">
          <h3>Risk Details</h3>
        </div>
        <div className="prosummarylist">
          {riskData?.complexityLevelProjectGoalRelated && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Complexity Level Project Goal Related
              </div>
              <div className="produmitem_content">
                :&nbsp; {riskData?.complexityLevelProjectGoalRelated}
              </div>
            </div>
          )}
          {riskData?.complexityLevelSocialRelated && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Complexity Level Social Related
              </div>
              <div className="produmitem_content">
                :&nbsp; {riskData?.complexityLevelSocialRelated}
              </div>
            </div>
          )}
          {riskData?.overallRiskLevel && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">Overall Risk Level</div>
              <div className="produmitem_content">
                :&nbsp; {riskData?.overallRiskLevel}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="row partshprosumpacrd_content">
        <div className="row">
          <div className="hero-title20">
            <b className="project-summary20">Risk Details</b>
          </div>

          <div className="prosumacordtbls_wrap">
            {riskData != null && (
              <div className="prosumacordinertbl_wrap">
                <table className="prosumacord_inertbl">
                  <thead>
                    <tr>
                      <th>ComplexityLevelProjectGoalRelated</th>
                      <th>ComplexityLevelSocialRelated</th>
                      <th>OverallRiskLevel</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{riskData.complexityLevelProjectGoalRelated}</td>
                      <td>{riskData.complexityLevelSocialRelated}</td>
                      <td>{riskData.overallRiskLevel}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
}
