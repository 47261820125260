import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import config from "../../utils/config";
import { sha512 } from "js-sha512";
import Loader from "./../Common/Loader/Loader";
import growdashlogo from "../../assets/growsmallogo.png";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import { Color } from "cesium";
class Registeruser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NameOfNGO: "",
      BaseLocation: "",
      TypeOfIncorporation: "Trust",
      NGOHeadName: "",
      Email: "",
      Mobile: "",
      QuantumTreePlanted: "",
      Website: "",
      IsActive: true,
      CreatedOn: "",
      CreatedBy: "",
      fields: {},
      errors: {},
      Body: "",
      IsLoader: false,
      files: [],
      MaxFileSizeToUploadInMB: "5",
      ApiSuccess: false,
      othersdata: "",
      NGOHeadEmailAddress: "",
      contactname: "",
      contactdesignation: "",
      showotherdatainput: false,
      showotherdoc: false,
      showpriltddoc: false,
      showtrustdoc: true,
      documentupload: "",

      trustdocument: "",
      rocdocument: "",
      aoadocument: "",
      moadocument: "",
      comincardocument: "",
      authorsignatories: "",
      blankcanchq: "",
      additionaldocument: "",
      trustdeedfile: [],
      authorisignatfile: [],
      blankcancheqfile: [],
      additionalfile: [],
      rocfile: [],
      moafile: [],
      aoafile: [],
      compincorporation: [],
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  setfile = (e) => {
    let filesArr = "";
    if (e.target.files[0].name.match(/\.(pdf|xls|xlsx)$/)) {
      if (
        parseInt(e.target.files[0].size) / 1024 <=
        parseInt(this.state.MaxFileSizeToUploadInMB) * 1024
      ) {
        // validation according to file size
        if (this.state.showtrustdoc) {
          filesArr = e.target.files;
          this.setState({ files: e.target.files });
          if (e.target.id == "trust") {
            this.setState({ trustdocument: e.target.files[0].name });
            var array = [...this.state.trustdeedfile];
            array.push(e.target.files);
            this.setState({ trustdeedfile: array });
            var filesize = array.length;
          }
          if (e.target.id == "authorizedsigna") {
            this.setState({ authorsignatories: e.target.files[0].name });
            var array = [...this.state.authorisignatfile];
            array.push(e.target.files);
            this.setState({ authorisignatfile: array });
            var filesize = array.length;
          }
          if (e.target.id == "blankcancheq") {
            this.setState({ blankcanchq: e.target.files[0].name });
            var array = [...this.state.blankcancheqfile];
            array.push(e.target.files);
            this.setState({ blankcancheqfile: array });
            var filesize = array.length;
          }
          if (e.target.id == "additonaldoc") {
            this.setState({ additionaldocument: e.target.files[0].name });
            var array = [...this.state.additionalfile];
            array.push(e.target.files);
            this.setState({ additionalfile: array });
            var filesize = array.length;
          }
        }
        if (this.state.showpriltddoc) {
          filesArr = e.target.files;
          if (e.target.id == "rocregis") {
            this.setState({ rocdocument: e.target.files[0].name });
            var array = [...this.state.rocfile];
            array.push(e.target.files);
            this.setState({ rocfile: array });
            var filesize = array.length;
          }
          if (e.target.id == "moa") {
            this.setState({ moadocument: e.target.files[0].name });
            var array = [...this.state.moafile];
            array.push(e.target.files);
            this.setState({ moafile: array });
            var filesize = array.length;
          }
          if (e.target.id == "aoa") {
            this.setState({ aoadocument: e.target.files[0].name });
            var array = [...this.state.aoafile];
            array.push(e.target.files);
            this.setState({ aoafile: array });
            var filesize = array.length;
          }
          if (e.target.id == "authorizedsigna") {
            this.setState({ authorsignatories: e.target.files[0].name });
            var array = [...this.state.authorisignatfile];
            array.push(e.target.files);
            this.setState({ authorisignatfile: array });
            var filesize = array.length;
          }
          if (e.target.id == "blankcancheq") {
            this.setState({ blankcanchq: e.target.files[0].name });
            var array = [...this.state.blankcancheqfile];
            array.push(e.target.files);
            this.setState({ blankcancheqfile: array });
            var filesize = array.length;
          }
          if (e.target.id == "additonaldoc") {
            this.setState({ additionaldocument: e.target.files[0].name });
            var array = [...this.state.additionalfile];
            array.push(e.target.files);
            this.setState({ additionalfile: array });
            var filesize = array.length;
          }
        }
        if (this.state.showotherdoc) {
          filesArr = e.target.files;
          if (e.target.id == "compincorpo") {
            this.setState({ comincardocument: e.target.files[0].name });
            var array = [...this.state.compincorporation];
            array.push(e.target.files);
            this.setState({ compincorporation: array });
            var filesize = array.length;
          }
          if (e.target.id == "authorizedsigna") {
            this.setState({ authorsignatories: e.target.files[0].name });
            var array = [...this.state.authorisignatfile];
            array.push(e.target.files);
            this.setState({ authorisignatfile: array });
            var filesize = array.length;
          }
          if (e.target.id == "blankcancheq") {
            this.setState({ blankcanchq: e.target.files[0].name });
            var array = [...this.state.blankcancheqfile];
            array.push(e.target.files);
            this.setState({ blankcancheqfile: array });
            var filesize = array.length;
          }
          if (e.target.id == "additonaldoc") {
            this.setState({ additionaldocument: e.target.files[0].name });
            var array = [...this.state.additionalfile];
            array.push(e.target.files);
            this.setState({ additionalfile: array });
            var filesize = array.length;
          }
        }

        if (filesize == 1) {
          for (var i = 0; i < filesArr.length; ++i) {
            //if (filesArr[i].name.match(/\.(pdf|xls|xlsx)$/)) {
            if (this.state.MaxFileSizeToUploadInMB) {
              // if ((parseInt(filesArr[0].size) / 1024) <= (parseInt(this.state.MaxFileSizeToUploadInMB) * 1024))  // validation according to file size
              // {
              let filesinde = e.target.files;
              let allfiles = this.state.files;
              if (this.state.showtrustdoc && e.target.id == "trust") {
                let myFilesList = this.state.trustdeedfile;
                myFilesList.push(filesArr[i]);
                this.setState({ trustdeedfile: myFilesList });
                allfiles.push(filesinde[i]);
              }
              if (this.state.showpriltddoc && e.target.id == "rocregis") {
                let myFilesList = this.state.rocfile;
                myFilesList.push(filesArr[i]);
                this.setState({ rocfile: myFilesList });
                allfiles.push(filesinde[i]);
              }
              if (this.state.showpriltddoc && e.target.id == "moa") {
                let myFilesList = this.state.moafile;
                myFilesList.push(filesArr[i]);
                this.setState({ moafile: myFilesList });
                allfiles.push(filesinde[i]);
              }
              if (this.state.showpriltddoc && e.target.id == "aoa") {
                let myFilesList = this.state.aoafile;
                myFilesList.push(filesArr[i]);
                this.setState({ aoafile: myFilesList });
                allfiles.push(filesinde[i]);
              }
              if (this.state.showotherdoc && e.target.id == "compincorpo") {
                let myFilesList = this.state.compincorporation;
                myFilesList.push(filesArr[i]);
                this.setState({ compincorporation: myFilesList });
                allfiles.push(filesinde[i]);
              }

              if (e.target.id == "authorizedsigna") {
                let myFilesList = this.state.authorisignatfile;
                myFilesList.push(filesArr[i]);
                this.setState({ authorisignatfile: myFilesList });
                allfiles.push(filesinde[i]);
              }
              if (e.target.id == "blankcancheq") {
                let myFilesList = this.state.blankcancheqfile;
                myFilesList.push(filesArr[i]);
                this.setState({ blankcancheqfile: myFilesList });
                allfiles.push(filesinde[i]);
              }
              if (e.target.id == "additonaldoc") {
                let myFilesList = this.state.additionalfile;
                myFilesList.push(filesArr[i]);
                this.setState({ additionalfile: myFilesList });
                allfiles.push(filesinde[i]);
              }
              this.setState({ files: allfiles });

              // } else {
              //     ToastNotification.ShowWarning('Maximum files size allowed is ' + this.state.MaxFileSizeToUploadInMB + 'MB')

              // }
            }
            // }
            // else {
            //     ToastNotification.ShowWarning('Allowed extensions are .pdf, .xls')
            // }
          }
        } else {
          //Size of array one
          if (e.target.id == "trust") {
            var index = array.indexOf(e.target.value, 1);
            array.splice(index, 1);
            this.setState({ trustdeedfile: array });
            ToastNotification.ShowWarning("Select only one file");
          }
          if (e.target.id == "authorizedsigna") {
            var index = array.indexOf(e.target.value, 1);
            array.splice(index, 1);
            this.setState({ authorisignatfile: array });
            ToastNotification.ShowWarning("Select only one file");
          }
          if (e.target.id == "blankcancheq") {
            var index = array.indexOf(e.target.value, 1);
            array.splice(index, 1);
            this.setState({ blankcancheqfile: array });
            ToastNotification.ShowWarning("Select only one file");
          }
          if (e.target.id == "additonaldoc") {
            var index = array.indexOf(e.target.value, 1);
            array.splice(index, 1);
            this.setState({ additionalfile: array });
            ToastNotification.ShowWarning("Select only one file");
          }
          if (e.target.id == "rocregis") {
            var index = array.indexOf(e.target.value, 1);
            array.splice(index, 1);
            this.setState({ rocfile: array });
            ToastNotification.ShowWarning("Select only one file");
          }
          if (e.target.id == "moa") {
            var index = array.indexOf(e.target.value, 1);
            array.splice(index, 1);
            this.setState({ moafile: array });
            ToastNotification.ShowWarning("Select only one file");
          }
          if (e.target.id == "aoa") {
            var index = array.indexOf(e.target.value, 1);
            array.splice(index, 1);
            this.setState({ aoafile: array });
            ToastNotification.ShowWarning("Select only one file");
          }
          if (e.target.id == "compincorpo") {
            var index = array.indexOf(e.target.value, 1);
            array.splice(index, 1);
            this.setState({ compincorporation: array });
            ToastNotification.ShowWarning("Select only one file");
          }
        }
      } else
        ToastNotification.ShowWarning(
          "Maximum files size allowed is " +
            this.state.MaxFileSizeToUploadInMB +
            "MB"
        );
    } else ToastNotification.ShowWarning("Allowed extensions are .pdf, .xls");
  };
  removeFileFromList = (e, id) => {
    if (id === "Trust") {
      var array = [...this.state.files]; // make a separate copy of the array
      array.splice(e.target.id, 1);
      this.setState({ trustdeedfile: [], trustdocument: "", files: array });
    }
    if (id === "authorisignat") {
      var array = [...this.state.files]; // make a separate copy of the array
      array.splice(e.target.id, 1);
      this.setState({
        authorisignatfile: [],
        authorsignatories: "",
        files: array,
      });
    }
    if (id === "blankcancheq") {
      var array = [...this.state.files]; // make a separate copy of the array
      array.splice(e.target.id, 1);
      this.setState({ blankcancheqfile: [], blankcanchq: "", files: array });
    }
    if (id === "roc") {
      var array = [...this.state.files]; // make a separate copy of the array
      array.splice(e.target.id, 1);
      this.setState({ rocfile: [], rocdocument: "", files: array });
    }
    if (id === "moa") {
      var array = [...this.state.files]; // make a separate copy of the array
      array.splice(e.target.id, 1);
      this.setState({ moafile: [], moadocument: "", files: array });
    }
    if (id === "aoa") {
      var array = [...this.state.files]; // make a separate copy of the array
      array.splice(e.target.id, 1);
      this.setState({ aoafile: [], aoadocument: "", files: array });
    }
    if (id === "compincorpor") {
      var array = [...this.state.files]; // make a separate copy of the array
      array.splice(e.target.id, 1);
      this.setState({
        compincorporation: [],
        comincardocument: "",
        files: array,
      });
    }
    if (id === "additional") {
      var array = [...this.state.files]; // make a separate copy of the array
      array.splice(e.target.id, 1);
      this.setState({
        additionalfile: [],
        additionaldocument: "",
        files: array,
      });
    }
  };
  // This function do validate the Registration Form
  validateForm() {
    let fields;
    fields = {
      NameOfNGO: this.state.NameOfNGO,
      BaseLocation: this.state.BaseLocation,
      TypeOfIncorporation: this.state.TypeOfIncorporation,
      NGOHeadName: this.state.NGOHeadName,
      Email: this.state.Email,
      Mobile: this.state.Mobile,
      QuantumTreePlanted: this.state.QuantumTreePlanted,
      Website: this.state.Website,
      IsActive: true,
      CreatedOn: new Date(),
      CreatedBy: this.state.Email,
      otherdata: this.state.othersdata,
      ngoheademailaddress: this.state.NGOHeadEmailAddress,
      contactname: this.state.contactname,
      contactdesignation: this.state.contactdesignation,
      trustdocument: this.state.trustdocument,
      authorsignatories: this.state.authorsignatories,
      rocdocument: this.state.rocdocument,
      aoadocument: this.state.aoadocument,
      moadocument: this.state.moadocument,
      comincardocument: this.state.comincardocument,
      blankcanchq: this.state.blankcanchq,
      additionaldocument: this.state.additionaldocument,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["NameOfNGO"]) {
      formIsValid = false;
      errors["NameOfNGO"] = "Enter NGO Name";
    }
    if (!fields["BaseLocation"]) {
      formIsValid = false;
      errors["BaseLocation"] = "Enter Base Location";
    }
    if (!fields["TypeOfIncorporation"]) {
      formIsValid = false;
      errors["TypeOfIncorporation"] = "Select Type of Incorporation";
    }
    if (!fields["NGOHeadName"]) {
      formIsValid = false;
      errors["NGOHeadName"] = "Enter NGO Head Name";
    }

    if (!fields["Email"]) {
      formIsValid = false;
      errors["Email"] = "Enter your Email Address";
    }

    if (typeof fields["Email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["Email"])) {
        formIsValid = false;
        errors["Email"] = "Enter Valid Email Address";
      }
    }
    if (!fields["Mobile"]) {
      formIsValid = false;
      errors["Mobile"] = "Enter Mobile Number";
    }
    if (!fields["QuantumTreePlanted"]) {
      formIsValid = false;
      errors["QuantumTreePlanted"] = "Enter Quantum of Tree Planted";
    }
    if (this.state.showotherdatainput && !fields["otherdata"]) {
      formIsValid = false;
      errors["otherdata"] = "Enter Details";
    }
    if (!fields["ngoheademailaddress"]) {
      formIsValid = false;
      errors["ngoheademailaddress"] = "Enter Ngo Head Email Address";
    }
    if (!fields["contactname"]) {
      formIsValid = false;
      errors["contactname"] = "Enter Name";
    }
    if (!fields["contactdesignation"]) {
      formIsValid = false;
      errors["contactdesignation"] = "Enter Designation";
    }
    if (typeof fields["ngoheademailaddress"] !== undefined) {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["ngoheademailaddress"])) {
        formIsValid = false;
        errors["ngoheademailaddress"] = "Enter valid email address";
      }
    }
    if (this.state.showtrustdoc && this.state.trustdocument.length === 0) {
      formIsValid = false;
      errors["trustdocument"] = "Please Upload File";
    }
    if (this.state.showpriltddoc && this.state.rocdocument.length === 0) {
      formIsValid = false;
      errors["rocdocument"] = "Please Upload File";
    }
    if (this.state.showpriltddoc && this.state.moadocument.length === 0) {
      formIsValid = false;
      errors["moadocument"] = "Please Upload File";
    }
    if (this.state.showpriltddoc && this.state.aoadocument.length === 0) {
      formIsValid = false;
      errors["aoadocument"] = "Please Upload File";
    }
    if (this.state.showotherdoc && this.state.comincardocument.length === 0) {
      formIsValid = false;
      errors["comincardocument"] = "Please Upload File";
    }
    if (this.state.authorsignatories.length === 0) {
      formIsValid = false;
      errors["authorsignatories"] = "Please Upload File";
    }
    if (this.state.blankcanchq.length === 0) {
      formIsValid = false;
      errors["blankcanchq"] = "Please Upload File";
    }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  }

  DoRegistration = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      if (this.mounted) this.setState({ IsLoader: true });
      var randomPassword = Math.random().toString(36).slice(-8);
      let cryptedp = sha512(randomPassword);
      let file = this.state.files;
      const formData = new FormData();
      for (var index = 0; index < file.length; index++) {
        var element = file[index];
        formData.append("FormFiles", element);
      }
      formData.append("UserId", "");
      formData.append("NameOfNGO", this.state.NameOfNGO);
      formData.append("BaseLocation", this.state.BaseLocation);
      formData.append("TypeOfIncorporation", this.state.TypeOfIncorporation);
      formData.append("NGOHeadName", this.state.NGOHeadName);
      formData.append("Username", this.state.Email);
      formData.append("Email", this.state.Email);
      formData.append("MainPassword", randomPassword);
      formData.append("Password", cryptedp);
      formData.append("Mobile", this.state.Mobile);
      formData.append("TreePlantedBefore", this.state.QuantumTreePlanted);
      formData.append("Website", this.state.Website);
      formData.append("IsActive", true);
      formData.append("RoleId", 2);
      formData.append("ProfileImage", "");
      formData.append("IsApproved", false);
      formData.append("CreatedOn", new Date());
      formData.append("CreatedBy", this.state.Email);
      formData.append("Status", "New Request");
      formData.append("OtherData", this.state.othersdata);
      formData.append("NgoheadEmailAddr", this.state.NGOHeadEmailAddress);
      formData.append("Contactname", this.state.contactname);
      formData.append("Contactdesignation", this.state.contactdesignation);
      formData.append("Trustdocument", this.state.trustdocument);
      formData.append("Rocdocument", this.state.rocdocument);
      formData.append("Aoadocument", this.state.aoadocument);
      formData.append("Moadocument", this.state.moadocument);
      formData.append(
        "Companyincarporationdocument",
        this.state.comincardocument
      );
      formData.append("Authorsignatories", this.state.authorsignatories);
      formData.append("Blankcanchq", this.state.blankcanchq);
      formData.append("Additionaldocument", this.state.additionaldocument);
      let uri = `${config.API_HOST_URL}/api/login/RegisterUser`;
      let fetchOptions = {
        method: "POST",
        //body: JSON.stringify(UserDetail),
        body: formData,
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          Accept: "application/json",
          ContentType: "multipart/form-data",
        },
      };
      fetch(uri, fetchOptions)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data.success && this.mounted == true) {
                let errors = {};
                // errors["message"] = data.message;
                ToastNotification.ShowSuccess("" + data.message);
                this.setState(
                  {
                    errors: errors,
                    ApiSuccess: true,
                  },
                  () => console.log(this.state.ApiSuccess)
                );

                this.clearFields();
              } else {
                let errors = {};
                //errors["message"] = data.message;
                ToastNotification.ShowWarning("" + data.message);
                if (this.mounted) {
                  this.setState({
                    errors: errors,
                    IsLoader: false,
                  });
                }
              }
            });
          } else {
            return response.json().then((data) => {
              let errors = {};
              //errors["message"] = data.message;
              ToastNotification.ShowWarning("" + data.message);
              if (this.mounted) {
                this.setState({
                  errors: errors,
                  IsLoader: false,
                });
              }
            });
          }
        })
        .catch((error) => {
          if (this.mounted) {
            console.log(error);
            this.setState({
              errorMsg: error,
              IsLoader: false,
            });
          }
        });
    }
  };

  clearFields = (e) => {
    if (this.mounted) {
      this.setState({
        NameOfNGO: "",
        BaseLocation: "",
        TypeOfIncorporation: "Trust",
        NGOHeadName: "",
        Email: "",
        Mobile: "",
        QuantumTreePlanted: "",
        Website: "",
        IsActive: true,
        CreatedOn: "",
        CreatedBy: "",
        Body: "",
        IsLoader: false,
        files: [],
        othersdata: "",
        NGOHeadEmailAddress: "",
        contactname: "",
        contactdesignation: "",
        showotherdatainput: false,
        documentupload: "",
        trustdeedfile: [],
        authorisignatfile: [],
        blankcancheqfile: [],
        additionalfile: [],
        rocfile: [],
        moafile: [],
        aoafile: [],
        compincorporation: [],
        trustdocument: "",
        rocdocument: "",
        aoadocument: "",
        moadocument: "",
        comincardocument: "",
        authorsignatories: "",
        blankcanchq: "",
        additionaldocument: "",
        showotherdoc: false,
        showpriltddoc: false,
        showtrustdoc: true,
      });
    }
  };
  onValueChange(event) {
    if (this.mounted) {
      this.setState({
        TypeOfIncorporation: event.target.value,
      });
      if (event.target.value === "Trust")
        this.setState({
          showtrustdoc: true,
          showpriltddoc: false,
          showotherdoc: false,
          trustdeedfile: [],
          trustdocument: "",
          showotherdatainput: false,
        });
      if (event.target.value === "Privatelimitedcompany")
        this.setState({
          showtrustdoc: false,
          showpriltddoc: true,
          showotherdoc: false,
          rocfile: [],
          moafile: [],
          aoafile: [],
          rocdocument: "",
          aoadocument: "",
          moadocument: "",
          showotherdatainput: false,
        });
      if (event.target.value === "Others")
        this.setState({
          showtrustdoc: false,
          showpriltddoc: false,
          showotherdoc: true,
          compincorporation: [],
          comincardocument: "",
          showotherdatainput: true,
        });
      this.setState({
        authorisignatfile: [],
        blankcancheqfile: [],
        additionalfile: [],
        authorsignatories: "",
        blankcanchq: "",
        additionaldocument: "",
      });
    }
  }

  // This function will set the state of input fields and validate them when user enters the value in input boxes
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;

    let errors = {};

    // Mobile number validation (only for "MobileNumber" field)
    if (name === "Mobile") {
      const mobileRegex = /^\d{10}$/; // assumes 10-digit mobile number
      if (!mobileRegex.test(value)) {
        errors["Mobile"] = "Invalid Mobile Number";
      } else {
        errors["Mobile"] = "";
      }
    }
    if (name === "QuantumTreePlanted") {
      const numberRegex = /^\d+$/; // matches one or more digits
      if (!numberRegex.test(value)) {
        errors["QuantumTreePlanted"] = "Invalid Number Of Quantum Tree Planted";
      } else {
        errors["QuantumTreePlanted"] = "";
      }
    }

    // Website validation (only for "Website" field)
    if (name === "Website") {
      const websiteRegex = /^(https?:\/\/)?(www\.)?[^.]+\.[a-zA-Z]{2,}$/; // matches a valid website URL
      if (!websiteRegex.test(value)) {
        errors["Website"] = "Invalid website URL";
      } else {
        errors["Website"] = "";
      }
    }
    if (this.mounted) {
      this.setState({ [name]: value, errors: errors }, () => {});
    }
  }

  handleInput(e) {
    const name = e.target.name;
    const value = e.target.value.replace(/^ /, "").replace(/[^A-Za-z ]/g, ""); // remove leading spaces and non-alphabet characters, allow spaces
    const newValue = value.replace(/\s{2,}/g, " "); // replace multiple spaces with a single space
    if (
      name === "NameOfNGO" ||
      name === "BaseLocation" ||
      name == "othersdata" ||
      name == "NGOHeadName" ||
      name == "contactname" ||
      name == "contactdesignation"
    ) {
      if (newValue.trim() === "") return; // don't allow empty strings
    }
    if (this.mounted) {
      this.setState({ [name]: newValue }, () => {});
    }
  }

  gotoLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };
  render() {
    return (
      <div>
        {this.state.IsLoader ? (
          <Loader />
        ) : (
          <div className="registartion_container">
            <div className="registation_head">
              <div className="growlogo">
                {/* companyLogo.png */}
                <a href="/">
                  <img
                    src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/treeties.png`}
                    alt="growdashlogo"
                  />
                </a>
              </div>
              <div className="section_heading">Applicant Registration</div>
            </div>
            <div className="registartion_cont">
              <div className="registartioninner_wrap">
                <div className="section_heading">General Details</div>
                <div className="mandatory_txt">
                  All fields marked <span style={{ color: "red" }}>*</span> are
                  mandatory
                </div>
              </div>
              <form>
                <ul className="register_form">
                  <li className="form-group field">
                    <input
                      type="text"
                      id="nameofngo"
                      name="NameOfNGO"
                      placeholder="Enter Your NGO Name"
                      required
                      value={this.state.NameOfNGO}
                      onChange={(event) => this.handleInput(event)}
                    />
                    <label for="nameofngo">
                      <sup className="mandatory">*</sup>
                      Name of NGO
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.NameOfNGO}
                    </div>
                  </li>
                  <li className="form-group field">
                    <input
                      type="text"
                      id="baselocation"
                      name="BaseLocation"
                      placeholder="Enter Your Base Location"
                      required
                      value={this.state.BaseLocation}
                      onChange={(event) => this.handleInput(event)}
                    />
                    <label for="baselocation">
                      <sup className="mandatory">*</sup>
                      Base Location
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.BaseLocation}
                    </div>
                  </li>

                  <li className="form-group incorporation_cont">
                    <label htmlFor="incorpngo">
                      <sup className="mandatory">*</sup>
                      Type of Incorporation
                    </label>
                    <div className="input-group radiobtns_wrap">
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            name="TypeOfIncorporation"
                            type="radio"
                            id="incorpngo"
                            value="Trust"
                            className="input-radio"
                            checked={this.state.TypeOfIncorporation === "Trust"}
                            onChange={this.onValueChange}
                          />
                        </span>
                        <label htmlFor="incorpngo" className="radio__label">
                          Trust
                        </label>
                      </div>
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            name="TypeOfIncorporation"
                            type="radio"
                            id="private"
                            value="Privatelimitedcompany"
                            className="input-radio"
                            checked={
                              this.state.TypeOfIncorporation ===
                              "Privatelimitedcompany"
                            }
                            onChange={this.onValueChange}
                          />
                        </span>
                        <label htmlFor="private" className="radio__label">
                          Private limited company
                        </label>
                      </div>
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            name="TypeOfIncorporation"
                            type="radio"
                            id="others"
                            value="Others"
                            className="input-radio"
                            checked={
                              this.state.TypeOfIncorporation === "Others"
                            }
                            onChange={this.onValueChange}
                          />
                        </span>
                        <label htmlFor="others" className="radio__label">
                          Others
                        </label>
                      </div>
                      <div className="othersinputcont">
                        {this.state.showotherdatainput && (
                          <span className="form-group field">
                            <input
                              type="text"
                              id="othersdata"
                              name="othersdata"
                              placeholder="Please Enter Details"
                              required
                              value={this.state.othersdata}
                              onChange={(event) => this.handleInput(event)}
                            />
                            <label for="othersdata">
                              <sup className="mandatory">*</sup>
                              Others
                            </label>
                            <div className="mandatory has-error">
                              {this.state.errors.otherdata}
                            </div>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mandatory has-error">
                      {this.state.errors.TypeOfIncorporation}
                    </div>
                  </li>
                  <li className="form-group field">
                    <input
                      type="text"
                      id="nameofhodorg"
                      name="NGOHeadName"
                      placeholder="Enter Your head of Organization Name"
                      required
                      value={this.state.NGOHeadName}
                      onChange={(event) => this.handleInput(event)}
                    />
                    <label for="nameofhodorg">
                      <sup className="mandatory">*</sup>
                      Name of The Head of Organization
                    </label>

                    <div className="mandatory has-error">
                      {this.state.errors.NGOHeadName}
                    </div>
                  </li>
                  <li className="form-group field">
                    <input
                      type="text"
                      id="NGOHeadEmailAddress"
                      name="NGOHeadEmailAddress"
                      placeholder="Enter Your Head Email Address"
                      required
                      value={this.state.NGOHeadEmailAddress}
                      onChange={(event) => this.handleUserInput(event)}
                    />
                    <label for="NGOHeadEmailAddress">
                      <sup className="mandatory">*</sup>
                      NGO Head Email Address
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.ngoheademailaddress}
                    </div>
                  </li>
                  <li className="contactdet_cont">
                    <div className="section_heading">Point of Contact</div>
                  </li>
                  <li className="form-group field ">
                    <input
                      type="text"
                      id="contactname"
                      name="contactname"
                      placeholder="Enter Your Name"
                      required
                      value={this.state.contactname}
                      onChange={(event) => this.handleInput(event)}
                    />
                    <label for="contactname">
                      <sup className="mandatory">*</sup>
                      Name
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.contactname}
                    </div>
                  </li>
                  <li className="form-group field emailreg_cont">
                    <input
                      type="ngoemail"
                      id="ngoemail"
                      name="Email"
                      placeholder="Enter Your Email Id"
                      required
                      value={this.state.Email}
                      onChange={(event) => this.handleUserInput(event)}
                    />
                    <label for="ngoemail">
                      <sup className="mandatory">*</sup>
                      Email Id
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.Email}
                    </div>
                  </li>
                  <li className="form-group field mobilereg_cont">
                    <input
                      type="tel"
                      id="mobileno"
                      name="Mobile"
                      placeholder="Enter Your Mobile Number"
                      required
                      value={this.state.Mobile}
                      onChange={(event) => this.handleUserInput(event)}
                    />
                    <label for="mobileno">
                      <sup className="mandatory">*</sup>
                      Mobile No
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.Mobile}
                    </div>
                  </li>
                  <li className="form-group field ">
                    <input
                      type="text"
                      id="contactdesignation"
                      name="contactdesignation"
                      placeholder="Enter Your Designation"
                      required
                      value={this.state.contactdesignation}
                      onChange={(event) => this.handleInput(event)}
                    />
                    <label for="contactdesignation">
                      <sup className="mandatory">*</sup>
                      Designation
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.contactdesignation}
                    </div>
                  </li>
                  <li className="contactdet_cont">
                    <div className="section_heading">Other Details</div>
                  </li>
                  <li className="form-group field">
                    <input
                      type="text"
                      id="treesplantbefore"
                      name="QuantumTreePlanted"
                      placeholder="Enter Number of Quantum Trees Planted Before"
                      required
                      value={this.state.QuantumTreePlanted}
                      onChange={(event) => this.handleUserInput(event)}
                    />
                    <label for="treesplantbefore">
                      <sup className="mandatory">*</sup>
                      Quantum of Trees Planted
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.QuantumTreePlanted}
                    </div>
                  </li>
                  <li className="form-group field">
                    <input
                      type="url"
                      id="linksreg"
                      name="Website"
                      placeholder="Enter your Website/Social Media link/News link etc"
                      required
                      value={this.state.Website}
                      onChange={(event) => this.handleUserInput(event)}
                    />
                    <label for="linksreg">
                      Website/Social Media Link/News Link etc
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.Website}
                    </div>
                  </li>
                  <li className="contactdet_cont">
                    <div className="section_heading">Upload Documents</div>
                  </li>
                  {this.state.showtrustdoc && (
                    <li className="form-group inventory_cont">
                      <label for="trustdeed">
                        <sup className="mandatory">*</sup>Upload Trust Deed
                      </label>
                      <div className="input-group divUploadFile">
                        <div className="qq-upload-drop-area">
                          <span>Drop files here to upload</span>
                        </div>
                        <div className="qq-upload-button">
                          Drag and Drop Files
                          <span className="redMandat">
                            Upload file type .pdf, .xls File size limit 4MB
                          </span>
                        </div>
                        <input
                          id="trust"
                          type="file"
                          name="files"
                          onChange={(e) => this.setfile(e)}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </div>
                      <div>
                        <ul id="fileList" className="ul-project-files">
                          {this.state.trustdeedfile !== null &&
                            this.state.trustdeedfile.map((item, index) => (
                              <li class="file-list-li">
                                <i class="fa fa-check"></i>
                                {item.name}
                                <a
                                  class="remove-files-list"
                                  id={index}
                                  onClick={(e) =>
                                    this.removeFileFromList(e, "Trust")
                                  }
                                >
                                  Remove
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                      {this.state.trustdeedfile.length == 0 && (
                        <div className="mandatory has-error">
                          {this.state.errors.trustdocument}
                        </div>
                      )}
                    </li>
                  )}

                  <li className="form-group inventory_cont">
                    <label for="listofauthsignature">
                      <sup className="mandatory">*</sup>Upload List of
                      Authorized Signatories
                    </label>
                    <div className="input-group divUploadFile">
                      <div className="qq-upload-drop-area">
                        <span>Drop files here to upload</span>
                      </div>
                      <div className="qq-upload-button">
                        Drag and Drop Files
                        <span className="redMandat">
                          Upload file type .pdf, .xls File size limit 4MB
                        </span>
                      </div>
                      <input
                        id="authorizedsigna"
                        type="file"
                        name="files"
                        onChange={(e) => this.setfile(e)}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                    </div>
                    <div>
                      <ul id="fileList" className="ul-project-files">
                        {this.state.authorisignatfile !== null &&
                          this.state.authorisignatfile.map((item, index) => (
                            <li class="file-list-li">
                              <i class="fa fa-check"></i>
                              {item.name}
                              <a
                                class="remove-files-list"
                                id={index}
                                onClick={(e) =>
                                  this.removeFileFromList(e, "authorisignat")
                                }
                              >
                                Remove
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                    {this.state.authorsignatories.length == 0 && (
                      <div className="mandatory has-error">
                        {this.state.errors.authorsignatories}
                      </div>
                    )}
                  </li>
                  <li className="form-group inventory_cont">
                    <label for="uploadblankcancheq">
                      <sup className="mandatory">*</sup>Upload Blank Cancel
                      Cheque
                    </label>
                    <div className="input-group divUploadFile">
                      <div className="qq-upload-drop-area">
                        <span>Drop files here to upload</span>
                      </div>
                      <div className="qq-upload-button">
                        Drag and Drop Files
                        <span className="redMandat">
                          Upload file type .pdf, .xls File size limit 4MB
                        </span>
                      </div>
                      <input
                        id="blankcancheq"
                        type="file"
                        name="files"
                        onChange={(e) => this.setfile(e)}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                    </div>
                    <div>
                      <ul id="fileList" className="ul-project-files">
                        {this.state.blankcancheqfile !== null &&
                          this.state.blankcancheqfile.map((item, index) => (
                            <li class="file-list-li">
                              <i class="fa fa-check"></i>
                              {item.name}
                              <a
                                class="remove-files-list"
                                id={index}
                                onClick={(e) =>
                                  this.removeFileFromList(e, "blankcancheq")
                                }
                              >
                                Remove
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                    {this.state.blankcanchq.length == 0 && (
                      <div className="mandatory has-error">
                        {this.state.errors.blankcanchq}
                      </div>
                    )}
                  </li>

                  {this.state.showpriltddoc && (
                    <li className="form-group inventory_cont">
                      <label for="uploadrocregis">
                        <sup className="mandatory">*</sup>Upload ROC
                        Registration
                      </label>
                      <div className="input-group divUploadFile">
                        <div className="qq-upload-drop-area">
                          <span>Drop files here to upload</span>
                        </div>
                        <div className="qq-upload-button">
                          Drag and Drop Files
                          <span className="redMandat">
                            Upload file type .pdf, .xls File size limit 4MB
                          </span>
                        </div>
                        <input
                          id="rocregis"
                          type="file"
                          name="files"
                          onChange={(e) => this.setfile(e)}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </div>
                      <div>
                        <ul id="fileList" className="ul-project-files">
                          {this.state.rocfile !== null &&
                            this.state.rocfile.map((item, index) => (
                              <li class="file-list-li">
                                <i class="fa fa-check"></i>
                                {item.name}
                                <a
                                  class="remove-files-list"
                                  id={index}
                                  onClick={(e) =>
                                    this.removeFileFromList(e, "roc")
                                  }
                                >
                                  Remove
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                      {this.state.rocdocument.length == 0 && (
                        <div className="mandatory has-error">
                          {this.state.errors.rocdocument}
                        </div>
                      )}
                    </li>
                  )}
                  {this.state.showpriltddoc && (
                    <li className="form-group inventory_cont">
                      <label for="uploadmoa">
                        <sup className="mandatory">*</sup>Upload Memorandum of
                        Association (MoA) of Company
                      </label>
                      <div className="input-group divUploadFile">
                        <div className="qq-upload-drop-area">
                          <span>Drop files here to upload</span>
                        </div>
                        <div className="qq-upload-button">
                          Drag and Drop Files
                          <span className="redMandat">
                            Upload file type .pdf, .xls File size limit 4MB
                          </span>
                        </div>
                        <input
                          id="moa"
                          type="file"
                          name="files"
                          onChange={(e) => this.setfile(e)}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </div>
                      <div>
                        <ul id="fileList" className="ul-project-files">
                          {this.state.moafile !== null &&
                            this.state.moafile.map((item, index) => (
                              <li class="file-list-li">
                                <i class="fa fa-check"></i>
                                {item.name}
                                <a
                                  class="remove-files-list"
                                  id={index}
                                  onClick={(e) =>
                                    this.removeFileFromList(e, "moa")
                                  }
                                >
                                  Remove
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                      {this.state.moadocument.length == 0 && (
                        <div className="mandatory has-error">
                          {this.state.errors.moadocument}
                        </div>
                      )}
                    </li>
                  )}
                  {this.state.showpriltddoc && (
                    <li className="form-group inventory_cont">
                      <label for="uploadaoa">
                        <sup className="mandatory">*</sup>Upload Articles of
                        Association (AoA) of Company
                      </label>
                      <div className="input-group divUploadFile">
                        <div className="qq-upload-drop-area">
                          <span>Drop files here to upload</span>
                        </div>
                        <div className="qq-upload-button">
                          Drag and Drop Files
                          <span className="redMandat">
                            Upload file type .pdf, .xls File size limit 4MB
                          </span>
                        </div>
                        <input
                          id="aoa"
                          type="file"
                          name="files"
                          onChange={(e) => this.setfile(e)}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </div>
                      <div>
                        <ul id="fileList" className="ul-project-files">
                          {this.state.aoafile !== null &&
                            this.state.aoafile.map((item, index) => (
                              <li class="file-list-li">
                                <i class="fa fa-check"></i>
                                {item.name}
                                <a
                                  class="remove-files-list"
                                  id={index}
                                  onClick={(e) =>
                                    this.removeFileFromList(e, "aoa")
                                  }
                                >
                                  Remove
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                      {this.state.aoadocument.length == 0 && (
                        <div className="mandatory has-error">
                          {this.state.errors.aoadocument}
                        </div>
                      )}
                    </li>
                  )}
                  {this.state.showotherdoc && (
                    <li className="form-group inventory_cont">
                      <label for="uploadcompanyincorpo">
                        <sup className="mandatory">*</sup>Upload Company
                        Incorporation Documents
                      </label>
                      <div className="input-group divUploadFile">
                        <div className="qq-upload-drop-area">
                          <span>Drop files here to upload</span>
                        </div>
                        <div className="qq-upload-button">
                          Drag and Drop Files
                          <span className="redMandat">
                            Upload file type .pdf, .xls File size limit 4MB
                          </span>
                        </div>
                        <input
                          id="compincorpo"
                          type="file"
                          name="files"
                          onChange={(e) => this.setfile(e)}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </div>
                      <div>
                        <ul id="fileList" className="ul-project-files">
                          {this.state.compincorporation !== null &&
                            this.state.compincorporation.map((item, index) => (
                              <li class="file-list-li">
                                <i class="fa fa-check"></i>
                                {item.name}
                                <a
                                  class="remove-files-list"
                                  id={index}
                                  onClick={(e) =>
                                    this.removeFileFromList(e, "compincorpor")
                                  }
                                >
                                  Remove
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                      {this.state.comincardocument.length == 0 && (
                        <div className="mandatory has-error">
                          {this.state.errors.comincardocument}
                        </div>
                      )}
                    </li>
                  )}
                  <li className="form-group inventory_cont">
                    <label for="uploadaddidocifany">
                      Upload Additional Document If Any
                    </label>
                    <div className="input-group divUploadFile">
                      <div className="qq-upload-drop-area">
                        <span>Drop files here to upload</span>
                      </div>
                      <div className="qq-upload-button">
                        Drag and Drop Files
                        <span className="redMandat">
                          Upload file type .pdf, .xls File size limit 4MB
                        </span>
                      </div>
                      <input
                        id="additonaldoc"
                        type="file"
                        name="files"
                        onChange={(e) => this.setfile(e)}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                    </div>
                    <div>
                      <ul id="fileList" className="ul-project-files">
                        {this.state.additionalfile !== null &&
                          this.state.additionalfile.map((item, index) => (
                            <li class="file-list-li">
                              <i class="fa fa-check"></i>
                              {item.name}
                              <a
                                class="remove-files-list"
                                id={index}
                                onClick={(e) =>
                                  this.removeFileFromList(e, "additional")
                                }
                              >
                                Remove
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                    {this.state.additionaldocument.length == 0 && (
                      <div className="mandatory has-error">
                        {this.state.errors.additionaldocument}
                      </div>
                    )}
                  </li>
                  {/* <li className="form-group regsucess">
                                        <div className={this.state.ApiSuccess === true ? 'msgsucess' : 'has-error'} >{this.state.errors.message}</div>
                                    </li> */}
                  <li className="form-group submitbtn_wrap">
                    <button
                      className="submit_btn"
                      type="submit"
                      onClick={this.DoRegistration}
                      name="Submit"
                      value="submit"
                    >
                      Submit
                    </button>
                    <button
                      className="cancel_btn"
                      type="reset"
                      onClick={this.gotoLogin}
                      name="Cancel"
                      value="cancel"
                    >
                      Cancel
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.configuration.token,
  };
}

export default connect(mapStateToProps)(Registeruser);
