import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button } from "@patternfly/react-core";
import { Scrollbars } from "react-custom-scrollbars";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Searchcomp from "../../Common/Search/Search";
import treesplant from "../../../assets/trees-plant.png";
import treesplantnew from "../../../assets/prolist_newtree.png";
import co2emission from "../../../assets/CO2-emission.png";
import amtraised from "../../../assets/amount-raised.png";
import modaltreeimg from "../../../assets/treedetail-img.jpg";
import growthicon from "../../../assets/growth-icon.jpg";
import dateicon from "../../../assets/date-icon.jpg";
import weathericon from "../../../assets/weather-icon.jpg";
import config from "../../../utils/config";
import { globalHistory } from "@reach/router";
import AddTreeType from "./AddTreeType";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import Loader from "../../Common/Loader/Loader";
import moment from "moment";
import MainViewer from "../../Common/Map/MainViewer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
//import Carousel from "react-elastic-carousel";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import GoogleMapViewer from "../../Common/Map/GoogleMapViewer";
import MapImage from "../../../assets/Map.PNG";

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    //this.props.func(this, 1234);
    this.initialState = {
      isModalOpen: false,
      ProjectId: "",
      ProjectDetail: null,
      List: [],
      count: 0,
      activePage: 0,
      pagesize: 0,
      PageName: "TreeTypes",
      SortBy: "PlantingDate",
      SortType: false,
      Up: "active",
      Down: "",
      PageRange: 10,
      SearchText: "",
      ProjectIndex: 1,
      ForestIsHealthy: false,
      ActiveItem: [],
      HealthyUnHealthyFilter: "",
      IsPopUpOpen: false,
      Applicationlist: [],
      MeetingScheduledModelIdx: false,
      TreeTypeImage: "",
      TreeTypeName: "",
      IsLoader: false,
      isshowMapView: true,
      isshowListView: false,
      isshowForestHealthScore: true,
      isShowTreeData: false,
      TreeData: "",
      isUploadTreeData: false,
      TreePlantedDate: "",
      TreePlantedBy: "",
      uploadfile: [],
      IframeGuid: null,
      VimanaBaseUrl: "",
      TreePlantedTillDate: "",
      TotalAliveTreeCount: 0,
      isModalOpen1: false,
      isModalOpen2: false,
      multipleImages: [],
      activeMediaitem: [],
      activeMediaitem1: [],
      ActiveProjectTreeTypesGrid: null,
      isModelProjectOverview: false,
      TreeItem: null,
      GoogleMapApiKey: "",
    };
    this.state = this.initialState;
    this.mounted = true;
    this.handleModalToggle = (e) => {
      this.setState(({ isModalOpen }) => ({
        isModalOpen: !isModalOpen,
      }));
    };
    this.handleProjectOverviewToggle = () => {
      this.setState(({ isModelProjectOverview }) => ({
        isModelProjectOverview: !isModelProjectOverview,
      }));
    };

    this.handleModalToggle1 = (e) => {
      this.setState(({ isModalOpen1 }) => ({
        isModalOpen1: !isModalOpen1,
      }));
    };

    this.handleModalToggle2 = (e) => {
      this.setState(({ isModalOpen2 }) => ({
        isModalOpen2: !isModalOpen2,
      }));
    };
  }
  CloseTreeDataiframe = () => {
    if (this.mounted) this.setState({ isShowTreeData: false });
  };
  componentDidMount() {
    this.mounted = true;
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    let Index = currentPath.split(":")[2].replace("/", "");
    this.setState({ ProjectId: Id, ProjectIndex: Index });
    this.GetProjectDetailById(Id);
    this.GetProjectTreeTypes(Id);
    this.GetForestIsHealthyCalcualtion(Id);
    this.GetGlobalSettings("Google Map");
  }
  GetGlobalSettings = (GroupName) => {
    let UserDetail = {
      GroupName: GroupName,
    };
    let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
    let fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (data.success) {
            let responseData = data.data;
            this.setState({
              GoogleMapApiKey: this.MapKeyInArray(
                responseData,
                "GoogleMapApiKey"
              ),
            });
          } else {
            let errors = {};
            errors["message"] = data.message;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  MapKeyInArray = (responseData, keyname) => {
    let myArray = responseData.find((obj) => obj.key === keyname);
    if (myArray) {
      return myArray.value;
    }
  };

  handleTreeTypeModalToggle = (item, AddOrUpdate) => {
    if (this.mounted) {
      this.setState({
        Applicationlist: item,
        AddOrUpdate: AddOrUpdate,
        IsPopUpOpen: true,
      });
    }
  };

  SetTreeValues = (item) => (e) => {
    item.ProjectTreeTypeId = 0;
    this.GetProjectTreeTypesGridDetail(item.gridGuid);
    this.setState({ ActiveItem: item }, () =>
      this.GetTreeTypeImage(item.treeType)
    );
    this.handleModalToggle(e);
  };

  //Set to open Media files - Epicollect
  SetImageValues = (item) => (e) => {
    this.setState({ activeMediaitem: item }, () => this.GetmultipleImage(item));
    this.handleModalToggle1(e);
  };
  SetVideoValues = (item) => (e) => {
    this.setState({ activeMediaitem1: item }, () =>
      this.GetmultipleImage(item)
    );
    this.handleModalToggle2(e);
  };

  GetProjectDetailById = (ProjectId) => {
    if (this.mounted) {
      let UserDetail = {
        ProjectId: ProjectId,
      };
      let uri = `${config.API_HOST_URL}/api/projects/GetProjectDetailById`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data.success) {
                if (this.mounted) {
                  this.setState({ ProjectDetail: data.data }, () => {
                    this.getIframedata();
                  });
                }
              } else {
                let errors = {};
                errors["message"] = data.message;
                if (this.mounted) {
                  this.setState({
                    errors: errors,
                    ProjectDetail: [],
                  });
                }
              }
            });
          } else {
            return response.json().then((data) => {
              let errors = {};
              errors["message"] = data.message;
              if (this.mounted) {
                this.setState({
                  errors: errors,
                });
              }
            });
          }
        })
        .catch((error) => {
          if (this.mounted) {
            console.log(error);
            this.setState({
              errorMsg: error,
              IsLoader: false,
            });
          }
        });
    }
  };
  FilterTreeTypes = (event) => {
    let value = event.target.getAttribute("value");
    this.setState({ HealthyUnHealthyFilter: value }, () =>
      this.GetProjectTreeTypes(this.state.ProjectId)
    );
  };

  GetProjectTreeTypes = (ProjectId) => {
    if (this.mounted) {
      let SearchText = "null";
      let Status = "null";
      if (this.state.SearchText !== "") SearchText = this.state.SearchText;
      if (this.state.HealthyUnHealthyFilter !== "")
        Status = this.state.HealthyUnHealthyFilter;

      let uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/GetProjectTreeTypes/${this.state.activePage}/${this.state.pagesize}/${SearchText}/${this.state.SortType}/${this.state.SortBy}/${ProjectId}/${Status}`;
      let fetchOptions = {
        method: "GET",
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          ViewType: this.state.isshowMapView ? "map" : "list",
        },
      };

      fetch(uri, fetchOptions)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data.success) {
                if (this.mounted) {
                  let responseData = data;
                  this.setState({
                    userTotalCount: responseData.list.length,
                    All: responseData.all,
                    NGO: responseData.ngo,
                    Private: responseData.private,
                    Others: responseData.others,
                    List: responseData.list,
                    count: responseData.count,
                    TotalAliveTreeCount: responseData.totalAliveTreeCount,
                    LastUpdatedDate: responseData.lastUpdatedDate,
                    PageSize: 2,
                    IsPopupDataLoaded: true,
                    isDataLoaded: true,
                  });
                }
              } else {
                if (this.mounted) {
                  this.setState({
                    List: [],
                    count: 0,
                    PageSize: 2,
                    IsPopupDataLoaded: true,
                    isDataLoaded: true,
                  });
                }
              }
            });
          } else {
            return response.json().then((data) => {
              if (this.mounted) {
              }
            });
          }
        })
        .catch((error) => {
          if (this.mounted) {
            console.log(error);
          }
        });
    }
  };

  GetForestIsHealthyCalcualtion = (ProjectId) => {
    if (this.mounted) {
      let UserDetail = {
        ProjectId: ProjectId,
      };
      let uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/GetForestIsHealthyCalcualtion`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              let responseData = data;
              if (responseData.success === "1") {
                if (this.mounted) {
                  this.setState({
                    ForestIsHealthy: responseData.forestIsHealthy,
                  });
                }
              } else {
                if (this.mounted) {
                }
              }
            });
          } else {
            return response.json().then((data) => {
              if (this.mounted) {
              }
            });
          }
        })
        .catch((error) => {
          if (this.mounted) {
            this.setState({ isshowForestHealthScore: false });
            console.log(error);
          }
        });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleButtonClick = (GroupGuid, GroupName) => {
    this.setState({
      IsShowpopup: !this.state.IsShowpopup,
      IsPopupDataLoaded: false,
      userActivePage: 1,
    });
    this.GetProjectTreeTypes(this.state.ProjectId);
  };

  handlePageChange = (pageNumber) => {
    if (this.mounted) {
      this.setState({ activePage: pageNumber }, function () {
        this.GetProjectTreeTypes(this.state.ProjectId);
      });
    }
  };

  handleSortClick = (SortBy) => {
    let up = "";
    let down = "active";
    let sortType = this.state.SortType;

    if (SortBy === this.state.SortBy) sortType = !this.state.SortType;

    if (sortType) {
      up = "active";
      down = "";
    }

    this.setState(
      {
        SortBy: SortBy,
        SortType: sortType,
        Up: up,
        Down: down,
      },
      function () {
        this.GetProjectTreeTypes(this.state.ProjectId);
      }
    );
  };

  handleChange = (e) => {
    if (this.mounted) {
      this.setState({
        SearchText: e.target.value,
      });
    }
  };

  handleSearchByEnter = (e) => {
    if (e.key === "Enter") {
      if (this.mounted) {
        this.setState(
          {
            SearchText: e.target.value,
            activePage: 1,
          },
          function () {
            this.GetProjectTreeTypes(this.state.ProjectId);
          }
        );
      }
    }
  };

  handleparentMethod = () => {
    let Id = this.state.ProjectId;
    // //this.setState(this.initialState, function () {
    //     this.GetProjectDetailById(Id);
    //     this.GetProjectTreeTypes(Id);
    //     this.GetForestIsHealthyCalcualtion(Id);
    // //})
    this.setState(
      {
        IsPopUpOpen: false,
      },
      function () {
        this.GetProjectDetailById(Id);
        this.GetProjectTreeTypes(Id);
        this.GetForestIsHealthyCalcualtion(Id);
      }
    );
  };

  goBack() {
    window.history.back();
  }

  // Bring Epicollect Media files
  GetmultipleImage = (item) => {
    if (this.mounted) {
      let UserDetail = {
        ProjectId: item.projectId,
        Ec5_uuid: item.ec5_uuid,
      };
      let uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/GetProjectTreeTypeImage/${item.projectId}/${item.ec5_uuid}`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          let records = JSON.parse(data.List);
          if (this.mounted && records.length > 0) {
            this.setState({
              multipleImages: records,
            });
          } else {
            this.setState({
              multipleImages: [],
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  GetTreeTypeImage = (TreeTypeName) => {
    if (this.mounted) {
      let UserDetail = {
        TreeTypeName: TreeTypeName,
      };
      let uri = `${config.API_HOST_URL}/api/TreeType/GetTreeTypeImage`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((data) => {
          let records = JSON.parse(data.List);
          if (this.mounted && records.length > 0) {
            this.setState({
              TreeTypeImage: `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreeTypes/${records[0].TreeTypeImgPath}`,
            });
          } else {
            this.setState({
              TreeTypeImage: modaltreeimg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  ExportReportToExcel = () => {
    if (this.mounted) {
      this.setState({
        IsLoader: true,
      });
      //this.state.ProjectDetail.forestHealthyPercentage
      // window.location = `${config.API_HOST_URL}/api/ProjectTreeTypes/ExportProjectDetails/${this.state.ProjectId}/${this.state.ProjectDetail.projectName}/${this.state.ProjectDetail.location}/${this.state.ProjectDetail.isForestHealthy}/${100}`;
      window.location = `${
        config.API_HOST_URL
      }/api/ProjectTreeTypes/ExportProjectDetails/${this.state.ProjectId}/${
        this.state.ProjectDetail.projectName
      }/${this.state.ProjectDetail.location}/${
        this.state.ProjectDetail.isForestHealthy
      }/${this.state.ProjectDetail.forestHealthyPercentage.toFixed(1)}`;
      setTimeout(() => this.setState({ IsLoader: false }), 300);
    }
  };
  ShowTreeData = (treeData) => {
    var tdata;
    if (treeData === null) {
      tdata = null; //"https://vimana.vimanalabs.com/share/7e30f9a6b1174b62abbee00841a65c8f?overlayGeojson=ewogInR5cGUiOiAiRmVhdHVyZUNvbGxlY3Rpb24iLAogIm5hbWUiOiAiZ3JlZW4taGltYWxheWEtdHJlZS1oZWlnaHQtYW5hbHl0aWNzLTEiLAogImNycyI6IHsKICAidHlwZSI6ICJuYW1lIiwKICAicHJvcGVydGllcyI6IHsKICAgIm5hbWUiOiAidXJuOm9nYzpkZWY6Y3JzOk9HQzoxLjM6Q1JTODQiCiAgfQogfSwKICJmZWF0dXJlcyI6IFsKICB7CiAgICJ0eXBlIjogIkZlYXR1cmUiLAogICAicHJvcGVydGllcyI6IHsKICAgICJ0cmVlLWhlaWdodCI6IDIuMjc0NzMKICAgfSwKICAgImdlb21ldHJ5IjogewogICAgInR5cGUiOiAiUG9pbnQiLAogICAgImNvb3JkaW5hdGVzIjogWwogICAgIDc4LjAwNTc4NTI0MDU1Nzg0LAogICAgIDE3Ljg4Mzk2Nzc2MzkwMzc1CiAgICBdCiAgIH0KICB9CiBdCn0="
    } else {
      tdata =
        this.state.ProjectDetail.iframeBaseUrl === null
          ? "https://vimana.vimanalabs.com/share/7e30f9a6b1174b62abbee00841a65c8f"
          : this.state.ProjectDetail.iframeBaseUrl +
            "?overlayGeojson=" +
            btoa(JSON.stringify(treeData));
    }
    this.setState(({ isShowTreeData }) => ({
      isShowTreeData: !isShowTreeData,
      TreeData: tdata,
    }));
  };

  ShowTreeOnMap = (treeItem) => {
    //  debugger
    //Condition for GOA project
    if (this.state.ProjectDetail.isShowLayersOnMap === true) {
      this.setState({
        isshowMapView: true,
        isshowListView: false,
        TreeItem: treeItem,
      });
    } else {
      this.ShowTreeData(treeItem.treeData);
    }
    //  console.log("item", this.state.TreeItem )
  };

  ShowUploadTreeData = () => {
    if (this.mounted)
      this.setState({ isUploadTreeData: !this.state.isUploadTreeData });
  };
  CloseUploadTreeData = () => {
    if (this.mounted)
      this.setState({
        isUploadTreeData: !this.state.isUploadTreeData,
        TreePlantedBy: "",
        TreePlantedDate: "",
        uploadfile: [],
        VimanaBaseUrl: "",
        TreePlantedTillDate: "",
      });
  };
  SelectPlantedDate = (e) => {
    if (this.mounted) this.setState({ TreePlantedDate: e.target.value });
  };
  ChangeTreePlantedBy = (event) => {
    if (this.mounted) this.setState({ TreePlantedBy: event.target.value });
  };
  ChangeTreePlantedTillDate = (event) => {
    const regmobno = /^[0-9\b]+$/;
    if (this.mounted) {
      if (event.target.value === "" || regmobno.test(event.target.value))
        this.setState({ TreePlantedTillDate: event.target.value });
    }
  };
  ChangeVimanaBaseUrl = (event) => {
    if (this.mounted) this.setState({ VimanaBaseUrl: event.target.value });
  };
  ProjectLocationData = () => {
    document.getElementById("locationDataUpload").click();
  };
  setFile = (e) => {
    let filesArr = "";
    if (e.target.id === "uploadjsonfile") {
      if (e.target.files[0].name.match(/\.(geojson)$/)) {
        filesArr = e.target.files;
        var array = [...this.state.uploadfile];
        array.push(e.target.files);
        this.setState({ uploadfile: array });
        var filesize = array.length;
        if (filesize === 1) {
          for (var i = 0; i < filesArr.length; ++i) {
            let myFilesList = this.state.uploadfile;
            myFilesList.push(filesArr[i]);
            this.setState({ uploadfile: myFilesList });
          }
        } else {
          if (e.target.id == "uploadjsonfile") {
            var index = array.indexOf(e.target.value, 1);
            array.splice(index, 1);
            this.setState({ uploadfile: array });
            ToastNotification.ShowWarning("Select only one file");
          }
        }
      } else {
        alert("Allowed extension are .json file");
      }
    } else if (e.target.id === "locationDataUpload") {
      filesArr = e.target.files;
      const formData = new FormData();
      for (var i = 0; i < filesArr.length; ++i) {
        if (filesArr[i].name.match(/\.(geojson)$/)) {
          formData.append("FormFiles", filesArr[i]);
          let uri = `${config.API_HOST_URL}/api/Projects/UploadProjectLocationData/${this.state.ProjectDetail.projectId}/${this.props.userData.userId}/${this.props.userData.nameOfNGO}`;
          let fetchOptions = {
            method: "POST",
            body: formData,
            headers: {
              WebsiteGuid: this.props.websiteGUID,
              LanguageGuid: this.props.languageGUID,
              Authorization: `Bearer ${this.props.token}`,
              Accept: "application/json",
            },
          };
          fetch(uri, fetchOptions)
            .then((response) => {
              const contentType = response.headers.get("content-type");
              if (
                contentType &&
                contentType.indexOf("application/json") !== -1
              ) {
                return response.json().then((data) => {
                  if (data.success) {
                    let projectDetail = this.state.ProjectDetail;
                    projectDetail["projectLocationFile"] = data.UploadFileName;
                    projectDetail["lastUpdatedDate"] = data.LastUpdatedDate;
                    projectDetail["locationCords"] = data.LocationCords;
                    this.setState({ ProjectDetail: projectDetail });
                    document.getElementById("showmap").click();
                    ToastNotification.ShowSuccess(data.message);
                    this.getIframedata();
                  } else {
                    ToastNotification.ShowSuccess(data.message);
                  }
                });
              } else {
                return response.json().then((data) => {
                  ToastNotification.ShowSuccess(data.message);
                });
              }
            })
            .catch((error) => {
              if (this.mounted) {
                ToastNotification.ShowSuccess(error);
              }
            });
        } else {
          alert("Allowed extension are .json file");
        }
      }
    }
    e.target.value = null;
  };
  UploadTreeData = (e) => {
    if (this.mounted) {
      let file = this.state.uploadfile;
      const formData = new FormData();
      for (var index = 0; index < file.length; index++) {
        var element = file[index];
        formData.append("FormFiles", element);
      }
      var treeplntDate = new Date(this.state.TreePlantedDate).toUTCString();
      formData.append("TreePlantedDate", treeplntDate);
      formData.append("TreePlantedBy", this.state.TreePlantedBy);
      formData.append("NameOfNGO", this.props.userData.nameOfNGO);
      formData.append("ProjectName", this.state.ProjectDetail.projectName);
      formData.append("ProjectId", this.state.ProjectDetail.projectId);
      formData.append("UserGuid", this.props.userData.userId);
      formData.append("IframeBaseUrl", this.state.VimanaBaseUrl);
      formData.append("TreePlantedTillDate", this.state.TreePlantedTillDate);

      let uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/UploadTreeData`;
      let fetchOptions = {
        method: "POST",
        body: formData,
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          Accept: "application/json",
          ContentType: "multipart/form-data",
        },
      };
      fetch(uri, fetchOptions)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data.success) {
                this.CloseUploadTreeData();
                let projectDetail = this.state.ProjectDetail;
                projectDetail["lastUpdatedDate"] = data.LastUpdatedDate;
                this.setState({ ProjectDetail: projectDetail });
                document.getElementById("treelist").click();
                ToastNotification.ShowSuccess(data.message);
                this.GetProjectDetailById(this.state.ProjectId);
              } else {
                this.CloseUploadTreeData();
                ToastNotification.ShowSuccess(data.message);
              }
            });
          } else {
            return response.json().then((data) => {
              this.CloseUploadTreeData();
              ToastNotification.ShowSuccess(data.message);
            });
          }
        })
        .catch((error) => {
          if (this.mounted) {
            this.CloseUploadTreeData();
            ToastNotification.ShowSuccess(error);
          }
        });
    }
  };
  getIframedata() {
    var iframeguid;
    let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/${this.state.ProjectDetail.projectName}/${this.state.ProjectDetail.projectLocationFile}`;
    fetch(url)
      .then(function (res) {
        return res.json();
      })
      .then((data) => {
        var result = JSON.stringify(data);
        iframeguid = btoa(JSON.stringify(result));
        this.setState({ IframeGuid: iframeguid });
      })
      .catch(function (err) {
        console.log(err, " error");
      });
  }
  removeFileFromList = (e) => {
    var array = [...this.state.uploadfile];
    array.splice(e.target.id, 1);
    this.setState({ uploadfile: array });
  };

  GetProjectTreeTypesGridDetail = (gridGuid) => {
    if (this.mounted) {
      let uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/GetProjectTreeTypesGridDetail/${gridGuid}`;
      let fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      fetch(uri, fetchOptions)
        .then((resp) => resp.json())
        .then((result) => {
          let records = result.data;
          if (this.mounted && records !== null) {
            this.setState({
              ActiveProjectTreeTypesGrid: records,
            });
          } else {
            this.setState({
              ActiveProjectTreeTypesGrid: null,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleDownloadFile = (FileName) => {
    //debugger

    let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/Goa%20Pilot%20Project/${FileName}`;
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = FileName;
        a.click();
      });
    });
  };

  render() {
    const {
      isModalOpen,
      isModalOpen1,
      isModalOpen2,
      isshowMapView,
      isshowListView,
      isModelProjectOverview,
    } = this.state;

    const renderThumb = ({ style, ...props }) => {
      const thumbStyle = {
        borderRadius: 6,
        innerWidth: 4,
        backgroundColor: "rgba(51, 51, 51, 1)",
      };
      return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };
    const OpenAModal = true;
    const CustomScrollbars = (props) => (
      <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
      />
    );
    const totalvalue =
      this.state.ProjectDetail &&
      this.state.ProjectDetail.treePlantedTillDate +
        Number(this.state.ProjectDetail.co2Nutrilized).toFixed(4) +
        this.state.ProjectDetail.amountRaised;
    return (
      <>
        {this.state.IsLoader ? (
          <Loader />
        ) : (
          this.state.ProjectDetail && (
            <>
              <div className="headbottom_cont">
                <Modal
                  title=""
                  isOpen={this.state.isShowTreeData}
                  className="mapmodalviewpopup"
                  onClose={this.CloseTreeDataiframe}
                >
                  <div className="mapmodalview_cont">
                    {this.state.TreeData != null ? (
                      <iframe
                        width="90%"
                        height="90%"
                        src={this.state.TreeData}
                      ></iframe>
                    ) : (
                      <div>Record not found</div>
                    )}
                  </div>
                </Modal>
                <Modal
                  title="Simple modal header"
                  isOpen={isModelProjectOverview}
                  onClose={this.handleProjectOverviewToggle}
                  className="projdetailpage_modal addproject_modal projectModalVideo projectdetactnbtn_modal"
                >
                  <div className="treedetails_cont">
                    <Container>
                      <Row>
                        <Col lg="12">
                          <div className="treedetails_wrap porjectviewmod_cont">
                            <Accordion>
                              <AccordionItem header="Project Overview">
                                <ul className="treedetails_list projectview_cont">
                                  <li>
                                    {/* <div className="treename">About the project: {this.state.ProjectDetail.projectName}</div> */}
                                    <div className="treename">
                                      {" "}
                                      Project Name: Renuka Bag Farms
                                    </div>
                                  </li>
                                  <li>
                                    {/* <div className="treename">Which ecoregion (CT): {this.state.ProjectDetail.location}</div> */}
                                    <div className="treename">
                                      Owner: Amit and Nitin Bandekar
                                    </div>
                                  </li>
                                  <li>
                                    {/* <div className="treename">Objective of the project: {}</div> */}
                                    <div className="treename">
                                      {" "}
                                      Site Location: Xeldem village, Quepem
                                      taluka, South Goa, Goa
                                    </div>
                                  </li>
                                  <li>
                                    {/* <div className="treename">Project Type: {this.state.ProjectDetail.projectType}</div> */}
                                    <div className="treename">
                                      Area: 52 acres
                                    </div>
                                  </li>
                                  <li>
                                    {/* <div className="treename">Project Start date: {  this.state.ProjectDetail.launchDate}</div> */}
                                    <div className="treename">
                                      {" "}
                                      Site Map :{" "}
                                      <img
                                        src={MapImage}
                                        alt="Map"
                                        width="100"
                                        height="100"
                                      ></img>
                                    </div>
                                  </li>
                                  <li>
                                    {/* <div className="treename">Status: {this.state.ProjectDetail.status}</div> */}
                                    <div className="treename">
                                      Ecoregion : Malabar Coast Moist Forests
                                    </div>
                                  </li>
                                  <li>
                                    <div className="treename">
                                      Climate : Climate of ecoregion is defined
                                      by below parameters
                                      <table>
                                        <tr>
                                          <th>Parameter</th>
                                          <th>Avg</th>
                                          <th>Min</th>
                                          <th>Max</th>
                                        </tr>
                                        <tr>
                                          <td>Temperature (deg Celsius)</td>
                                          <td>28</td>
                                          <td>20</td>
                                          <td>40</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Rainfall (in mm) - Total annual
                                          </td>
                                          <td>195</td>
                                          <td>100</td>
                                          <td>350</td>
                                        </tr>
                                      </table>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="treename">
                                      Soil type : Lateritic soil{" "}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="treename">
                                      {" "}
                                      Project Type : Horticulture{" "}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="treename">
                                      {" "}
                                      Site Use : Mixed Horticulture produce,
                                      Recreation, Restoration
                                    </div>
                                  </li>
                                </ul>
                              </AccordionItem>
                              <AccordionItem header="Collaborations">
                                {/* <ul className="treedetails_list">
                                                                            <li>
                                                                        <div className="treename">Implementation agency: {}</div>
                                                                        </li>
                                                                        <li>
                                                                        <div className="treename">Funding agency: {}</div>
                                                                        </li>
                                                                        <li>
                                                                        <div className="treename">Science Expert associated: {}</div>
                                                                        </li>
                                                                        <li>
                                                                        <div className="treename">TreeTies POC: {}</div>
                                                                        </li>
                                                                        </ul> */}

                                <ul className="treedetails_list projectview_cont">
                                  <li>
                                    <div className="treename">
                                      Advisors : ICAR Agriculture Science
                                      experts
                                    </div>
                                  </li>
                                  <li>
                                    <div className="treename">
                                      Implemented by : Owner
                                    </div>
                                  </li>
                                  <li>
                                    <div className="treename">
                                      Funded by : Owner
                                    </div>
                                  </li>
                                  <li>
                                    <div className="treename">
                                      TreeTies POC : Madhura Niphadkar
                                    </div>
                                  </li>
                                </ul>
                              </AccordionItem>
                            </Accordion>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Modal>
                <ul className="headbottom_content">
                  <li
                    className="projdet_heading"
                    key={this.state.ProjectDetail.projectName}
                  >
                    <a className="gobackbtn" onClick={this.goBack}>
                      <i className="icon icon-menu-left-arrow"></i>
                    </a>
                    <span className="prolist_name">
                      {this.state.ProjectIndex}.{" "}
                      {this.state.ProjectDetail.projectName}
                    </span>

                    {this.state.ProjectDetail.location !== null ? (
                      <div className="prolisttime_cont logilangval">
                        <span>
                          <i className="icon icon-location-pin"></i>
                          {this.state.ProjectDetail.location}
                        </span>
                        <div className="polycord">
                          <div className="polycordlocationab">
                            <span className="cordone">
                              <b>A)</b>
                              {this.state.ProjectDetail.locationCords[0]},
                            </span>
                            <span className="cordtwo">
                              {this.state.ProjectDetail.locationCords[1]}
                            </span>
                            <span className="cordthree">
                              <b>B)</b>
                              {this.state.ProjectDetail.locationCords[2]},
                            </span>
                            <span className="cordfour">
                              {" "}
                              {this.state.ProjectDetail.locationCords[3]}
                            </span>
                          </div>
                          <div className="polycordlocationcd">
                            <span className="cordfive">
                              <b>C)</b>
                              {this.state.ProjectDetail.locationCords[4]},
                            </span>
                            <span className="cordsix">
                              {this.state.ProjectDetail.locationCords[5]}
                            </span>
                            <span className="cordseven">
                              <b>D)</b>
                              {this.state.ProjectDetail.locationCords[6]}
                            </span>
                            <span className="cordeight">
                              {this.state.ProjectDetail.locationCords[7]}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="prolistverified_btn">
                      <span>{this.state.ProjectDetail.status}</span>
                      <i className="icon icon-verified"></i>
                    </div>
                    {this.state.isshowForestHealthScore && (
                      <div>
                        <div className="prodetprogresscont">
                          <CircularProgressbar
                            width={50}
                            height={50}
                            value={
                              (this.state.TotalAliveTreeCount /
                                this.state.ProjectDetail.treePlantedTillDate) *
                              100
                            }
                            text={`${Number(
                              (this.state.TotalAliveTreeCount /
                                this.state.ProjectDetail.treePlantedTillDate) *
                                100
                            ).toFixed(1)}%`}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              textColor: "#333",
                              pathColor:
                                this.state.ProjectDetail.isForestHealthy == true
                                  ? "#95C13D"
                                  : "#B54E56",
                              trailColor: "#D9D9D9",
                            })}
                          />
                        </div>
                        <div className="projdetcont_btn">
                          <a
                            href="#"
                            className={
                              this.state.ForestIsHealthy === "true"
                                ? "hlthyforbtn"
                                : "unhlthyforbtn"
                            }
                          >
                            {this.state.ForestIsHealthy === "true"
                              ? "Forest is Healthy"
                              : "Forest is not Healthy"}
                          </a>
                        </div>
                      </div>
                    )}
                  </li>

                  <li className="search_container">
                    <Searchcomp />
                  </li>
                  <div className="downloadreprt_btn">
                    {this.state.ProjectDetail.isShowLayersOnMap === true ? (
                      <Button
                        onClick={() =>
                          this.handleDownloadFile(
                            this.state.ProjectDetail.projectReportFile
                          )
                        }
                      >
                        Download Report{" "}
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                  <li className="export_btn">
                    {this.state.List.length > 0 && (
                      <Button onClick={() => this.ExportReportToExcel()}>
                        Tree Registry <i className="fa fa-plus"></i>
                      </Button>
                    )}
                  </li>
                </ul>
              </div>
              <div className="projectdetail_container">
                <div className="projdetcont_row1">
                  {totalvalue > 0 && (
                    <div className="projdetcontlist_wrap">
                      <div className="prolistinner_list ">
                        <div className="prolist_tree">
                          <img src={treesplantnew} alt="trees plant" />
                        </div>
                        <div className="prolist_treedet">
                          <span>Trees Planted Till Date</span>
                          <span>
                            {this.state.ProjectDetail.treePlantedTillDate}
                          </span>
                        </div>
                      </div>

                      <div className="prolistinner_list">
                        <div className="prolist_tree">
                          <img src={co2emission} alt="CO2 emission" />
                        </div>
                        {/* <div className="prolist_treedet">
                          <span>Carbon Sequestered - Till Now</span>
                          <span>
                            {Number(
                              this.state.ProjectDetail.co2Nutrilized * 1000
                            ).toFixed(2)}{" "}
                            Kg
                          </span>
                        </div> */}
                        <div className="prolist_progress">
                          {/* <CircularProgressbar
                                        width={150}
                                        height={150}
                                        value={co2emissionpercent}
                                        text={`${co2emissionpercent}%`}
                                        styles={buildStyles({
                                            strokeLinecap: "butt",
                                            textColor: "#333",
                                            pathColor: "#E8BF36",
                                            trailColor: "#D9D9D9"
                                        })}
                                    /> */}
                        </div>
                      </div>
                      <div className="prolistinner_list">
                        <div className="prolist_tree">
                          <img src={amtraised} alt="trees plant" />
                        </div>
                        <div className="prolist_treedet">
                          <span>Amount Raised</span>
                          <span>
                            RS. {this.state.ProjectDetail.amountRaised}
                          </span>
                        </div>
                        <div className="prolist_progress">
                          <CircularProgressbar
                            width={150}
                            height={150}
                            value={
                              (this.state.ProjectDetail.amountRaised /
                                this.state.ProjectDetail.fundraiserGoal) *
                              100
                            }
                            text={`${
                              isNaN(
                                Number(
                                  (this.state.ProjectDetail.amountRaised /
                                    this.state.ProjectDetail.fundraiserGoal) *
                                    100
                                ).toFixed(1)
                              )
                                ? 0
                                : Number(
                                    (this.state.ProjectDetail.amountRaised /
                                      this.state.ProjectDetail.fundraiserGoal) *
                                      100
                                  ).toFixed(1)
                            }%`}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              textColor: "#333",
                              pathColor: "#95C13D",
                              trailColor: "#D9D9D9",
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="growalarm_content projdetcont_row2">
                  <div className="commontblfltr_wrap">
                    <div className="showtxtnbtns_wrap">
                      {this.state.isshowListView && (
                        <div className="showingtxt">
                          {(this.state.HealthyUnHealthyFilter == "Alive" ||
                            this.state.HealthyUnHealthyFilter == "") && (
                            <span>
                              Showing{" "}
                              {this.state.pagesize <= this.state.count
                                ? this.state.pagesize
                                : this.state.count}{" "}
                              of {this.state.count} Tree data
                            </span>
                          )}
                          {this.state.ProjectDetail.lastUpdatedDate && (
                            <span className="projdettime">
                              Last Updated On :
                              <Moment format="DD MMM, YYYY">
                                {this.state.ProjectDetail.lastUpdatedDate}
                              </Moment>
                            </span>
                          )}
                          <ul className="filterlist">
                            <li
                              className={
                                this.state.HealthyUnHealthyFilter === "Alive"
                                  ? "active"
                                  : ""
                              }
                            >
                              <a value="Alive" onClick={this.FilterTreeTypes}>
                                Healthy{" "}
                              </a>
                            </li>
                            <li
                              className={
                                this.state.HealthyUnHealthyFilter === "Dead"
                                  ? "active"
                                  : ""
                              }
                            >
                              <a value="Dead" onClick={this.FilterTreeTypes}>
                                UnHealthy{" "}
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                      {this.state.isshowMapView && (
                        <div className="showingtxt">Location On Map</div>
                      )}
                      <div className="showbtn_cont">
                        {/* <a className="adddetbtn" onClick={() => { this.handleTreeTypeModalToggle() }}>Add Details</a> */}
                        {this.props.userData.roleId &&
                          this.props.userData.roleId === 1 && (
                            <React.Fragment>
                              <a
                                className="adddetbtn"
                                onClick={() => this.ProjectLocationData()}
                              >
                                Upload Project Location
                              </a>
                              <input
                                type="file"
                                name="files"
                                id="locationDataUpload"
                                onChange={(e) => this.setFile(e)}
                                style={{ display: "none" }}
                              />
                              <a
                                className="adddetbtn"
                                onClick={() => this.ShowUploadTreeData()}
                              >
                                Upload Tree Data
                              </a>{" "}
                            </React.Fragment>
                          )}
                        {/* <a className="adddetbtn" onClick={() => { this.setState({ isshowListView: true, isshowMapView: false, activePage: 1, pagesize: 10, List: [] }, () => this.GetProjectTreeTypes(this.state.ProjectId)) }}>View Details</a> */}
                        {this.state.ProjectDetail.isShowLayersOnMap === true ? (
                          <a
                            className="adddetbtn"
                            onClick={() => this.handleProjectOverviewToggle()}
                          >
                            Project View
                          </a>
                        ) : (
                          ""
                        )}
                        <a
                          id="treelist"
                          className={`lineicon ${
                            isshowListView ? "active" : ""
                          }`}
                          onClick={() => {
                            this.setState(
                              {
                                isshowListView: true,
                                isshowMapView: false,
                                activePage: 1,
                                pagesize: 10,
                                List: [],
                              },
                              () =>
                                this.GetProjectTreeTypes(this.state.ProjectId)
                            );
                          }}
                        >
                          <i className="icon icon-menu-of-three-lines"></i>
                        </a>
                        <a
                          id="showmap"
                          className={`pinicon ${isshowMapView ? "active" : ""}`}
                          onClick={() => {
                            this.setState(
                              {
                                isshowMapView: true,
                                isshowListView: false,
                                activePage: 0,
                                pagesize: 0,
                                List: [],
                                TreeItem: null,
                              },
                              () =>
                                this.GetProjectTreeTypes(this.state.ProjectId)
                            );
                          }}
                        >
                          <i className="icon icon-location-pin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  {this.state.isshowMapView && (
                    <div className="viewmap">
                      {/* Condition For GOA Project */}
                      {this.state.ProjectDetail.isShowLayersOnMap === true ? (
                        <GoogleMapViewer
                          projectDetail={this.state.ProjectDetail}
                          locationCords={this.state.ProjectDetail.locationCords}
                          projectTreeType={this.state.TreeItem}
                          GoogleMapApiKey={this.state.GoogleMapApiKey}
                          handleModalToggle={this.handleModalToggle}
                        ></GoogleMapViewer>
                      ) : (
                        <MainViewer
                          key={this.state.List.length}
                          locationCords={this.state.ProjectDetail.locationCords}
                          cameraPosition={
                            this.state.ProjectDetail.cameraPosition
                          }
                          plotPlanted={this.state.List}
                          IframeGuid={this.state.IframeGuid}
                          IframeBaseUrl={this.state.ProjectDetail.iframeBaseUrl}
                        />
                      )}
                      {/* <MainViewer key={this.state.List.length} locationCords={this.state.ProjectDetail.locationCords} cameraPosition={this.state.ProjectDetail.cameraPosition} plotPlanted={this.state.List} IframeGuid={this.state.IframeGuid} IframeBaseUrl={this.state.ProjectDetail.iframeBaseUrl} /> */}
                    </div>
                  )}
                  {this.state.isshowListView && (
                    <div className="commontbl_wrap">
                      <CustomScrollbars
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                      >
                        <table className="commontbl_cont treedettbl_cont">
                          <thead>
                            <tr>
                              <th className="thGridNo">Grid No.</th>
                              <th className="idth">
                                Tree Id{/* PLOT ID */}
                                <div
                                  className="sortArrow"
                                  onClick={() => this.handleSortClick("PlotId")}
                                >
                                  <i
                                    className={
                                      this.state.SortBy === "PlotId"
                                        ? `fas fa-sort-up ${this.state.Up}`
                                        : "fas fa-sort-up"
                                    }
                                  ></i>
                                  <i
                                    className={
                                      this.state.SortBy === "PlotId"
                                        ? `fas fa-sort-down ${this.state.Down}`
                                        : "fas fa-sort-down"
                                    }
                                  ></i>
                                </div>
                              </th>
                              <th className="treetypeth">
                                Species Name {/* Tree Type PLOT TYPE */}
                                <div
                                  className="sortArrow"
                                  onClick={() =>
                                    this.handleSortClick("TreeType")
                                  }
                                >
                                  <i
                                    className={
                                      this.state.SortBy === "TreeType"
                                        ? `fas fa-sort-up ${this.state.Up}`
                                        : "fas fa-sort-up"
                                    }
                                  ></i>
                                  <i
                                    className={
                                      this.state.SortBy === "TreeType"
                                        ? `fas fa-sort-down ${this.state.Down}`
                                        : "fas fa-sort-down"
                                    }
                                  ></i>
                                </div>
                              </th>
                              <th className="statusoneth">
                                Tree Planted Date
                                <div
                                  className="sortArrow"
                                  onClick={() =>
                                    this.handleSortClick("PlantingDate")
                                  }
                                >
                                  <i
                                    className={
                                      this.state.SortBy === "PlantingDate"
                                        ? `fas fa-sort-up ${this.state.Up}`
                                        : "fas fa-sort-up"
                                    }
                                  ></i>
                                  <i
                                    className={
                                      this.state.SortBy === "PlantingDate"
                                        ? `fas fa-sort-down ${this.state.Down}`
                                        : "fas fa-sort-down"
                                    }
                                  ></i>
                                </div>
                              </th>
                              <th className="statusoneth">
                                STATUS
                                <div
                                  className="sortArrow"
                                  onClick={() => this.handleSortClick("Status")}
                                >
                                  <i
                                    className={
                                      this.state.SortBy === "Status"
                                        ? `fas fa-sort-up ${this.state.Up}`
                                        : "fas fa-sort-up"
                                    }
                                  ></i>
                                  <i
                                    className={
                                      this.state.SortBy === "Status"
                                        ? `fas fa-sort-down ${this.state.Down}`
                                        : "fas fa-sort-down"
                                    }
                                  ></i>
                                </div>
                              </th>
                              <th className="statusoneth">
                                Tree Planted By
                                <div
                                  className="sortArrow"
                                  onClick={() =>
                                    this.handleSortClick("TreePlantedby")
                                  }
                                >
                                  <i
                                    className={
                                      this.state.SortBy === "TreePlantedby"
                                        ? `fas fa-sort-up ${this.state.Up}`
                                        : "fas fa-sort-up"
                                    }
                                  ></i>
                                  <i
                                    className={
                                      this.state.SortBy === "TreePlantedby"
                                        ? `fas fa-sort-down ${this.state.Down}`
                                        : "fas fa-sort-down"
                                    }
                                  ></i>
                                </div>
                              </th>
                              <th className="statusoneth">
                                Tree Height (meter)
                                <div
                                  className="sortArrow"
                                  onClick={() =>
                                    this.handleSortClick("TreeHeight")
                                  }
                                >
                                  <i
                                    className={
                                      this.state.SortBy === "TreeHeight"
                                        ? `fas fa-sort-up ${this.state.Up}`
                                        : "fas fa-sort-up"
                                    }
                                  ></i>
                                  <i
                                    className={
                                      this.state.SortBy === "TreeHeight"
                                        ? `fas fa-sort-down ${this.state.Down}`
                                        : "fas fa-sort-down"
                                    }
                                  ></i>
                                </div>
                              </th>
                              <th className="statusoneth">
                                Planting Organization
                                <div
                                  className="sortArrow"
                                  onClick={() =>
                                    this.handleSortClick("PlantingOrganization")
                                  }
                                >
                                  <i
                                    className={
                                      this.state.SortBy === "TreeHeight"
                                        ? `fas fa-sort-up ${this.state.Up}`
                                        : "fas fa-sort-up"
                                    }
                                  ></i>
                                  <i
                                    className={
                                      this.state.SortBy === "TreeHeight"
                                        ? `fas fa-sort-down ${this.state.Down}`
                                        : "fas fa-sort-down"
                                    }
                                  ></i>
                                </div>
                              </th>
                              {this.state.List.map(function (v) {
                                return v.ec5_uuid;
                              }) !== null ? (
                                <th className="actionth"> Media </th>
                              ) : (
                                ""
                              )}

                              <th className="actionth">ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="tblsearch_cont">
                              <td colSpan="8">
                                <div className="tblsearch">
                                  <i className="fa fa-search"></i>
                                  {!this.state.isUploadTreeData && (
                                    <input
                                      type="text"
                                      autoFocus
                                      id="SearchText"
                                      placeholder="Search for tree type, tree id…"
                                      onKeyPress={this.handleSearchByEnter}
                                      value={this.state.SearchText}
                                      onChange={this.handleChange}
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                            {this.state.List.map((item, index) => (
                              <>
                                <tr
                                  key={index}
                                  className={
                                    item.status === "Dead" ? "unhealthytr" : ""
                                  }
                                >
                                  <td className="tdGridNo">{item.gridNo}</td>
                                  <td className="idtd treeidtd">
                                    <a onClick={() => this.ShowTreeOnMap(item)}>
                                      {item.plotId}
                                    </a>
                                  </td>
                                  <td className="treetypetd">
                                    {item.treeType}
                                  </td>
                                  <td className="idtd">
                                    {item.plantingDate !== null
                                      ? moment(item.plantingDate).format(
                                          "DD MMM YYYY"
                                        )
                                      : ""}
                                  </td>
                                  <td className="idtd">{item.status}</td>
                                  <td className="idtd">{item.treePlantedby}</td>
                                  <td className="idtd">
                                    {Math.round(item.treeHeight * 10) / 10}
                                  </td>
                                  <td className="idtd">
                                    {item.plantingOrganization}
                                  </td>
                                  {/* <td className={"statusone healthy " + (item.status === null || item.status === "Alive" ? 'alive' : 'unhealthy')} ><span>{item.status}</span></td> */}

                                  {item.ec5_uuid !== null &&
                                  item.ec5_uuid !== "" ? (
                                    <td className="action_cont mediatd_cont">
                                      {item.isShowMediaImage !== false ? (
                                        <a
                                          onClick={this.SetImageValues(item)}
                                          title="Image"
                                        >
                                          <i className=" fa fa-image"></i>
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                      {item["27_Video_1"] !== "" &&
                                      item["27_Video_1"] !== null &&
                                      item["27_Video_1"] !== undefined ? (
                                        <a
                                          onClick={this.SetVideoValues(item)}
                                          title="Video"
                                        >
                                          <i className=" fa fa-video-camera"></i>
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}

                                  <td className="action_cont">
                                    {/* <a onClick={() => { this.handleTreeTypeModalToggle(item, "Update") }}><i className="fas fa-edit" title="Edit"></i> */}
                                    {/* <AddTreeType isModalOpen={true} ProjectTreeTypeId={item.projectTreeTypeId} PlotId={item.plotId} TreeType={item.treeType} PlantingDate={item.plantingDate} Status={item.status} /> */}
                                    {/* </a> */}
                                    <a
                                      onClick={this.SetTreeValues(item)}
                                      title="Details"
                                    >
                                      <i className="fas fa-satellite"></i>
                                    </a>
                                  </td>
                                </tr>
                              </>
                            ))}
                            {/* {this.state.IsPopUpOpen && <AddTreeType parentMethod={this.handleparentMethod} AddOrUpdate={this.state.AddOrUpdate} data={this.state.Applicationlist} IsOpen={true} />} */}
                          </tbody>
                        </table>
                        <div className="wrapper">
                          {this.state.isDataLoaded ? (
                            this.state.count > 0 ? (
                              this.state.count > this.state.pagesize && (
                                <div className="paginationDiv">
                                  <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.pagesize}
                                    totalItemsCount={this.state.count}
                                    pageRangeDisplayed={this.state.PageRange}
                                    onChange={this.handlePageChange}
                                    nextPageText={
                                      <i className="fa fa-angle-right"></i>
                                    }
                                    prevPageText={
                                      <i className="fa fa-angle-left"></i>
                                    }
                                    lastPageText={
                                      <i className="fa fa-angle-double-right"></i>
                                    }
                                    firstPageText={
                                      <i className="fa fa-angle-double-left"></i>
                                    }
                                  />
                                </div>
                              )
                            ) : (
                              <div>
                                {this.state.HealthyUnHealthyFilter == "Dead" ? (
                                  <span className="prolist_name">
                                    {this.state.ProjectDetail
                                      .treePlantedTillDate -
                                      this.state.TotalAliveTreeCount}{" "}
                                    trees out of total{" "}
                                    {
                                      this.state.ProjectDetail
                                        .treePlantedTillDate
                                    }{" "}
                                    trees are unhealthy.
                                  </span>
                                ) : (
                                  <span>No Results Found</span>
                                )}
                              </div>
                            )
                          ) : (
                            <div></div>
                          )}
                        </div>
                        {/* Image Popup - Epicollect */}
                        <Modal
                          title="Simple modal header"
                          isOpen={isModalOpen1}
                          onClose={this.handleModalToggle1}
                          className="projdetailpage_modal projectModal projdetimgcarusel_modal"
                        >
                          <div className="treedetails_cont">
                            <Row>
                              <Col lg="12">
                                <div className="treedetimg">
                                  <Carousel>
                                    {this.state.multipleImages
                                      .filter((x) => x.IsVideo == false)
                                      .map((src) => (
                                        <img
                                          key={src.ImageName}
                                          src={
                                            `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreesImages/${this.state.activeMediaitem.projectId}/${this.state.activeMediaitem.ec5_uuid}/` +
                                            src.ImageName
                                          }
                                          alt="ssa"
                                        />
                                      ))}
                                  </Carousel>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Modal>
                        <Modal
                          title="Simple modal header"
                          isOpen={isModalOpen2}
                          onClose={this.handleModalToggle2}
                          className="projdetailpage_modal projectModal projectModalVideo"
                        >
                          <div className="treedetails_cont">
                            <Row>
                              <Col lg="12">
                                <div className="treedetimg">
                                  <Carousel>
                                    {this.state.multipleImages
                                      .filter((x) => x.IsVideo == false)
                                      .map((src) => (
                                        <img
                                          key={src.ImageName}
                                          src={
                                            `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreesImages/${this.state.activeMediaitem.projectId}/${this.state.activeMediaitem.ec5_uuid}/` +
                                            src.ImageName
                                          }
                                          alt="ssa"
                                        />
                                      ))}
                                  </Carousel>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Modal>
                        <Modal
                          title="Simple modal header"
                          isOpen={isModalOpen2}
                          onClose={this.handleModalToggle2}
                          className="projdetailpage_modal projectModal projectModalVideo"
                        >
                          <div className="treedetails_cont">
                            <Row>
                              <Col lg="12">
                                <div className="treedetimg">
                                  <Carousel>
                                    {this.state.multipleImages
                                      .filter((x) => x.IsVideo == true)
                                      .map((src) => (
                                        <ReactPlayer
                                          key={src.ImageName}
                                          url={
                                            `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreesImages/${this.state.activeMediaitem1.projectId}/${this.state.activeMediaitem1.ec5_uuid}/` +
                                            src.ImageName
                                          }
                                          controls={true}
                                          height={450}
                                        />
                                      ))}
                                  </Carousel>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Modal>
                        <Modal
                          title="Simple modal header"
                          isOpen={isModalOpen}
                          onClose={this.handleModalToggle}
                          className="projdetailpage_modal projectdetactnbtn_modal"
                        >
                          <div className="treedetails_cont">
                            <Container>
                              <Row>
                                <Col lg="6">
                                  <div className="treedetimg">
                                    <img
                                      src={this.state.TreeTypeImage}
                                      alt="ddd"
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="treedetails_wrap">
                                    <Accordion>
                                      <AccordionItem header="Tree Details">
                                        <div className="treeid">
                                          Plant ID:{" "}
                                          {this.state.ActiveItem.plotId}
                                        </div>
                                        <div className="treename">
                                          Specie Name:{" "}
                                          {this.state.ActiveItem.treeType}
                                        </div>
                                        <ul className="treedetails_list">
                                          <li>
                                            <div className="treedetlistimg">
                                              <img
                                                src={growthicon}
                                                alt="Height"
                                              />
                                            </div>
                                            <div className="treedetpopright">
                                              <span>Height</span>
                                              <span className="treedetpopvalue">
                                                {Math.round(
                                                  this.state.ActiveItem
                                                    .treeHeight * 10
                                                ) / 10}
                                              </span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="treedetlistimg">
                                              <img
                                                src={dateicon}
                                                alt="Planting Date"
                                              />
                                            </div>
                                            <div className="treedetpopright">
                                              <span>Planting Date</span>
                                              <span className="treedetpopvalue">
                                                <Moment format="DD MMMM, YYYY">
                                                  {
                                                    this.state.ActiveItem
                                                      .plantingDate
                                                  }
                                                </Moment>
                                              </span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="treedetlistimg">
                                              <img
                                                src={dateicon}
                                                alt="Last Updated on"
                                              />
                                            </div>
                                            <div className="treedetpopright">
                                              <span>Last Updated on</span>
                                              <span className="treedetpopvalue">
                                                9 May, 2022 10:00 am IST
                                              </span>
                                            </div>
                                          </li>
                                          {this.state.ActiveItem[
                                            "8_Health_status"
                                          ] !== null &&
                                          this.state.ActiveItem[
                                            "8_Health_status"
                                          ] !== "" &&
                                          this.state.ActiveItem[
                                            "8_Health_status"
                                          ] !== "undefined" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Health Status</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state.ActiveItem[
                                                      "8_Health_status"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {/* {(this.state.ActiveProjectTreeTypesGrid !== null && this.state.ActiveProjectTreeTypesGrid.crop !== null
                                                                            && typeof(this.state.ActiveProjectTreeTypesGrid.crop) !== 'undefined' 
                                                                            && this.state.ActiveProjectTreeTypesGrid.crop !== "") ?
                                                                        <li>
                                                                        
                                                                        <div className="treedetpopright">
                                                                            <span>Crop</span>
                                                                            <span className="treedetpopvalue"> {this.state.ActiveProjectTreeTypesGrid.crop}</span>
                                                                        </div>
                                                                        </li>
                                                                          : ""} */}

                                          {this.state.ActiveItem[
                                            "9_Girth_GBH__cm"
                                          ] !== null &&
                                          this.state.ActiveItem[
                                            "9_Girth_GBH__cm"
                                          ] !== "" &&
                                          this.state.ActiveItem[
                                            "9_Girth_GBH__cm"
                                          ] !== "undefined" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Girth GBH(cm)</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state.ActiveItem[
                                                      "9_Girth_GBH__cm"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                        </ul>
                                      </AccordionItem>
                                      <AccordionItem header="Weather Details">
                                        <ul className="treedetails_list">
                                          <li>
                                            <div className="treedetlistimg">
                                              <img
                                                src={weathericon}
                                                alt="Weather"
                                              />
                                            </div>
                                            <div className="treedetpopright">
                                              <span>Weather</span>
                                              <span className="treedetpopvalue">
                                                28<sup>o</sup> C
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </AccordionItem>
                                      <AccordionItem header="Field Officer Details">
                                        <ul className="treedetails_list soiltreedetails_list">
                                          {this.state.ActiveItem["title"] !==
                                            null &&
                                          this.state.ActiveItem["title"] !==
                                            "" &&
                                          this.state.ActiveItem["title"] !==
                                            "undefined" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Title</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state.ActiveItem[
                                                      "title"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state.ActiveItem[
                                            "2_Name_of_observer"
                                          ] !== null &&
                                          this.state.ActiveItem[
                                            "2_Name_of_observer"
                                          ] !== "" &&
                                          this.state.ActiveItem[
                                            "2_Name_of_observer"
                                          ] !== "undefined" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Name Of Observer</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state.ActiveItem[
                                                      "2_Name_of_observer"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state.ActiveItem[
                                            "28_Notes__observatio"
                                          ] !== null &&
                                          this.state.ActiveItem[
                                            "28_Notes__observatio"
                                          ] !== "" &&
                                          this.state.ActiveItem[
                                            "_28_Notes__observatio"
                                          ] !== "undefined" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Observation Notes</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state.ActiveItem[
                                                      "28_Notes__observatio"
                                                    ]
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .siteDescription !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .siteDescription !== "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .siteDescription !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Site Description</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state
                                                      .ActiveProjectTreeTypesGrid
                                                      .siteDescription
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                        </ul>
                                      </AccordionItem>
                                      <AccordionItem header="Water Sources">
                                        <ul className="treedetails_list soiltreedetails_list">
                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .waterpH !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .waterpH !== "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .waterpH !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>pH details</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state
                                                      .ActiveProjectTreeTypesGrid
                                                      .waterpH
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .waterElectricConductivity !==
                                            null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .waterElectricConductivity !==
                                            "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .waterElectricConductivity !==
                                            "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>
                                                  Electric Conductivity
                                                </span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state
                                                      .ActiveProjectTreeTypesGrid
                                                      .waterElectricConductivity
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .irrigationPattern !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .irrigationPattern !==
                                            "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .irrigationPattern !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Irrigation Pattern</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state
                                                      .ActiveProjectTreeTypesGrid
                                                      .irrigationPattern
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                        </ul>
                                      </AccordionItem>
                                      <AccordionItem header="Soil Details">
                                        <ul className="treedetails_list soiltreedetails_list">
                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilNitrate !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilNitrate !== "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilNitrate !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Nitrate (ppm)</span>
                                                <span className="treedetpopvalue">
                                                  {(
                                                    Math.round(
                                                      this.state
                                                        .ActiveProjectTreeTypesGrid
                                                        .soilNitrate * 100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilPhosphate !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilPhosphate !== "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilPhosphate !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Phosphate(ppm)</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {(
                                                    Math.round(
                                                      this.state
                                                        .ActiveProjectTreeTypesGrid
                                                        .soilPhosphate * 100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilPotassium !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilPotassium !== "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilPotassium !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Potassium(ppm)</span>
                                                <span className="treedetpopvalue">
                                                  {(
                                                    Math.round(
                                                      this.state
                                                        .ActiveProjectTreeTypesGrid
                                                        .soilPotassium * 100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilpH !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilpH !== "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilpH !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Soil pH</span>
                                                <span className="treedetpopvalue">
                                                  {(
                                                    Math.round(
                                                      this.state
                                                        .ActiveProjectTreeTypesGrid
                                                        .soilpH * 100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilElectricConductivity !==
                                            null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilElectricConductivity !==
                                            "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilElectricConductivity !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>
                                                  Electric Conductivity (dS/m)
                                                </span>
                                                <span className="treedetpopvalue">
                                                  {(
                                                    Math.round(
                                                      this.state
                                                        .ActiveProjectTreeTypesGrid
                                                        .soilElectricConductivity *
                                                        100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilOrganicCarbon !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilOrganicCarbon !==
                                            "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilOrganicCarbon !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>Organic Carbon (%)</span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {
                                                    this.state
                                                      .ActiveProjectTreeTypesGrid
                                                      .soilOrganicCarbon
                                                  }
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelPit1 !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelPit1 !==
                                            "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelPit1 !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>
                                                  Moisture Level Pit 1
                                                </span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {(
                                                    Math.round(
                                                      this.state
                                                        .ActiveProjectTreeTypesGrid
                                                        .soilMoistureLevelPit1 *
                                                        100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelPit2 !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelPit2 !==
                                            "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelPit2 !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>
                                                  Moisture Level Pit 2
                                                </span>
                                                <span className="treedetpopvalue">
                                                  {(
                                                    Math.round(
                                                      this.state
                                                        .ActiveProjectTreeTypesGrid
                                                        .soilMoistureLevelPit2 *
                                                        100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelPit3 !== null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelPit3 !==
                                            "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelPit3 !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>
                                                  Moisture Level Pit 3
                                                </span>
                                                <span className="treedetpopvalue">
                                                  {(
                                                    Math.round(
                                                      this.state
                                                        .ActiveProjectTreeTypesGrid
                                                        .soilMoistureLevelPit3 *
                                                        100
                                                    ) / 100
                                                  ).toFixed(2)}{" "}
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          {this.state
                                            .ActiveProjectTreeTypesGrid !==
                                            null &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelAverage !==
                                            null &&
                                          typeof this.state
                                            .ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelAverage !==
                                            "undefined" &&
                                          this.state.ActiveProjectTreeTypesGrid
                                            .soilMoistureLevelAverage !== "" ? (
                                            <li>
                                              <div className="treedetpopright">
                                                <span>
                                                  Moisture Level Average
                                                </span>
                                                <span className="treedetpopvalue">
                                                  {" "}
                                                  {(
                                                    Math.round(
                                                      this.state
                                                        .ActiveProjectTreeTypesGrid
                                                        .soilMoistureLevelAverage *
                                                        100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                        </ul>
                                      </AccordionItem>
                                    </Accordion>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </Modal>
                      </CustomScrollbars>
                    </div>
                  )}
                  {this.state.IsPopUpOpen && (
                    <AddTreeType
                      parentMethod={this.handleparentMethod}
                      AddOrUpdate={this.state.AddOrUpdate}
                      data={this.state.Applicationlist}
                      IsOpen={true}
                      polycords={this.state.ProjectDetail.locationCords}
                      cameraPosition={this.state.ProjectDetail.cameraPosition}
                      plotPlanted={this.state.List}
                    />
                  )}
                </div>
              </div>
              <div>
                <Modal
                  isOpen={this.state.isUploadTreeData}
                  className="uploadtreedata_modal"
                  onClose={this.CloseUploadTreeData}
                >
                  <Container>
                    <Row className="treedatarow">
                      <Col lg="4">
                        <label>Tree Planted Date</label>
                      </Col>
                      <Col lg="8">
                        <input
                          type="date"
                          className="treeplntdte"
                          value={this.state.TreePlantedDate}
                          onChange={(event) => this.SelectPlantedDate(event)}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </Col>
                    </Row>
                    <Row className="treedatarow">
                      <Col lg="4">
                        <label>Tree Planted By</label>
                      </Col>
                      <Col lg="8">
                        <input
                          type="text"
                          id="TreePlntBy"
                          className="treeplntby"
                          value={this.state.TreePlantedBy}
                          onChange={(event) => this.ChangeTreePlantedBy(event)}
                        />
                      </Col>
                    </Row>
                    <Row className="treedatarow">
                      <Col lg="4">
                        <label>Tree Planted Till Date</label>
                      </Col>
                      <Col lg="8">
                        <input
                          type="text"
                          id="TreePlnttilldate"
                          className="treeplntby"
                          value={this.state.TreePlantedTillDate}
                          onChange={(event) =>
                            this.ChangeTreePlantedTillDate(event)
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="treedatarow">
                      <Col lg="4">
                        <label>Vimana Base Url</label>
                      </Col>
                      <Col lg="8">
                        <input
                          type="text"
                          id="VimanaBaseUrl"
                          className="treeplntby"
                          value={this.state.VimanaBaseUrl}
                          onChange={(event) => this.ChangeVimanaBaseUrl(event)}
                        />
                      </Col>
                    </Row>
                    <Row className="treedatarow">
                      <Col lg="4">
                        <label for="forinventory">Upload Tree Data</label>
                      </Col>
                      <Col lg="8" className="upload_projTreeData">
                        <div className="input-group divUploadFile">
                          <div className="qq-upload-drop-area">
                            <span>Drop files here to upload</span>
                          </div>
                          <div className="qq-upload-button">
                            Drag and Drop Files
                            <span className="redMandat">
                              Upload file type .json File size limit 5 MB
                            </span>
                          </div>
                          <input
                            id="uploadjsonfile"
                            type="file"
                            name="files"
                            onChange={(e) => this.setFile(e)}
                          />
                        </div>
                        <div>
                          <ul id="fileList" className="ul-project-files">
                            {this.state.uploadfile !== null &&
                              this.state.uploadfile.map((item, index) => (
                                <li class="file-list-li">
                                  {item.name}
                                  <a
                                    class="remove-files-list"
                                    id={index}
                                    onClick={(e) => this.removeFileFromList(e)}
                                  >
                                    Remove
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                    <Row className="treedatarow">
                      <Col lg="12">
                        <div className="savetree_btn">
                          <a
                            className="savebtn"
                            onClick={(e) => this.UploadTreeData(e)}
                          >
                            Save
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal>
              </div>
            </>
          )
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(ProjectDetails);
