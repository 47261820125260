import { useEffect, useState } from "react";
import config from "../../../../utils/config";
import { ReactComponent as SelectDropdown } from "../icons/Select_Dropdown.svg";
import OverviewReportUrl from "../Reports/OverviewDownload";
import Pagination from "react-js-pagination";

export default function OwnershipView(props) {
  const [allData, setAllData] = useState([]);
  const [ownerproofdDropdownList, setOwnerProofdDropdownList] = useState([]);
  const [activeTab, setActiveTab] = useState("Government");

  useEffect(() => {
    if (props.projectId) {
      GetOwnership(props.projectId);
      GetCommonMasters();
    }
  }, [props.projectId]);

  const GetOwnership = (ProjectId) => {
    let proj = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetOwnership`;
    fetch(url, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(proj),
      timeout: 5000, // Timeout in milliseconds
    })
      .then((response) => response?.json())
      .then((data) => {
        let responseData = data?.data;
        if (responseData !== null) {
          setAllData(responseData);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMaster/Ownership`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000, // Timeout in milliseconds
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.length > 0) {
          var OwnerOwnerproof = data?.filter(
            (x) => x?.type === "Proof of Ownership"
          );
          setOwnerProofdDropdownList(OwnerOwnerproof);
        }
      })

      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const handleDownloadDocument = (FileName) => {
    let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/ProjectFiles/Project/${props.projectId}/${FileName}`;
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = FileName;
        a.click();
      });
    });
  };

  const renderTabContent = (currentTab, index) => {
    let dataForCurrentTab = allData?.ownershipList?.filter(
      (ownership) => ownership?.ownershipType === currentTab
    );

    return (
      <div className="tabs-content" key={"ownership" + index}>
        <div className="partshpreport_heading">{currentTab}</div>
        <div className="executivepatners_trust">
          {dataForCurrentTab && dataForCurrentTab?.length > 0 ? (
            dataForCurrentTab.map((ownership, index) => (
              <div key={index} className="ownershiptab_content">
                <div className="owershiptabtop_cont">
                  {ownership?.ownerName && (
                    <div className="ownertb_ownrnme">
                      {ownership?.ownerName}
                    </div>
                  )}
                  <div className="ownertbtypmobile_wrap">
                    {ownership?.ownershipType && (
                      <div className="ownertb_ownertype">
                        {ownership?.ownershipType} |
                      </div>
                    )}
                    {ownership?.contactNo && (
                      <div className="ownertb_ownermobile">
                        <span>&nbsp;Contact: </span>
                        <span>{ownership?.contactNo}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="prosumacordinertbl_wrap">
                  <table className="prosumacord_inertbl">
                    <thead>
                      <tr>
                        <th>Proof of Ownership</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ownerproofdDropdownList &&
                        ownerproofdDropdownList.map((eachOwnerProof, i) => (
                          <tr key={i}>
                            <td>
                              {eachOwnerProof?.description ? (
                                <div>{eachOwnerProof?.description}</div>
                              ) : (
                                <div>NA</div>
                              )}
                            </td>
                            <td>
                              {ownership.ownershipFiles &&
                                ownership?.ownershipFiles?.some(
                                  (file) =>
                                    file?.documentId ===
                                    eachOwnerProof?.mainGuId
                                ) && (
                                  <button
                                    onClick={() => {
                                      const matchingFile =
                                        ownership?.ownershipFiles?.find(
                                          (file) =>
                                            file?.documentId ===
                                            eachOwnerProof?.mainGuId
                                        );
                                      if (matchingFile) {
                                        handleDownloadDocument(
                                          matchingFile?.documentName
                                        );
                                      }
                                    }}
                                  >
                                    <SelectDropdown />
                                  </button>
                                )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          ) : (
            <div className="norecodfoundprosum_cont">No Record Found</div>
          )}
        </div>
      </div>
    );
  };

  const Tabs = [
    "Government",
    "Private - Individuals",
    "Corporations/Businesses",
    "Trust",
    "Community Land",
    "NGO",
  ];

  return (
    <div className="row prosumcordtabscont_wrap partshpreportcont_wrap">
      <div className="col-12">
        {Tabs?.map((tab, index) => {
          return renderTabContent(tab, index);
        })}
      </div>
    </div>
  );
}
