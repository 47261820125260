import React, { useState, useCallback, useRef, useEffect } from "react";
import { Modal, Button } from "@patternfly/react-core";
import config from "../../../utils/config";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import ToastNotification from "../../Common/ToastNotification/ToastNotification";

export default function AddLayerMaster(props) {
  const [isModalOpen, setIsModalOpen] = useState(props.IsOpen);
  const [count, setCount] = useState(0);
  const [fileControl, setFileControl] = useState(null);
  const [fileNameControl, setFileNameControl] = useState("");
  const [uploadfile, setUploadfile] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const fileInputRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [layerMstId, setLayerMstId] = useState("");
  const [editMode, setEditMode] = useState(props.editMode);
  const [layerFileType, setLayerFileType] = useState([]);
  const [layerMaster, setLayerMaster] = useState({
    filePath: "",
    showCount: false,
    isFilterApply: false,
    isSpeciesRequired: false,
  });
  const clearError = () => {
    setError("fileName", null);
  };
  const {
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
  } = useForm();

  useEffect(() => {
    fileInputRef.current = document.getElementById("fileInput");
  }, []);

  useEffect(() => {
    console.log("props?.editLayer", props?.editLayer);
    if (props?.editLayer) {
      const filePath = props?.editLayer?.sampleFile;
      if (filePath) {
        let fileName = getFileName(filePath);
        setFileControl(filePath);
        setFileNameControl(fileName);
        if (fileInputRef.current) {
          fileInputRef.current.focus();
        }
      } else {
        setFileControl(null);
        setFileNameControl("");
      }

      Object.keys(props?.editLayer).forEach((key) => {
        setValue(key, props?.editLayer[key]);
      });
      setLayerMaster(props?.editLayer);
      setLayerMstId(props?.editLayer?.layerMstId);
    }
  }, [props?.editLayer]);

  const getFileName = (filePath) => {
    return filePath.substring(filePath.lastIndexOf("/") + 1);
  };

  const handleModalToggle = useCallback(() => {
    props.setIsPopUpStatus(false);
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, [setIsModalOpen]);

  const onSubmit = async (e) => {
    const randomGuid = uuidv4();
    if (editMode) {
      layerMaster.layerMstId = layerMstId;
    } else {
      layerMaster.layerMstId = randomGuid;
    }
    if (fileSelected) {
      const fileResponse = await UploadLayerSampleFile(e);
      if (fileResponse?.success) {
        layerMaster.filePath = fileResponse?.UploadFileName;
      }
    } else {
      // setErrorMsg("Please Upload Layer File");
      if (fileNameControl === "") {
        setErrorMsg("Please Upload Layer File");
      } else {
        layerMaster.filePath = fileControl;
      }
    }
    if (fileNameControl !== "" && layerMaster.layerMstId !== "") {
      const payload = {
        layerMstId: layerMaster?.layerMstId,
        layerName: layerMaster?.layerName,
        layerType: layerMaster?.layerType,
        fileType: layerMaster?.fileType,
        layerInfo: layerMaster?.layerInfo,
        sampleFile: layerMaster?.filePath,
        showCount: layerMaster?.showCount,
        isFilterApply: layerMaster?.isFilterApply,
        isSpeciesRequired: layerMaster?.isSpeciesRequired,
      };

      fetch(`${config?.API_HOST_URL}/api/Ecosystem/AddLayerMaster`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          if (props?.editMode) {
            ToastNotification.ShowSuccess("Layer Master Updated Successfully");
          } else {
            ToastNotification.ShowSuccess("Layer Master Added Successfully");
          }
          typeof props?.GetLayerMaster == "function" && props?.GetLayerMaster();
          handleModalToggle(); // Close the modal after successful submission
          clearAll();
        })
        .catch((error) => {
          console.error("Error in request", error);
        });
    }
  };

  const UploadLayerSampleFile = async (e) => {
    let file = uploadfile;
    const formData = new FormData();
    for (var index = 0; index < file.length; index++) {
      var element = file[index];
      formData.append("FormFiles", element);
    }
    let uri = `${config.API_HOST_URL}/api/Ecosystem/UploadLayerSampleFile`;
    let fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
        ContentType: "multipart/form-data",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      } else {
        return response.json();
      }
      // const data = response.json();
      //   if (data.success === true) {
      //     return data;
      //   }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };
  const setFile = async (e) => {
    let filesArr = e.target.files;
    const array = [];
    // Clear the array if there's already a file
    if (filesArr.length > 0) {
      array.push(filesArr[0]);
    }
    setUploadfile(array);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files;
    if (selectedFile) {
      //Check file extension validation
      const fileName = selectedFile[0].name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const maxSize = 50 * 1024 * 1024; // 50MB in bytes
      if (layerMaster.fileType === fileExtension) {
        //Check file size validation
        if (selectedFile[0].size > maxSize) {
          setErrorMsg("File size exceeds 50MB limit.");
          setFileControl(null);
          setFileNameControl("");
          setFileSelected(false);
          setUploadfile([]);
        } else {
          setErrorMsg("");
          setFileControl(selectedFile);
          setFileNameControl(selectedFile.name);
          setFileSelected(true);
        }
      } else {
        setErrorMsg(
          "Invalid file type. Allowed Selected " +
            layerMaster.fileType +
            " file type only"
        );
        setFileControl(null);
        setFileNameControl("");
        setFileSelected(false);
        setUploadfile([]);
      }
    } else {
      setFileControl(null);
      setFileNameControl("");
      setFileSelected(false);
      setUploadfile([]);
    }
  };

  const removeFileFromList = (e, id) => {
    var array = [...uploadfile]; // make a separate copy of the array
    array.splice(e.target.id, 1);
    setUploadfile(array);
    resetFileInput();
  };

  const resetFileInput = () => {
    setFileControl(null);
    setFileNameControl("");
    setFileSelected(false);
    setUploadfile([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChangeRbtn = (e) => {
    const name = e.target.name;
    const value = e.target.value === "true";
    let newData = { ...layerMaster };
    newData[name] = value;
    setLayerMaster(newData);
  };

  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   let newData = { ...layerMaster };
  //   newData[name] = value;
  //   setLayerMaster(newData);
  // };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLayerMaster((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLayerTypeChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLayerMaster((prevData) => ({ ...prevData, [name]: value }));
    layerMaster.fileType = "";
    if (value === "polygon") {
      setLayerFileType(["xlsx", "geojson"]);
      //layerMaster.fileType = "xlsx";
    }
    if (value === "ground-overlay") {
      layerMaster.fileType = "png";
    }
    if (value === "LayerMarker") {
      layerMaster.fileType = "xlsx";
      layerMaster.isSpeciesRequired = true;
    }
    if (value === "multipolygon") {
      layerMaster.fileType = "geojson";
    }
    if (value === "gridLayer") {
      layerMaster.fileType = "xlsx";
    }
    setLayerMaster((prevData) => ({
      ...prevData,
      ["fileType"]: layerMaster.fileType,
      ["isSpeciesRequired"]: layerMaster.isSpeciesRequired,
    }));
  };

  const handleCancel = () => {
    props.setIsPopUpStatus(false);
    clearAll();
    handleModalToggle(false);
  };
  const clearAll = () => {
    setLayerMaster({});
    props?.setEditLayer({});
    props?.setEditMode(false);
    setFileControl(null);
    setFileNameControl("");
    setFileSelected(false);
    setUploadfile([]);
  };

  const validateFileType = (fileList) => {
    if (!uploadfile || uploadfile.length === 0) {
      return "Please Upload Layer File";
    } else {
      return undefined;
    }
  };

  return (
    <>
      <Modal
        title={props?.editMode ? "Update Layer Master" : "Add Layer Master"}
        isOpen={isModalOpen}
        onClose={() => handleModalToggle(false)}
        className="addproject_modal fieldofficer_modal layermaster_modal"
        actions={[
          <Button
            key="submit"
            className="modal_btn submit_btn"
            onClick={handleSubmit(onSubmit)}
          >
            {props?.editMode ? "Update" : "Submit"}
          </Button>,
          <Button
            key="cancel"
            className="modal_btn cancel_btn"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="addproject_form">
              <li className="form-group field">
                <Controller
                  control={control}
                  name={"layerName"}
                  rules={{
                    required: "Please Enter Layer Name",
                    // pattern: {
                    //   value: /^[a-zA-Z\s]+$/,
                    //   message: "Only alphabets are allowed!",
                    // },
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="layerName"
                        id="layerName"
                        value={
                          layerMaster?.layerName ? layerMaster?.layerName : ""
                        }
                        placeholder="Enter Layer Name"
                        onKeyDown={(event) => {
                          if (
                            event.key === "Backspace" ||
                            event.key === "Delete"
                          ) {
                            return;
                          }
                          if (event.key === " ") {
                            if (event.target.value.endsWith(" ")) {
                              event.preventDefault();
                            }
                          } else if (!/^[a-zA-Z]$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          handleInputChange(event);
                          onChange(event);
                        }}
                      />
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="layerName">
                        <sup class="mandatory">*</sup>Layer Name
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name={"layerType"}
                  rules={{
                    required: "Please Select Layer Type",
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <select
                        id="layerType"
                        name="layerType"
                        className="dropdown-130"
                        value={
                          layerMaster?.layerType ? layerMaster?.layerType : ""
                        }
                        onChange={(event) => {
                          handleLayerTypeChange(event);
                          onChange(event);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="polygon">Polygon</option>
                        <option value="ground-overlay">Ground-Overlay</option>
                        <option value="LayerMarker">Layer Marker</option>
                        <option value="multipolygon">Multi-Polygon</option>
                        <option value="gridLayer">Grid Layer</option>
                      </select>

                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="layerType">
                        <sup class="mandatory">*</sup>Layer Type
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name={"layerInfo"}
                  rules={{
                    required: "Please Enter Layer Information",
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="layerInfo"
                        id="layerInfo"
                        value={
                          layerMaster?.layerInfo ? layerMaster?.layerInfo : ""
                        }
                        placeholder="Enter Layer Information"
                        onKeyDown={(event) => {
                          if (
                            event.key === "Backspace" ||
                            event.key === "Delete"
                          ) {
                            return;
                          }
                          if (event.key === " ") {
                            if (event.target.value.endsWith(" ")) {
                              event.preventDefault();
                            }
                          }
                        }}
                        onChange={(event) => {
                          handleInputChange(event);
                          onChange(event);
                        }}
                      />
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="layerInfo">
                        <sup class="mandatory">*</sup>Layer Information
                      </label>
                    </>
                  )}
                />
              </li>
              {layerFileType && layerFileType.length === 0 && (
                <li className="form-group field">
                  <Controller
                    control={control}
                    name={"fileType"}
                    // rules={{
                    //   required: "File Type is required!",
                    // }}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <input
                          type="text"
                          name="fileType"
                          id="fileType"
                          readOnly
                          value={
                            layerMaster?.fileType ? layerMaster?.fileType : ""
                          }
                          placeholder="File Type"
                        />

                        {/* <select
                        id="fileType"
                        name="fileType"
                        className="dropdown-130"
                        style={{
                          cursor: "none",
                        }}
                        disabled
                        value={
                          layerMaster?.fileType ? layerMaster?.fileType : ""
                        }
                        onChange={(event) => {
                          handleInputChange(event);
                          onChange(event);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="png">Png</option>
                        <option value="xlsx">Xlsx</option>
                        <option value="geojson">Geojson</option>
                        <option value="kml">Kml</option>
                      </select> */}

                        {error && (
                          <div class="mandatory has-error">
                            {" "}
                            {error.message}
                          </div>
                        )}
                        <label for="fileType">File Type</label>
                      </>
                    )}
                  />
                </li>
              )}

              {layerFileType && layerFileType.length > 0 && (
                <li className="form-group field">
                  <Controller
                    control={control}
                    name={"fileType"}
                    rules={{
                      required: "Please Select File Type!",
                    }}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <select
                          id="fileType"
                          name="fileType"
                          className="dropdown-130"
                          value={
                            layerMaster?.fileType ? layerMaster?.fileType : ""
                          }
                          onChange={(event) => {
                            handleInputChange(event);
                            onChange(event);
                          }}
                        >
                          <option value="">Select</option>
                          {layerFileType &&
                            layerFileType.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                        </select>

                        {error && (
                          <div class="mandatory has-error">
                            {" "}
                            {error.message}
                          </div>
                        )}
                        <label for="fileType">File Type</label>
                      </>
                    )}
                  />
                </li>
              )}

              <li className="form-group inventory_cont">
                <Controller
                  control={control}
                  name={"fileName"}
                  rules={{
                    validate: validateFileType,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <label for="forinventory">
                        <sup className="mandatory">*</sup>
                        Sample File
                      </label>
                      <div className="input-group divUploadFile">
                        <div className="qq-upload-drop-area">
                          <span>Drop files here to upload</span>
                        </div>
                        <div className="qq-upload-button">
                          Drag and Drop Files
                          <span className="redMandat">
                            Upload file type {""}
                            {layerMaster?.fileType ? layerMaster?.fileType : ""}
                          </span>
                        </div>
                        <input
                          type="file"
                          id="fileInput"
                          name="fileName"
                          accept=".jpg, .jpeg, .png, .xlsx, .xls, .geojson"
                          defaultValue=""
                          onChange={(event) => {
                            const fileList = event.target.files;
                            validateFileType(fileList);
                            const file = event.target.files[0];
                            setFile(event);
                            handleFileChange(event, file);
                            clearError();
                          }}
                          ref={fileInputRef}
                        />
                      </div>
                      <div>
                        <ul id="fileList" className="ul-project-files">
                          {uploadfile &&
                            uploadfile?.map((item, index) => (
                              <li class="file-list-li" key={index}>
                                <i class="fa fa-check"></i>
                                {item.name}
                                <a
                                  class="remove-files-list"
                                  id={index}
                                  onClick={(e) =>
                                    removeFileFromList(e, "sampleFile")
                                  }
                                >
                                  Remove
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                    </>
                  )}
                />
                {errorMsg && <div class="mandatory has-error"> {errorMsg}</div>}
                <span>{fileControl ? fileNameControl : ""}</span>
              </li>
              {layerMaster?.layerType === "LayerMarker" && (
                <li className="form-group inventory_cont">
                  <label for="fieldfirstname">Is Species Required ?</label>
                  <div className="input-group radiobtns_wrap">
                    <div className="radio__input">
                      <span className="custom-radio">
                        <input
                          className="radio__input"
                          type="radio"
                          name="isSpeciesRequired"
                          disabled
                          value={true}
                          checked={layerMaster?.isSpeciesRequired}
                          onChange={handleChangeRbtn}
                        />
                      </span>
                      <label className="radio__label">Yes</label>
                    </div>
                    <div className="radio__input">
                      <span className="custom-radio">
                        <input
                          className="radio__input"
                          type="radio"
                          name="isSpeciesRequired"
                          disabled
                          value={false}
                          checked={!layerMaster?.isSpeciesRequired}
                          onChange={handleChangeRbtn}
                        />
                      </span>
                      <label className="radio__label">No</label>
                    </div>
                  </div>
                </li>
              )}

              {layerMaster?.fileType !== "png" &&
                layerMaster?.layerType !== "polygon" &&
                layerMaster?.layerType !== "gridLayer" && (
                  <li className="form-group">
                    <label for="fieldfirstname">Is Show Count ?</label>
                    <div className="input-group radiobtns_wrap">
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            className="radio__input"
                            type="radio"
                            name="showCount"
                            value={true}
                            checked={layerMaster?.showCount}
                            onChange={handleChangeRbtn}
                          />
                        </span>
                        <label className="radio__label">Yes</label>
                      </div>
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            className="radio__input"
                            type="radio"
                            name="showCount"
                            value={false}
                            checked={!layerMaster?.showCount}
                            onChange={handleChangeRbtn}
                          />
                        </span>
                        <label className="radio__label">No</label>
                      </div>
                    </div>
                  </li>
                )}
              {layerMaster?.fileType !== "png" &&
                layerMaster?.layerType == "LayerMarker" && (
                  <li className="form-group">
                    <label for="fieldfirstname">Is Filter Apply ?</label>
                    <div className="input-group radiobtns_wrap">
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            className="radio-button-yes2"
                            type="radio"
                            name="isFilterApply"
                            value={true}
                            checked={layerMaster?.isFilterApply}
                            onChange={handleChangeRbtn}
                          />
                        </span>
                        <label className="radio__label">Yes</label>
                      </div>
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            className="radio-button-yes2"
                            type="radio"
                            name="isFilterApply"
                            value={false}
                            checked={!layerMaster?.isFilterApply}
                            onChange={handleChangeRbtn}
                          />
                        </span>
                        <label className="radio__label">No</label>
                      </div>
                    </div>
                  </li>
                )}
            </ul>
          </form>
        </div>
      </Modal>
    </>
  );
}
