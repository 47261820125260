import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import config from "../../utils/config";
import ToastNotification from "../../Components/Common/ToastNotification/ToastNotification";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import Header from "../EcosystemCommon/Header";
import LeftNavbar from "../EcosystemCommon/LeftNavbar";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";

class TreeTiesAddProjectForms1Spo extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props;
    this.state = {
      added_IS_EP: [],
      added_IS_TT: [],
      added_IND_EP: [],
      added_IND_TT: [],
      ngoUserList: [],
      instSponsorUserList: [],
      indSponsorUserList: [],
      sponsorshipList: [],
      partnerInsList: [],
      partnerIndivList: [],
      associationTypeList: [],
      projectId: id?.substring(1),
      iT_EP_Speci_Spon: "",
      iT_EP_Spon_Amount: null,
      iT_TT_Speci_Spon: "",
      iT_TT_Spon_Amount: null,
      iV_EP_Speci_Spon: "",
      iV_EP_Spon_Amount: null,
      iV_TT_Speci_Spon: "",
      iV_TT_Spon_Amount: null,
      iT_EP_Spon_Amount_Multiple: null,
      sponsorship_Id: "",
    };
  }

  componentDidMount() {
    this.GetCommonMasters();
    this.GetNGOUserList();
    this.GetSponsorship(this.state.projectId);
  }
  handleSelectInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };
  //On change dropdown values to set target values to state
  handleUserInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    this.setState({ [name]: value });
    // const name = e.target.name;
    // const value = e.target.value;
    // if (value === "") {
    //   var input = document.getElementById("ii");
    //   input.value = parseInt(input.value.toString().replace("-", ""));
    //   this.setState({ [name]: input.value });
    // } else if (value === "e") {
    //   var input = document.getElementById("ii");
    //   input.value = parseInt(input.value.toString().replace("e", ""));
    //   this.setState({ [name]: input.value });
    // } else {
    //   this.setState({ [name]: value }, () => {});
    // }
  };
  handleKeyPress = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
    // var input = document.getElementById("ii");
    // if (input.value === "e") {
    //   input.value = parseInt(input.value.toString().replace("e", ""));
    //   return input.value;
    // }
    // input.value = parseInt(input.value.toString().replace("-", ""));
    // return input.value;
  };

  //On change Grid amount value set
  handleGridTextChangeEvent_IS_EP = (e, parentIdValue) => {
    let filtered = this.state.added_IS_EP.filter(
      (x) => x.institutional_SponsorId === parentIdValue
    )[0];
    filtered[e.target.name] = e.target.value;
    this.setState({ added_IS_EP: this.state.added_IS_EP });
  };

  handleGridTextChangeEvent_IS_TT = (e, parentIdValue) => {
    let filtered = this.state.added_IS_TT.filter(
      (x) => x.institutional_SponsorId === parentIdValue
    )[0];
    filtered[e.target.name] = e.target.value;
    this.setState({ added_IS_TT: this.state.added_IS_TT });
  };

  handleGridTextChangeEvent_IND_EP = (e, idValue) => {
    let filtered = this.state.added_IND_EP.filter(
      (x) => x.individual_SponsorId === idValue
    )[0];
    filtered[e.target.name] = e.target.value;
    this.setState({ added_IND_EP: this.state.added_IND_EP });
  };
  handleGridTextChangeEvent_IND_TT = (e, idValue) => {
    let filtered = this.state.added_IND_TT.filter(
      (x) => x.individual_SponsorId === idValue
    )[0];
    filtered[e.target.name] = e.target.value;
    this.setState({ added_IND_TT: this.state.added_IND_TT });
  };

  GetNGOUserList = () => {
    let url = `${config.API_HOST_URL}/api/Login/GetNGOUserList`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = JSON.parse(data.NGOUserList);
        this.setState({
          instSponsorUserList: responseData.filter(
            (x) => x.TypeOfIncorporation === "InstitutionalSponsor"
          ),
          indSponsorUserList: responseData.filter(
            (x) => x.TypeOfIncorporation === "IndividualSponsor"
          ),
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetSponsorship = (ProjectId) => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetSponsorship`;
    let proj = {
      ProjectId: ProjectId,
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(proj),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        if (responseData !== null) {
          this.setState({ sponsorshipList: responseData });
          this.setState({
            sponsorship_Id: responseData.sponsorship_Id,
            iT_EP_Speci_Spon: responseData.iT_EP_Speci_Spon,
            iT_EP_Spon_Amount: responseData.iT_EP_Spon_Amount,
            iT_TT_Speci_Spon: responseData.iT_TT_Speci_Spon,
            iT_TT_Spon_Amount: responseData.iT_TT_Spon_Amount,
            iV_EP_Speci_Spon: responseData.iV_EP_Speci_Spon,
            iV_EP_Spon_Amount: responseData.iV_EP_Spon_Amount,
            iV_TT_Speci_Spon: responseData.iV_TT_Speci_Spon,
            iV_TT_Spon_Amount: responseData.iV_TT_Spon_Amount,
            added_IS_EP: responseData.iS_Assoc_EP,
            added_IS_TT: responseData.iS_Assoc_TT,
            added_IND_EP: responseData.inD_Assoc_EP,
            added_IND_TT: responseData.inD_Assoc_TT,
          });
        }

        this.GetPartnershipList();
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetCommonMasters = () => {
    let masterType = "AssociationType";
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMaster/${masterType}`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ associationTypeList: data });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetPartnershipList = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetSponserWithPartner/${this.state.projectId}`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //Institutional
        if (data.InstSpon != null && data.InstSpon.length > 0) {
          this.setState({ partnerInsList: JSON.parse(data.InstSpon) });
          if (
            this.state.sponsorshipList.iS_Assoc_EP !== null &&
            this.state.sponsorshipList.iS_Assoc_EP !== undefined
          ) {
            this.setState({
              added_IS_EP: this.state.sponsorshipList.iS_Assoc_EP,
            });
          } else {
            this.setState({
              added_IS_EP: this.state.partnerInsList.filter(
                (x) =>
                  x.associationTypeId ===
                  this.state.associationTypeList.filter(
                    (x) =>
                      x.description ===
                      "Associated with Execution Partner (NGO)"
                  )[0]?.mainGuId
              ),
            });
          }
          if (
            this.state.sponsorshipList.iS_Assoc_TT !== null &&
            this.state.sponsorshipList.iS_Assoc_TT !== undefined
          ) {
            this.setState({
              added_IS_TT: this.state.sponsorshipList.iS_Assoc_TT,
            });
          } else {
            this.setState({
              added_IS_TT: this.state.partnerInsList.filter(
                (x) =>
                  x.associationTypeId ===
                  this.state.associationTypeList.filter(
                    (x) => x.description === "Brought by TreeTies"
                  )[0]?.mainGuId
              ),
            });
          }
        }
        //Individual
        if (data.IndSpon != null && data.IndSpon.length > 0) {
          this.setState({ partnerIndivList: JSON.parse(data.IndSpon) });
          if (
            this.state.sponsorshipList.inD_Assoc_EP !== null &&
            this.state.sponsorshipList.inD_Assoc_EP !== undefined
          ) {
            this.setState({
              added_IND_EP: this.state.sponsorshipList.inD_Assoc_EP,
            });
          } else {
            this.setState({
              added_IND_EP: this.state.partnerIndivList.filter(
                (x) =>
                  x.associationType_Ind_Id ===
                  this.state.associationTypeList.filter(
                    (x) =>
                      x.description ===
                      "Associated with Execution Partner (NGO)"
                  )[0]?.mainGuId
              ),
            });
          }
          if (
            this.state.sponsorshipList.inD_Assoc_TT !== null &&
            this.state.sponsorshipList.inD_Assoc_TT !== undefined
          ) {
            this.setState({
              added_IND_TT: this.state.sponsorshipList.inD_Assoc_TT,
            });
          } else {
            this.setState({
              added_IND_TT: this.state.partnerIndivList.filter(
                (x) =>
                  x.associationType_Ind_Id ===
                  this.state.associationTypeList.filter(
                    (x) => x.description === "Brought by TreeTies"
                  )[0]?.mainGuId
              ),
            });
          }
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  AddSponsorship = (e) => {
    // if (this.validateForm(e)) {
    const data = {
      projectId: this.state.projectId,
      sponsorship_Id: this.state.sponsorship_Id,
      iT_EP_Speci_Spon: this.state.iT_EP_Speci_Spon,
      iT_EP_Spon_Amount: this.state.iT_EP_Spon_Amount,
      iT_TT_Speci_Spon: this.state.iT_TT_Speci_Spon,
      iT_TT_Spon_Amount: this.state.iT_TT_Spon_Amount,
      iV_EP_Speci_Spon: this.state.iV_EP_Speci_Spon,
      iV_EP_Spon_Amount: this.state.iV_EP_Spon_Amount,
      iV_TT_Speci_Spon: this.state.iV_TT_Speci_Spon,
      iV_TT_Spon_Amount: this.state.iV_TT_Spon_Amount,
      iS_Assoc_EP: this.state.added_IS_EP,
      iS_Assoc_TT: this.state.added_IS_TT,
      iND_Assoc_EP: this.state.added_IND_EP,
      iND_Assoc_TT: this.state.added_IND_TT,
    };
    let url = `${config.API_HOST_URL}/api/Ecosystem/AddSponsorship`;
    fetch(url, {
      method: "POST",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success === true) {
          // this.clearFields();
          ToastNotification.ShowSuccess(resp.message);
          this.Nextpage();
        } else {
          ToastNotification.ShowError(resp.message);
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
    // }
  };
  Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1Loc/:" + this.state.projectId, {
      state: { activeMenu: 4 },
    });
  };
  Back = () => {
    navigate("/TreeTiesAddProjectForms1Colla/:" + this.state.projectId, {
      state: { activeMenu: 2, activesubMenu: true },
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <div className="treeties-addproject-forms1-spo ecosyscom_cont">
        {/* <div className="header7">
                <div className="header-left-content7">
                <Header/>
                </div>
              </div> */}
        <div className="main-content-area7">
          {/* <div className="left-navbar7">
                  <LeftNavbar/>
                </div> */}
          <div className="middle-content-area7">
            <div className="container-18 ecoprosumry_cont">
              <ProjectSummary />
            </div>
            <div className="ecosys_middlecont ecosponsmid_cont">
              <LeftTabMenuContainer
                activeMenu={this.props.location.state?.activeMenu}
              />

              <div className="ecosys_rightcont">
                <div className="container-19">
                  {/* institution sponsor with associated with execution partner in Partnership    */}
                  {this.state.partnerInsList.filter(
                    (x) =>
                      x.associationTypeId ===
                      this.state.associationTypeList.filter(
                        (x) =>
                          x.description ===
                          "Associated with Execution Partner (NGO)"
                      )[0]?.mainGuId
                  ).length >
                    0 ===
                    true && (
                    <div className="input-field-text26 fullwidthspon">
                      <div className="hero-title17">
                        <b className="project-summary17">
                          Institutional Sponsorship &nbsp;&nbsp;:&nbsp;&nbsp;
                          Associated with Execution Partner (NGO)
                        </b>
                      </div>
                      <div className="input-field-text25 fullwidthspon">
                        <div className="content-title-text133">
                          <div className="hero-title16">
                            <div className="project-summary16">
                              Specific Sponsorship Details Available
                            </div>
                          </div>
                          <select
                            className="dropdown-126"
                            name="iT_EP_Speci_Spon"
                            value={this.state.iT_EP_Speci_Spon}
                            onChange={this.handleSelectInput}
                          >
                            <option>Select</option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                        {this.state.iT_EP_Speci_Spon === "No" && (
                          <div className="content-title-text133">
                            <div className="hero-title16">
                              <div className="project-summary16">
                                Sponsorship Amount (in Rs.)
                              </div>
                            </div>
                            <div className="field">
                              <input
                                type="number"
                                inputmode="numeric"
                                min="0"
                                id="ii"
                                placeholder="Enter Amount"
                                name="iT_EP_Spon_Amount"
                                onWheel={(e) => e.target.blur()}
                                value={this.state.iT_EP_Spon_Amount}
                                onChange={this.handleUserInput}
                                onKeyPress={this.handleKeyPress}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {this.state.iT_EP_Speci_Spon === "Yes" && (
                        <div className="form-group row fullwidthspon pb-4">
                          <table className="table noflextable">
                            <thead>
                              <tr>
                                <th scope="col">Institutional Sponsor</th>
                                <th scope="col">Sponsorship Amount (in Rs.)</th>
                              </tr>
                            </thead>
                            {this.state.added_IS_EP &&
                              this.state.added_IS_EP.map((eachIS, i) => {
                                return (
                                  <tbody>
                                    <tr key={i}>
                                      <td>
                                        {
                                          this.state.instSponsorUserList.filter(
                                            (x) =>
                                              x.UserId ===
                                              eachIS.institutional_SponsorId
                                          )[0]?.NameOfNGO
                                        }
                                      </td>
                                      <td>
                                        <td>
                                          <input
                                            type="number"
                                            id="ii"
                                            min="0"
                                            className="form-control form-control-sm"
                                            value={
                                              eachIS.iT_EP_Spon_Amount_Multiple
                                            }
                                            name="iT_EP_Spon_Amount_Multiple"
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) =>
                                              this.handleGridTextChangeEvent_IS_EP(
                                                e,
                                                eachIS.institutional_SponsorId,
                                                eachIS.iT_EP_Spon_Amount_Multiple
                                              )
                                            }
                                            onKeyPress={this.handleKeyPress}
                                          ></input>
                                        </td>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                          </table>
                        </div>
                      )}
                    </div>
                  )}

                  {/* institution sponsor with Brought by TreeTies in Partnership    */}
                  {this.state.partnerInsList.filter(
                    (x) =>
                      x.associationTypeId ===
                      this.state.associationTypeList.filter(
                        (x) => x.description === "Brought by TreeTies"
                      )[0]?.mainGuId
                  ).length >
                    0 ===
                    true && (
                    <div className="input-field-text26 fullwidthspon">
                      <div className="institutional-sponsorship-ass">
                        Institutional Sponsorship &nbsp;&nbsp;:&nbsp;&nbsp;
                        Associated with TreeTies
                      </div>
                      <div className="input-field-text25 fullwidthspon">
                        <div className="content-title-text133">
                          <div className="hero-title16">
                            <div className="project-summary16">
                              Specific Sponsorship Details Available
                            </div>
                          </div>
                          <select
                            className="dropdown-126"
                            name="iT_TT_Speci_Spon"
                            value={this.state.iT_TT_Speci_Spon}
                            onChange={this.handleSelectInput}
                          >
                            <option>Select</option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                        {this.state.iT_TT_Speci_Spon === "No" && (
                          <div className="content-title-text133">
                            <div className="hero-title16">
                              <div className="project-summary16">
                                Sponsorship Amount (in Rs.)
                              </div>
                            </div>
                            <div className="radio-button-input-text4">
                              <input
                                type="number"
                                min="0"
                                id="ii"
                                placeholder="Enter Amount"
                                name="iT_TT_Spon_Amount"
                                onWheel={(e) => e.target.blur()}
                                value={this.state.iT_TT_Spon_Amount}
                                onChange={(event) =>
                                  this.handleUserInput(event)
                                }
                                onKeyPress={this.handleKeyPress}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {this.state.iT_TT_Speci_Spon === "Yes" && (
                        <div className="form-group row fullwidthspon pb-4">
                          <table className="table noflextable">
                            <thead>
                              <tr>
                                <th scope="col">Institutional Sponsor</th>
                                <th scope="col">Sponsorship Amount (in Rs.)</th>
                              </tr>
                            </thead>
                            {this.state.added_IS_TT &&
                              this.state.added_IS_TT.map((eachIS, i) => {
                                return (
                                  <tbody>
                                    <tr key={i}>
                                      <td>
                                        {
                                          this.state.instSponsorUserList.filter(
                                            (x) =>
                                              x.UserId ===
                                              eachIS.institutional_SponsorId
                                          )[0]?.NameOfNGO
                                        }
                                      </td>
                                      <td>
                                        <td>
                                          <input
                                            type="number"
                                            id="ii"
                                            min="0"
                                            className="form-control form-control-sm"
                                            value={
                                              eachIS.iT_TT_Spon_Amount_Multiple
                                            }
                                            name="iT_TT_Spon_Amount_Multiple"
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) =>
                                              this.handleGridTextChangeEvent_IS_TT(
                                                e,
                                                eachIS.institutional_SponsorId,
                                                eachIS.iT_TT_Spon_Amount_Multiple
                                              )
                                            }
                                            onKeyPress={this.handleKeyPress}
                                          ></input>
                                        </td>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="container-19">
                  {/*  Individual Sponsorship with associated with execution partner in Partnership    */}
                  {this.state.partnerIndivList.filter(
                    (x) =>
                      x.associationType_Ind_Id ===
                      this.state.associationTypeList.filter(
                        (x) =>
                          x.description ===
                          "Associated with Execution Partner (NGO)"
                      )[0]?.mainGuId
                  ).length >
                    0 ===
                    true && (
                    <div className="input-field-text26 fullwidthspon">
                      <div className="hero-title17">
                        <b className="project-summary17">
                          Individual Sponsorship &nbsp;&nbsp;: &nbsp;&nbsp;
                          Associated with Execution Partner (NGO)
                        </b>
                      </div>
                      <div className="input-field-text25 fullwidthspon">
                        <div className="content-title-text133">
                          <div className="hero-title16">
                            <div className="project-summary16">
                              Specific Sponsorship Details Available
                            </div>
                          </div>
                          <select
                            className="dropdown-126"
                            name="iV_EP_Speci_Spon"
                            value={this.state.iV_EP_Speci_Spon}
                            onChange={this.handleSelectInput}
                          >
                            <option>Select</option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                        {this.state.iV_EP_Speci_Spon === "No" && (
                          <div className="content-title-text133">
                            <div className="hero-title16">
                              <div className="project-summary16">
                                Sponsorship Amount (in Rs.)
                              </div>
                            </div>
                            <div className="field">
                              <input
                                type="number"
                                min="0"
                                id="ii"
                                placeholder="Enter Amount"
                                name="iV_EP_Spon_Amount"
                                onWheel={(e) => e.target.blur()}
                                value={this.state.iV_EP_Spon_Amount}
                                onChange={(event) =>
                                  this.handleUserInput(event)
                                }
                                onKeyPress={this.handleKeyPress}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {this.state.iV_EP_Speci_Spon === "Yes" && (
                        <div className="form-group row fullwidthspon pb-4">
                          <table className="table noflextable">
                            <thead>
                              <tr>
                                <th scope="col">Individual SponsorShip</th>
                                <th scope="col">Sponsorship Amount (in Rs.)</th>
                              </tr>
                            </thead>

                            {this.state.added_IND_EP &&
                              this.state.added_IND_EP.map((eachIND, i) => {
                                return (
                                  <tbody>
                                    <tr key={i}>
                                      <td>
                                        {
                                          this.state.indSponsorUserList.filter(
                                            (x) =>
                                              x.UserId ===
                                              eachIND.individual_SponsorId
                                          )[0]?.NameOfNGO
                                        }
                                      </td>
                                      <td>
                                        <td>
                                          <input
                                            type="number"
                                            id="ii"
                                            min="0"
                                            className="form-control form-control-sm"
                                            value={
                                              eachIND.iV_EP_Spon_Amount_Multiple
                                            }
                                            name="iV_EP_Spon_Amount_Multiple"
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) =>
                                              this.handleGridTextChangeEvent_IND_EP(
                                                e,
                                                eachIND.individual_SponsorId,
                                                eachIND.iV_EP_Spon_Amount_Multiple
                                              )
                                            }
                                            onKeyPress={this.handleKeyPress}
                                          ></input>
                                        </td>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                  {/*Individual Sponsorship with Brought by TreeTies in Partnership    */}
                  {this.state.partnerIndivList.filter(
                    (x) =>
                      x.associationType_Ind_Id ===
                      this.state.associationTypeList.filter(
                        (x) => x.description === "Brought by TreeTies"
                      )[0]?.mainGuId
                  ).length >
                    0 ===
                    true && (
                    <div className="input-field-text26 fullwidthspon">
                      <div className="institutional-sponsorship-ass">
                        Individual Sponsorship &nbsp;&nbsp;: &nbsp;&nbsp;
                        Associated with TreeTies
                      </div>
                      <div className="input-field-text25 fullwidthspon">
                        <div className="content-title-text133">
                          <div className="hero-title16">
                            <div className="project-summary16">
                              Specific Sponsorship Details Available
                            </div>
                          </div>
                          <select
                            className="dropdown-126"
                            name="iV_TT_Speci_Spon"
                            value={this.state.iV_TT_Speci_Spon}
                            onChange={this.handleSelectInput}
                          >
                            <option>Select</option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                        {this.state.iV_TT_Speci_Spon === "No" && (
                          <div className="content-title-text133">
                            <div className="hero-title16">
                              <div className="project-summary16">
                                Sponsorship Amount (in Rs.)
                              </div>
                            </div>
                            <div className="radio-button-input-text4">
                              <input
                                type="number"
                                id="ii"
                                min="0"
                                placeholder="Enter Amount"
                                name="iV_TT_Spon_Amount"
                                onWheel={(e) => e.target.blur()}
                                value={this.state.iV_TT_Spon_Amount}
                                onChange={(event) =>
                                  this.handleUserInput(event)
                                }
                                onKeyPress={this.handleKeyPress}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {this.state.iV_TT_Speci_Spon === "Yes" && (
                        <div className="form-group row fullwidthspon">
                          <table className="table noflextable">
                            <thead>
                              <tr>
                                <th scope="col">Individual SponsorShip</th>
                                <th scope="col">Sponsorship Amount (in Rs.)</th>
                              </tr>
                            </thead>
                            {this.state.added_IND_TT &&
                              this.state.added_IND_TT.map((eachIND, i) => {
                                return (
                                  <tbody>
                                    <tr key={i}>
                                      <td>
                                        {
                                          this.state.indSponsorUserList.filter(
                                            (x) =>
                                              x.UserId ===
                                              eachIND.individual_SponsorId
                                          )[0]?.NameOfNGO
                                        }
                                      </td>
                                      <td>
                                        <td>
                                          <input
                                            type="number"
                                            id="ii"
                                            min="0"
                                            className="form-control form-control-sm"
                                            value={
                                              eachIND.iV_TT_Spon_Amount_Multiple
                                            }
                                            name="iV_TT_Spon_Amount_Multiple"
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) =>
                                              this.handleGridTextChangeEvent_IND_TT(
                                                e,
                                                eachIND.individual_SponsorId,
                                                eachIND.iV_TT_Spon_Amount_Multiple
                                              )
                                            }
                                            onKeyPress={this.handleKeyPress}
                                          ></input>
                                        </td>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="action-buttons6 pt-4">
                  <button className="cta13">
                    <div className="back6" onClick={this.Back}>
                      Back
                    </div>
                  </button>
                  <button
                    className="cta14"
                    onClick={(e) => this.AddSponsorship(e)}
                  >
                    <div className="save-continue7">{`Save & Continue`}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right-navbar7">
                  <button className="plus-circle7">plus-circle</button>
                  <button className="notifications-bell7">notifications-bell</button>
                  <button className="notifications-bell7">email</button>
                </div> */}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Spo);
