import React, { useState, useCallback, useEffect } from "react";
import { Modal, Button } from "@patternfly/react-core";
import config from "../../../utils/config";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import ToastNotification from "../../Common/ToastNotification/ToastNotification";

export default function AddPointOfContactModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(props.IsOpen);
  const [savedData, setSavedData] = useState([]);
  const [addButton, setAddButton] = useState(false);
  const [prevData, setFormData] = useState({});
  const [execution_PartnerId, setExecution_PartnerId] = useState("");
  const [ngoUserList, setNgoUserList] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [updatedIndex, setUpdatedIndex] = useState(null);
  const [editedIndex, setEditedIndex] = useState(null);
  const [errorMsg, setErrorMsg] = useState([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm();

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let newData = { ...prevData };
    newData[name] = value;
    setFormData(newData);
    validateForm();
  };

  const handleModalToggle = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, [setIsModalOpen]);

  const getSaveTimeSeriesDetails = (data) => {
    let url;
    if (props.selectedUserdata) {
      url = `${config.API_HOST_URL}/api/Ecosystem/UpdatePointOfContact`;
    } else {
      url = `${config.API_HOST_URL}/api/Ecosystem/AddPointOfContact`;
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success === false) {
          ToastNotification.ShowError(response.message);
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          ToastNotification.ShowSuccess(response.message);
          handleCancel();
          props?.getAddPointDetails();
        }
      })
      .catch((error) => {
        console.error("Error in request", error);
      });
  };

  const handleCancel = () => {
    props.setIsPopUpStatus(false);
    props.propsCleardata();
    handleModalToggle(false);
  };

  useEffect(() => {
    if (props.UserListData.length > 0) {
      setNgoUserList(props.UserListData);
    }
  }, [props.UserListData]);

  useEffect(() => {
    if (props.selectedUserdata) {
      const contactData = props.selectedUserdata;
      setExecution_PartnerId(contactData.UserId);
      setSavedData(contactData.PointOfContact || []);
    } else {
      reset();
      setSavedData([]);
      setFormData({});
    }
  }, [props.selectedUserdata, reset]);
  const onSubmit = async (data) => {
    if (!execution_PartnerId) {
      alert("Please Select NGO");
      return;
    }
    if (savedData.length > 0) {
      const pointOfContactRequestModel = {
        UserId: execution_PartnerId,
        PointOfContact: savedData,
      };
      getSaveTimeSeriesDetails(pointOfContactRequestModel);
    } else {
      ToastNotification.ShowError(
        "Please add all details for point of contact"
      );
    }
    // document.location.reload();
  };
  const handleChange = (e) => {
    setExecution_PartnerId(e.target.value);
  };
  const handleDeletesaveddata = (data) => {
    const newData = savedData.filter((item) => item !== data);
    setSavedData(newData);
    setFormData({});
  };
  const handleEditData = (data, index) => {
    setEditedData(data);
    setUpdatedIndex(index);
    setFormData({
      ...prevData,
      NameOfNGO: data.NameOfNGO,
      ContactName: data.ContactName,
      Mobile: data.Mobile,
      Email: data.Email,
      Mobile: data.Mobile,
      ContactDesignation: data.ContactDesignation,
    });
  };

  const handleEditsaveddata = (data, index) => {
    handleEditData(data, index);
  };

  const handleSaveEditedData = () => {
    if (editedIndex !== null) {
      const updatedSavedData = [...savedData];
      updatedSavedData[editedIndex] = editedData;
      setSavedData(updatedSavedData);
      setEditedData(null);
      setEditedIndex(null);
    }
  };

  const validateForm = () => {
    const errors = {};
    let formIsValid = true;
    if (!execution_PartnerId) {
      errors["execution_PartnerId"] = "Please Select NGO.";
      formIsValid = false;
    }
    if (!prevData.ContactName) {
      errors["ContactName"] = "Enter Contact Name";
      formIsValid = false;
    }
    if (!prevData.Mobile) {
      errors["Mobile"] = "Enter Mobile Number";
      formIsValid = false;
    } else {
      let mobileValue = prevData.Mobile;
      if (/^\d*$/.test(mobileValue)) {
        if (mobileValue.length === 10) {
          errors["Mobile"] = "";
          formIsValid = true;
        } else if (mobileValue.length > 0 && mobileValue.length < 9) {
          errors["Mobile"] = "Invalid Mobile Number";
          formIsValid = false;
        }
      }
    }
    if (!prevData.Email) {
      errors["Email"] = "Enter Email Id";
      formIsValid = false;
    } else {
      let emailValue = prevData.Email;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(emailValue)) {
        errors["Email"] = "Invalid Email Address";
        formIsValid = false;
      } else {
        errors["Email"] = "";
        formIsValid = true;
      }
    }
    if (!prevData.ContactDesignation) {
      errors["ContactDesignation"] = "Enter Contact Designation";
      formIsValid = false;
    }
    setErrorMsg(errors);
    return formIsValid;
  };

  const handleAddData = (index = null) => {
    if (validateForm()) {
      if (index === null) {
        const newData = {
          NameOfNGO: "",
          ContactName: prevData.ContactName,
          Mobile: prevData.Mobile,
          Email: prevData.Email,
          ContactDesignation: prevData.ContactDesignation,
        };
        setSavedData([...savedData, newData]);
      } else {
        const updatedData = [...savedData];
        updatedData[index] = {
          ...updatedData[index],
          ContactName: prevData.ContactName,
          Mobile: prevData.Mobile,
          Email: prevData.Email,
          ContactDesignation: prevData.ContactDesignation,
        };
        setSavedData(updatedData);
        setUpdatedIndex(null);
      }
      setAddButton(false);
      setFormData({});
    }
  };

  return (
    <>
      <Modal
        title={
          props.selectedUserdata
            ? "Update Point Of Contact"
            : "Add Point Of Contact"
        }
        isOpen={isModalOpen}
        onClose={() => handleModalToggle(false)}
        className="addproject_modal fieldofficer_modal timeseriesform_modal addpointofcontact_modal"
        actions={[
          <Button
            key="submit"
            type="button"
            className="modal_btn submit_btn"
            onClick={handleSubmit(onSubmit)}
          >
            {props.selectedUserdata ? "Update" : "Submit"}
          </Button>,
          <Button
            key="cancel"
            className="modal_btn cancel_btn"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul className="addproject_form">
            <li className="form-group field">
              <Controller
                control={control}
                name="execution_PartnerId"
                // rules={{
                //   required: "Please Select NGO",
                // }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <select
                      className="dropdown-130"
                      name="execution_PartnerId"
                      value={execution_PartnerId}
                      onChange={(event) => {
                        if (!props.selectedUserdata) {
                          setExecution_PartnerId(event.target.value);
                          onChange(event);
                          setErrorMsg((prevErrorMsg) => ({
                            ...prevErrorMsg,
                            execution_PartnerId: "",
                          }));
                        }
                      }}
                      disabled={props.selectedUserdata}
                    >
                      <option value="0">Select</option>
                      {props.UserListData.map((item) => (
                        <option value={item.UserId}>{item.NameOfNGO}</option>
                      ))}
                    </select>
                    {errorMsg && (
                      <div className="mandatory has-error">
                        {errorMsg.execution_PartnerId}
                      </div>
                    )}
                    <label htmlFor="SelectNGO">
                      <sup className="mandatory">*</sup>Select NGO
                    </label>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="ContactName"
                rules={{
                  pattern: {
                    value: /^[a-zA-Z\s]{1,20}$/,
                    message:
                      "Only alphabets and spaces are allowed, max 20 characters!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      name="ContactName"
                      id="ContactName"
                      placeholder="Enter Contact name"
                      value={prevData?.ContactName ? prevData?.ContactName : ""}
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (event.key === " ") {
                          if (event.target.value.endsWith(" ")) {
                            event.preventDefault();
                          }
                        } else if (!/^[a-zA-Z]$/.test(event.key)) {
                          event.preventDefault();
                        }
                        if (value && value.length >= 20) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="ContactName">
                      <sup className="mandatory">*</sup>Contact Name
                    </label>
                    {errorMsg && (
                      <div className="mandatory has-error">
                        {errorMsg?.ContactName}
                      </div>
                    )}
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="Mobile"
                rules={{
                  // required: "Please Enter Contact",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Only numeric characters are allowed!",
                  },
                  minLength: {
                    value: 10,
                    message: "Contact must be at least 10 digits long!",
                  },
                  maxLength: {
                    value: 10,
                    message: "Contact must not be more than 10 digits long!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="tel"
                      id="Mobile"
                      name="Mobile"
                      value={prevData?.Mobile ? prevData?.Mobile : ""}
                      placeholder="Enter Contact Number"
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (
                          event.target.value.length >= 10 &&
                          /\d/.test(event.key)
                        ) {
                          event.preventDefault(); // prevent entering more than 15 digits
                        }
                        if (!/^[0-9]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="Mobile">
                      <sup className="mandatory">*</sup>Mobile Number
                    </label>
                    {errorMsg && (
                      <div className="mandatory has-error">
                        {errorMsg?.Mobile}
                      </div>
                    )}
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="Email"
                rules={{
                  //required: "Please Enter Email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email address!",
                  },
                  //   validate: (value) => {
                  //     const currentUserId = formData?.userId;
                  //     const userEmails = userList
                  //       .filter(
                  //         (item) =>
                  //           item.UserId !== currentUserId && item.Email !== null
                  //       )
                  //       .map((item) => item.Email);
                  //     return userEmails.includes(value)
                  //       ? "Email address is already in use"
                  //       : undefined;
                  //   },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      name="Email"
                      id="Email"
                      autocomplete="off"
                      autofill="off"
                      data-random={Math.random()}
                      value={prevData?.Email ? prevData?.Email : ""}
                      placeholder="Enter Email"
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="Email">
                      <sup className="mandatory">*</sup>Email
                    </label>
                    {errorMsg && (
                      <div className="mandatory has-error">
                        {errorMsg?.Email}
                      </div>
                    )}
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="ContactDesignation"
                rules={{
                  pattern: {
                    value: /^[a-zA-Z\s]{1,20}$/,
                    message:
                      "Only alphabets and spaces are allowed, max 20 characters!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      id="ContactDesignation"
                      placeholder="Enter Contact Designation"
                      name="ContactDesignation"
                      value={
                        prevData?.ContactDesignation
                          ? prevData?.ContactDesignation
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (event.key === " ") {
                          if (event.target.value.endsWith(" ")) {
                            event.preventDefault();
                          }
                        } else if (!/^[a-zA-Z]$/.test(event.key)) {
                          event.preventDefault();
                        }
                        if (value && value.length >= 20) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="ContactDesignation">
                      <sup className="mandatory">*</sup>Contact Designation
                    </label>
                    {errorMsg && (
                      <div className="mandatory has-error">
                        {errorMsg?.ContactDesignation}
                      </div>
                    )}
                  </>
                )}
              />
            </li>
          </ul>
          <a
            className="treedetmodal_addbtn"
            onClick={() => handleAddData(updatedIndex)}
          >
            Add Data
          </a>

          <div
            className={`uploadedimgtbl_cont ${
              savedData.length <= 0 ? "nodataupldimgtbl_cont" : ""
            }`}
          >
            <table>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Contact Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {savedData.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.ContactName}</td>
                    <td>{data.Email}</td>
                    <td>
                      <a onClick={() => handleEditsaveddata(data, index)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M4.09942 15.6C3.62017 15.6 3.21718 15.4367 2.89046 15.1099C2.56375 14.7832 2.40039 14.3802 2.40039 13.901V4.09905C2.40039 3.6198 2.56375 3.21681 2.89046 2.89009C3.21718 2.56337 3.62017 2.40002 4.09942 2.40002H9.25856C9.46481 2.40002 9.6195 2.46525 9.72262 2.59571C9.82575 2.72617 9.87731 2.86776 9.87731 3.02047C9.87731 3.17318 9.82613 3.3142 9.72377 3.44353C9.62142 3.57285 9.47115 3.63752 9.27298 3.63752H4.09942C3.98405 3.63752 3.87828 3.68559 3.78212 3.78174C3.68597 3.8779 3.63789 3.98367 3.63789 4.09905V13.901C3.63789 14.0164 3.68597 14.1221 3.78212 14.2183C3.87828 14.3144 3.98405 14.3625 4.09942 14.3625H13.9014C14.0167 14.3625 14.1225 14.3144 14.2187 14.2183C14.3148 14.1221 14.3629 14.0164 14.3629 13.901V8.66974C14.3629 8.46349 14.4281 8.3088 14.5586 8.20567C14.689 8.10255 14.8306 8.05099 14.9833 8.05099C15.1361 8.05099 15.2771 8.10255 15.4064 8.20567C15.5357 8.3088 15.6004 8.46349 15.6004 8.66974V13.901C15.6004 14.3802 15.437 14.7832 15.1103 15.1099C14.7836 15.4367 14.3806 15.6 13.9014 15.6H4.09942ZM7.25664 9.89426V9.1356C7.25664 8.9062 7.30238 8.68455 7.39385 8.47065C7.48532 8.25675 7.60929 8.07215 7.76578 7.91685L13.8898 1.7928C14.0225 1.66011 14.1606 1.56852 14.3043 1.51803C14.4479 1.46756 14.5963 1.44232 14.7495 1.44232C14.8994 1.44232 15.0525 1.47068 15.2088 1.52741C15.365 1.58415 15.5071 1.67405 15.635 1.79713L16.1499 2.28752C16.2797 2.4202 16.3747 2.56549 16.4348 2.72338C16.4949 2.88127 16.5249 3.04088 16.5249 3.20223C16.5249 3.36357 16.4978 3.51793 16.4436 3.6653C16.3894 3.81268 16.2949 3.9515 16.16 4.08176L10.0114 10.2491C9.8528 10.4077 9.66706 10.5298 9.45423 10.6154C9.24142 10.701 9.02091 10.7438 8.7927 10.7438H8.10615C7.86961 10.7438 7.66889 10.6613 7.50399 10.4964C7.33909 10.3315 7.25664 10.1308 7.25664 9.89426ZM8.49414 9.50627H8.97587L13.7499 4.75099L13.509 4.49137L13.2206 4.22165L8.49414 8.9481V9.50627Z"
                            fill="#6C6C6C"
                          ></path>
                        </svg>
                      </a>
                      <a onClick={() => handleDeletesaveddata(data)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24px"
                          height="24px"
                        >
                          <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z" />
                        </svg>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </form>
      </Modal>
    </>
  );
}
