import React, { useEffect, useState, useCallback } from "react";
import Container from "react-bootstrap/Container";
import { Scrollbars } from "react-custom-scrollbars";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import config from "../../../utils/config";
import ToastNotification from "../../Common/ToastNotification/ToastNotification";
import Loader from "../../Common/Loader/NewLoader";

const ApproveGroundSamplingData = ({ props, userData }) => {
  const [activePage, setActivePage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [up, setUp] = useState("active");
  const [down, setDown] = useState("");
  const [pageRange, setPageRange] = useState(10);
  const [sortType, setSortType] = useState(true);
  const [status, setStatus] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const [selectedSource, setSelectedSource] = useState("Ground Sampling");
  const [selectAll, setSelectAll] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [treeDetailList, setTreeDetailList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData?.userId)
      GetProjectFilterByUserId(userData?.userId, userData?.roleId);
  }, [userData?.userId]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    GetTreeDetailsBySource(selectedProject, pageNumber);
  };

  const handleSortClick = (field) => {
    const newSortOrder =
      sortBy === field && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(field);
    setSortOrder(newSortOrder);

    const sortedList = [...treeDetailList].sort((a, b) => {
      const aField = field === "tree_id" ? `${a.tree_id}` : a[field];
      const bField = field === "species_name" ? `${b.species_name}` : b[field];

      if (aField < bField) return newSortOrder === "asc" ? -1 : 1;
      if (aField > bField) return newSortOrder === "asc" ? 1 : -1;
      return 0;
    });
    setTreeDetailList(sortedList);
  };

  const GetTreeDetailsBySource = async (ProjectId, pageNumber) => {
    setLoading(true);
    let source = selectedSource;
    let projectId = ProjectId;
    const uri = `${config.API_HOST_URL}/api/TreeDetails/GetTreeDetailsBySource/${projectId}/${source}/${pageNumber}/${pagesize}`;
    const fetchOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(uri, fetchOptions);
      const data = await response.json();
      if (data.success) {
        const treeDetailData = data.list;
        const treeCount = data.count;
        setTreeDetailList(treeDetailData);
        setTotalCount(treeCount);
        setSelectAll(false);
      } else {
        console.error("Error fetching projects", data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching projects", error);
      setLoading(false);
    }
  };

  const GetProjectFilterByUserId = async (userId, roleId) => {
    setLoading(true);
    const UserDetail = {
      UserId: userId,
      RoleId: roleId,
    };
    const uri = `${config.API_HOST_URL}/api/projects/GetProjectFilterByUserId`;
    const fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await fetch(uri, fetchOptions);
      const data = await response.json();
      if (data.success) {
        const projectListData = data.data;
        setProjectList(projectListData);
        GetTreeDetailsBySource(projectListData[0].projectId, activePage);
      } else {
        console.error("Error fetching projects", data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching projects", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projectList.length > 0) {
      setSelectedProject(projectList[0].projectId);
      GetTreeDetailsBySource(projectList[0].projectId, activePage);
    }
  }, [projectList]);

  const ApproveOrRejectTreeDetails = async (status) => {
    const selectedTreeDetails = treeDetailList
      .filter((tree) => tree.isSelected)
      .map((tree) => ({
        tree_id: tree.tree_id,
        approvalStatus: status,
        source: tree.source,
        projectID: tree.projectID,
      }));

    if (selectedTreeDetails.length === 0) {
      if (status === "Approved") {
        ToastNotification.ShowError("Trees Not Selected For Approval");
        return;
      } else if (status === "Rejected") {
        ToastNotification.ShowError("Trees Not Selected For Reject");
        return;
      }
    } else {
      const url = `${config.API_HOST_URL}/api/TreeDetails/ApproveTreeDetails`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedTreeDetails),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responseData = await response.json();
        if (responseData.success)
          GetTreeDetailsBySource(selectedProject, activePage);
        if (status === "Approved") {
          ToastNotification.ShowSuccess("Approved Successfully");
        } else if (status === "Rejected") {
          ToastNotification.ShowSuccess("Rejected Successfully");
        }
      } catch (error) {
        console.error("Error in request", error);
      }
    }
  };

  const handleChange = (e) => {
    setSelectedProject(e.target.value);
    GetTreeDetailsBySource(e.target.value, activePage);
  };
  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    setTreeDetailList(
      treeDetailList.map((row) => ({
        ...row,
        isSelected: checked,
      }))
    );
  };

  const handleRowSelect = (id, checked) => {
    setTreeDetailList(
      treeDetailList.map((row) => {
        if (row.tree_id === id) {
          return {
            ...row,
            isSelected: checked,
          };
        }
        return row;
      })
    );
    setSelectAll(false);
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      innerWidth: 4,
      backgroundColor: "#50b041",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  const CustomScrollbars = (props) => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );
  const Loader = () => {
    return (
      <div className="spinner-loader">
        <div className="spinner"></div>
      </div>
    );
  };
  return (
    <>
      {loading && (
        <div className="loader-overlay listspages_cont">
          <Loader />
        </div>
      )}
      <div className="headbottom_cont">
        <ul className="headbottom_content fieldofficertop_cont">
          <li className="heading_cont">Approve Ground Sampling Data</li>
        </ul>
      </div>
      <div className="growalarm_container prolistmodal approvalgsdata_cont">
        <Container>
          <div className="selectsearch_wrap">
            <label>Select Project :</label>
            <select
              value={selectedProject}
              className="prolstvewpop_select"
              onChange={(e) => handleChange(e)}
            >
              {projectList &&
                projectList.map((option, index) => (
                  <option key={index} value={option.projectId}>
                    {option.projectName}
                  </option>
                ))}
            </select>
            <div className="addpro_btn">
              <a onClick={() => ApproveOrRejectTreeDetails("Approved")}>
                Approve
              </a>
            </div>
            <div className="addpro_btn addproreject_btn">
              <a onClick={() => ApproveOrRejectTreeDetails("Rejected")}>
                Reject
              </a>
            </div>
          </div>
          <div className="growalarm_content">
            <div className="commontbl_wrap">
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
              >
                <table className="commontbl_cont prolistviewtbl">
                  <thead>
                    <tr>
                      <th className="selectAllTh">
                        <input
                          value={selectAll}
                          checked={selectAll}
                          type="checkbox"
                          id="selectAll"
                          onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                      </th>
                      <th className="thGridNo">SN</th>
                      <th className="treeididth">
                        Tree Id
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("tree_id")}
                        >
                          <i
                            className={
                              sortBy === "tree_id"
                                ? `fas fa-sort-up ${up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              sortBy === "tree_id"
                                ? `fas fa-sort-down ${down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      <th className="treespeciesth">
                        Species Name{" "}
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("species_name")}
                        >
                          <i
                            className={
                              sortBy === "species_name"
                                ? `fas fa-sort-up ${up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              sortBy === "species_name"
                                ? `fas fa-sort-down ${down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      {selectedSource === "Ground Sampling" && (
                        <th className="treeplantdtth">
                          Tree Planted Date
                          <div
                            className="sortArrow"
                            onClick={() => handleSortClick("planted_date")}
                          >
                            <i
                              className={
                                sortBy === "planted_date"
                                  ? `fas fa-sort-up ${up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                sortBy === "planted_date"
                                  ? `fas fa-sort-down ${down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                      )}
                      <th className="treesstatusth">
                        Tree Status
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("tree_Status")}
                        >
                          <i
                            className={
                              sortBy === "tree_Status"
                                ? `fas fa-sort-up ${up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              sortBy === "tree_Status"
                                ? `fas fa-sort-down ${down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      {selectedSource === "Ground Sampling" && (
                        <th className="treesplantbyth">
                          Tree Planted By
                          <div
                            className="sortArrow"
                            onClick={() => handleSortClick("treePlantedby")}
                          >
                            <i
                              className={
                                sortBy === "treePlantedby"
                                  ? `fas fa-sort-up ${up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                sortBy === "treePlantedby"
                                  ? `fas fa-sort-down ${down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                      )}
                      {selectedSource === "Ground Sampling" && (
                        <th className="treeheightth">
                          Tree Height (m)
                          <div
                            className="sortArrow"
                            onClick={() => handleSortClick("tree_height")}
                          >
                            <i
                              className={
                                sortBy === "tree_height"
                                  ? `fas fa-sort-up ${up}`
                                  : "fas fa-sort-up"
                              }
                            ></i>
                            <i
                              className={
                                sortBy === "tree_height"
                                  ? `fas fa-sort-down ${down}`
                                  : "fas fa-sort-down"
                              }
                            ></i>
                          </div>
                        </th>
                      )}
                      <th className="treewealthth">
                        Approval Status
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("approvalStatus")}
                        >
                          <i
                            className={
                              sortBy === "approvalStatus"
                                ? `fas fa-sort-up ${up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              sortBy === "approvalStatus"
                                ? `fas fa-sort-down ${down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                      <th className="treesourceth">
                        Source
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("source")}
                        >
                          <i
                            className={
                              sortBy === "source"
                                ? `fas fa-sort-up ${up}`
                                : "fas fa-sort-up"
                            }
                          ></i>
                          <i
                            className={
                              sortBy === "source"
                                ? `fas fa-sort-down ${down}`
                                : "fas fa-sort-down"
                            }
                          ></i>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {treeDetailList && treeDetailList.length > 0 ? (
                      treeDetailList?.map((item, index) => (
                        <tr key={item?.tree_id}>
                          <td className="selectalltd">
                            <input
                              type="checkbox"
                              checked={item.isSelected}
                              onChange={(e) =>
                                handleRowSelect(item?.tree_id, e.target.checked)
                              }
                            />
                          </td>
                          <td className="tdGridNo">{index + 1}</td>
                          <td className="idtd treeidtd">
                            <a>{item?.tree_id}</a>
                          </td>
                          <td className="treetypetd">
                            <span style={{ fontWeight: "bold" }}>
                              {item?.species_name}
                            </span>
                          </td>
                          <td className="idtd">{item?.planted_date}</td>
                          <td className="idtd alivetd treesstatustd">
                            <span>{item?.tree_Status}</span>
                          </td>
                          <td className="idtd treesplantbytd">
                            {item?.treePlantedby}
                          </td>
                          <td className="idtd treeheighttd">
                            {item?.tree_height}
                          </td>
                          <td
                            className={
                              item?.approvalStatus === "Approved"
                                ? "idtd statustwo resolved"
                                : item?.approvalStatus === "Rejected"
                                ? "idtd statustwo rejected"
                                : "idtd statustwo pending"
                            }
                          >
                            <span>{item?.approvalStatus}</span>
                          </td>
                          <td className="idtd treesourcetd">{item?.source}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10} style={{ textAlign: "center" }}>
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </CustomScrollbars>
            </div>
          </div>
          {treeDetailList && treeDetailList.length > 0 ? (
            <div className="paginationDiv">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pagesize}
                totalItemsCount={totalCount}
                pageRangeDisplayed={pageRange}
                nextPageText={<i className="fa fa-angle-right"></i>}
                prevPageText={<i className="fa fa-angle-left"></i>}
                onChange={(pageNumber) => handlePageChange(pageNumber)}
              />
            </div>
          ) : (
            ""
          )}
        </Container>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
};

export default connect(mapStateToProps)(ApproveGroundSamplingData);
