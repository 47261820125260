import { useEffect, useState } from "react";
import MapImage from "../../../assets/Map.PNG";
import config from "../../../utils/config";

export default function ProjectOverviewSummary(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(props.projectDetail);
  }, [props?.projectDetail]);
  console.log("Projectdetails", props.projectDetail);
  return (
    <div className="row">
      <div className="col-9">
        <div className="prosummarylist">
          {data?.projectName && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Project Name<abbr>:&nbsp;</abbr>
              </div>
              <div className="produmitem_content">{data?.projectName}</div>
            </div>
          )}
          {data?.ownername && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Owner<abbr>:&nbsp;</abbr>
              </div>
              <div className="produmitem_content">{data?.ownername}</div>
            </div>
          )}
          {data?.location && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Site Location<abbr>:&nbsp;</abbr>
              </div>
              <div className="produmitem_content">{data?.location}</div>
            </div>
          )}
          {data?.area && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Area<abbr>:&nbsp;</abbr>
              </div>
              <div className="produmitem_content">
                {/* {data?.area.toFixed(2)} acres */}
                {typeof data?.area === "number"
                  ? data?.area.toFixed(2)
                  : parseFloat(data?.area).toFixed(2)}{" "}
                acres
              </div>
            </div>
          )}
          {data?.ecoregion && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Ecoregion<abbr>:&nbsp;</abbr>
              </div>
              <div className="produmitem_content">{data?.ecoregion}</div>
            </div>
          )}

          <div className="prosummarylist_item">
            <div className="produmitem_title">
              Climate<abbr>:&nbsp;</abbr>
            </div>
            <div className="produmitem_content">
              {data?.climatetype ? data?.climatetype : "-"}
              <table className="prosumarytbl prosumartbltemp">
                <thead>
                  <tr>
                    <th colSpan="5">Temperature (°C)</th>
                    <th>Min</th>
                    <th>Avg</th>
                    <th colSpan="2">Max</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>°C</td>
                    <td
                      className={
                        data?.avg_Temp_Summer <= -20 ? "activetype_td" : ""
                      }
                    >
                      -20
                    </td>
                    <td
                      className={
                        data?.avg_Temp_Summer > -20 &&
                        data?.avg_Temp_Summer <= -10
                          ? "activetype_td"
                          : ""
                      }
                    >
                      -10
                    </td>
                    <td
                      className={
                        data?.avg_Temp_Summer > -10 &&
                        data?.avg_Temp_Summer <= 0
                          ? "activetype_td"
                          : ""
                      }
                    >
                      0
                    </td>
                    <td
                      className={
                        data?.avg_Temp_Summer > 0 && data?.avg_Temp_Summer <= 10
                          ? "activetype_td"
                          : ""
                      }
                    >
                      10
                    </td>
                    <td
                      className={
                        data?.avg_Temp_Summer > 10 &&
                        data?.avg_Temp_Summer <= 20
                          ? "activetype_td"
                          : ""
                      }
                    >
                      20
                    </td>
                    <td
                      className={
                        data?.avg_Temp_Summer > 20 &&
                        data?.avg_Temp_Summer <= 30
                          ? "activetype_td"
                          : ""
                      }
                    >
                      30
                    </td>
                    <td
                      className={
                        data?.avg_Temp_Summer > 30 &&
                        data?.avg_Temp_Summer <= 40
                          ? "activetype_td"
                          : ""
                      }
                    >
                      40
                    </td>
                    <td
                      className={
                        data?.avg_Temp_Summer > 40 &&
                        data?.avg_Temp_Summer <= 50
                          ? "activetype_td"
                          : ""
                      }
                    >
                      50
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="prosumarytbl prosumartblrainfall">
                <thead>
                  <tr>
                    <th colSpan="3">Rainfall (in mm)</th>
                    <th colSpan="2">Min</th>
                    <th>Avg</th>
                    <th>Max</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>mm</td>
                    <td
                      className={
                        data?.avg_Yearly_RainFall > 0 &&
                        data?.avg_Yearly_RainFall <= 10
                          ? "activetype_td"
                          : ""
                      }
                    >
                      10
                    </td>
                    <td
                      className={
                        data?.avg_Yearly_RainFall > 10 &&
                        data?.avg_Yearly_RainFall <= 30
                          ? "activetype_td"
                          : ""
                      }
                    >
                      30
                    </td>
                    <td
                      className={
                        data?.avg_Yearly_RainFall > 30 &&
                        data?.avg_Yearly_RainFall <= 50
                          ? "activetype_td"
                          : ""
                      }
                    >
                      50
                    </td>
                    <td
                      className={
                        data?.avg_Yearly_RainFall > 50 &&
                        data?.avg_Yearly_RainFall <= 90
                          ? "activetype_td"
                          : ""
                      }
                    >
                      90
                    </td>
                    <td
                      className={
                        data?.avg_Yearly_RainFall > 90 &&
                        data?.avg_Yearly_RainFall <= 120
                          ? "activetype_td"
                          : ""
                      }
                    >
                      120
                    </td>
                    <td
                      className={
                        data?.avg_Yearly_RainFall > 120 &&
                        data?.avg_Yearly_RainFall <= 150
                          ? "activetype_td"
                          : ""
                      }
                    >
                      150
                    </td>
                    <td
                      className={
                        data?.avg_Yearly_RainFall > 150 &&
                        data?.avg_Yearly_RainFall <= 200
                          ? "activetype_td"
                          : ""
                      }
                    >
                      200
                    </td>
                    <td
                      className={
                        data?.avg_Yearly_RainFall > 200 &&
                        data?.avg_Yearly_RainFall >= 300
                          ? "activetype_td"
                          : ""
                      }
                    >
                      300
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {data?.soiltype && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Soil type<abbr>:&nbsp;</abbr>
              </div>
              <div className="produmitem_content">{data?.soiltype}</div>
            </div>
          )}

          {data?.projectType && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Project Type<abbr>:&nbsp;</abbr>
              </div>
              <div className="produmitem_content">{data?.projectType}</div>
            </div>
          )}

          {data?.siteUse && (
            <div className="prosummarylist_item">
              <div className="produmitem_title">
                Site Use<abbr>:&nbsp;</abbr>
              </div>
              <div className="produmitem_content">{data?.siteUse}</div>
            </div>
          )}
        </div>
      </div>
      {data?.imageName && data?.imageName !== "default.jpg" && (
        <div className="col-3">
          <div className="prosummap_cont">
            <h3>Sitemap</h3>
            {/* <img src={MapImage} alt="Map" width="100" height="100"></img> */}{" "}
            <img
              src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/ProjectOverview/${data?.imageName}`}
              alt="Mangotree"
            />
          </div>
        </div>
      )}
      {/* :(
        <div className="col-3">
          <div className="prosummap_cont">
            <h3>Sitemap</h3>
            <img
              src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/ProjectOverview/default.jpg`}
              alt="Mangotree"
            />
          </div>
        </div>
      )} */}
    </div>
  );
}
