import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import CircleType from "circletype";
import { Progress, ProgressSize } from "@patternfly/react-core";
import "react-circular-progressbar/dist/styles.css";
import { navigate } from "@reach/router";
import { Modal, Button, Select, SelectOption } from "@patternfly/react-core";
import treesplant from "../../../assets/trees-plant.png";
import totaltreesplanted from "../../../assets/totaltrees_planted.png";
import treesplantnew from "../../../assets/prolist_newtree.png";
import disturbanceImpact from "../../../assets/disturbanceImpact.png";
import opportunity from "../../../assets/opportunity.png";
import co2emission from "../../../assets/CO2-emission.png";
import amtraised from "../../../assets/amount-raised.png";
import Searchcomp from "../../Common/Search/Search";
import AddProject from "./Addproject";
import config from "../../../utils/config";
import AddNewProject from "./AddNewProject";
import ProjectMapView from "./ProjectMapView";
import Loader from "../../Common/Loader/NewLoader";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: "gridbtn",
      ProjectStatus: "All",
      ProjectList: [],
      LastUpdatedDateTime: "",
      AllProjectCounts: 0,
      VerifiedProjectCounts: 0,
      UnverifiedProjectCounts: 0,
      RejectedProjectCount: 0,
      ActiveColorState: "All",
      HealthyPercentage: 0,
      isshowMapView: false,
      activeTab: "GridView",
      isSidebarOpen: false,
      GoogleMapApiKey: "",
      GetGlobalSettings: "Google Map",
      ProjectDetailData: [],
      isOpen: false,
      selectedValue: "All",
      isDropdownOpen: false,
      loading: true,
      RegressionList: [],
    };
    this.mounted = true;
    this.selectValue = this.selectValue.bind(this);
  }

  handleTabChange(tab) {
    this.setState({ activeTab: tab });
    const headbottomCont = document.getElementById("headbottom_cont");
    const projectsContainer = document.getElementById("projects_container");
    if (tab === "MapView" && headbottomCont && projectsContainer) {
      headbottomCont.classList.add("mapviewactive_container");
      projectsContainer.classList.add("mapviewactive_container");
    } else if (headbottomCont && projectsContainer) {
      headbottomCont.classList.remove("mapviewactive_container");
      projectsContainer.classList.remove("mapviewactive_container");
    }
  }

  handleCampaigns = (Type) => {
    let selectedType = "";
    let projlistingview = document.getElementById("projects_list");
    if (Type === "gridbtn") {
      //selectedType = "gridbtn";
      projlistingview?.classList?.remove("projlistview");
    } else if (Type === "listbtn") {
      //selectedType = "listbtn";
      projlistingview?.classList?.add("projlistview");
    } else if (Type === "mapbtn") {
      //selectedType = "mapbtn";
      this.setState((prevState) => ({
        isshowMapView: !prevState.isshowMapView,
      }));
    }
    this.setState({
      selectedType: Type,
    });
  };

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener("click", this.handleDocumentClick);
  }
  componentDidMount() {
    this.mounted = true;
    this.GetProjectCounts();
    this.GetGlobalSettings("Google Map");
    let projlistingview = document.getElementById("projects_list");
    document.addEventListener("click", this.handleDocumentClick);
  }

  GetAllProjectDetails = (ProjectStatus) => {
    let UserDetail = {
      //ProjectId: ProjectId,
      Status: ProjectStatus,
      UserId: this.props.userData.userId,
    };
    let url = `${config.API_HOST_URL}/api/Projects/GetProjectDetails`;
    fetch(url, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(UserDetail),
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        if (responseData !== null) {
          this.setState({
            ProjectDetailData: responseData,
          });
        }
      })

      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetGlobalSettings = (GroupName) => {
    const UserDetail = {
      GroupName: GroupName,
    };
    const uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
    const fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (data.success) {
            const responseData = data.data;
            this.setState({
              GoogleMapApiKey: this.MapKeyInArray(
                responseData,
                "GoogleMapApiKey"
              ),
            });
          } else {
            const errors = {};
            errors["message"] = data.message;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  MapKeyInArray = (responseData, keyname) => {
    let myArray = responseData.find((obj) => obj.key === keyname);
    if (myArray) {
      return myArray.value;
    }
  };

  FilterProject = (e) => {
    if (this.mounted) {
      e.stopPropagation();
      let value = e.target.getAttribute("value");
      this.setState({ ProjectStatus: value, ActiveColorState: value });
      this.GetProjectByFilter(value);
      this.GetAllProjectDetails(value);
      setTimeout(() => {
        this.toggleDropdown(); // Close the dropdown after updating the state
      }, 0);
    }
  };

  GetProjectCounts = () => {
    if (this.mounted) {
      let UserDetail = {
        UserId: this.props.userData?.userId,
        RoleId: this.props.userData?.roleId,
      };
      let uri = `${config.API_HOST_URL}/api/projects/GetProjectCounts`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((response) => {
          this.GetProjectByFilter(this.state.ProjectStatus);
          this.GetAllProjectDetails(this.state.ProjectStatus);
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data.success) {
                if (this.mounted) {
                  let responseData = data;
                  this.setState({
                    AllProjectCounts: responseData?.all,
                    VerifiedProjectCounts: responseData?.verified,
                    UnverifiedProjectCounts: responseData?.unverified,
                    RejectedProjectCount: responseData?.rejected,
                    LastUpdatedDateTime: response?.lastUpdatedDate,
                  });
                }
              } else {
                let errors = {};
                errors["message"] = data.message;
                if (this.mounted) {
                  this.setState({
                    errors: errors,
                  });
                }
              }
            });
          } else {
            return response.json().then((data) => {
              let errors = {};
              errors["message"] = data.message;
              if (this.mounted) {
                this.setState({
                  errors: errors,
                });
              }
            });
          }
        })

        .catch((error) => {
          if (this.mounted) {
            console.log(error);
            this.setState({
              errorMsg: error,
              IsLoader: false,
            });
          }
        });
    }
  };

  //   projectdetailclick = (projectId, index, status, item) => (e) => {
  //     e.preventDefault();
  //     if (status === "Verified") {
  //       navigate("/projectdetailsnew/:" + projectId + "/:" + (index + 1));
  //     }
  //   };

  projectdetailclickNew = (projectId, index, status, item) => (e) => {
    e.preventDefault();
    if (status === "Verified") {
      navigate("/projectdetailsnew/:" + projectId + "/:" + (index + 1));
    }
  };
  goToPartnership = (projectId, status, index) => (e) => {
    e.preventDefault();
    if (status === "Verified") {
      navigate("/TreeTiesAddProjectForms1Par1/:" + projectId, {
        state: { activeMenu: 1, activesubMenu: true },
      });
    }
  };
  projlistinggridview = (e) => {
    let projlistingview = document.getElementById("projects_list");
    projlistingview.classList.remove("projlistview");
  };

  projlistinglistview = (e) => {
    let projlistingview = document.getElementById("projects_list");
    projlistingview.classList.add("projlistview");
  };

  GetProjectByFilter = (ProjectStatus) => {
    this.setState({ loading: true });
    if (this.mounted) {
      let UserDetail = {
        Status: ProjectStatus,
        UserId: this.props.userData.userId,
      };
      let uri = `${config.API_HOST_URL}/api/projects/GetProjectByFilter`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => {
              if (data.success) {
                if (this.mounted) {
                  let responseData = data.data;
                  console.log("RegressionList", responseData[0]?.regression);
                  this.setState({
                    ProjectList: responseData,
                    RegressionList: responseData[0]?.regression,
                    loading: false,
                  });
                }
              } else {
                this.setState({
                  ProjectList: [],
                  RegressionList: [],
                  loading: false,
                });
                let errors = {};
                errors["message"] = data.message;
                if (this.mounted) {
                  this.setState({
                    errors: errors,
                  });
                }
              }
            });
          } else {
            return response.json().then((data) => {
              let errors = {};
              errors["message"] = data.message;
              if (this.mounted) {
                this.setState({
                  errors: errors,
                  loading: false,
                });
              }
            });
          }
        })
        .catch((error) => {
          if (this.mounted) {
            console.log(error);
            this.setState({
              errorMsg: error,
              IsLoader: false,
            });
            this.setState({ loading: false });
          }
        });
    }
  };

  handleDownloadFile = (FileName) => {
    let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/Goa%20Pilot%20Project/${FileName}`;
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = FileName;
        a.click();
      });
    });
  };

  // toggleDropdown = () => {
  //   this.dropdown = this.dropdown || document.querySelector(".filter_dropdown");
  //   this.setState((prevState) => ({
  //     isDropdownOpen: !prevState.isDropdownOpen,
  //   }));
  // };

  selectValue = (value) => {
    this.setState({
      selectedValue: value,
      isOpen: false,
    });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
    this.dropdown = this.dropdown || document.querySelector(".filter_dropdown");
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  handleDocumentClick = (e) => {
    if (
      this.dropdown &&
      !this.dropdown.contains(e.target) &&
      e.target !== this.dropdownToggle
    ) {
      this.setState({ isDropdownOpen: false });
    }
  };
  Loader = () => {
    return (
      <div className="spinner-loader">
        <div className="spinner"></div>
      </div>
    );
  };

  render() {
    const { activeTab } = this.state;
    const { isOpen } = this.state;
    const { loading } = this.state;
    return (
      <>
        {loading && (
          <div className="loader-overlay loaderprojectspg_cont">
            <Loader />
          </div>
        )}
        <div id="headbottom_cont" className="headbottom_cont">
          <ul className="headbottom_content">
            <li className="heading_cont">
              Projects{" "}
              <span className="projects_count">
                ({this.state.AllProjectCounts})
              </span>
            </li>
            {/* <li className="updatetxt_cont">Last updated 3 May, 2021 9:00:00 AM IST<i className="fa fa-repeat"></i></li> */}
            {/* <li className="updatetxt_cont">Last updated {this.state.LastUpdatedDateTime}<i className="fa fa-repeat"></i></li> */}
            <li className="newfilters_cont">
              <div
                className="filter_dropdown"
                ref={(ref) => (this.dropdownToggle = ref)}
              >
                <button
                  className="dropdown-toggle"
                  onClick={this.toggleDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                  >
                    <path
                      d="M7 12V10H11V12H7ZM3 7V5H15V7H3ZM0 2V0H18V2H0Z"
                      fill="white"
                    />
                  </svg>
                </button>
                {isOpen && (
                  <ul className="filterdropdown_list">
                    <li
                      className={
                        this.state.ActiveColorState === "All" ? "active" : ""
                      }
                    >
                      <a value="All" onClick={this.FilterProject}>
                        All <span>({this.state.AllProjectCounts})</span>
                      </a>
                    </li>
                    <li
                      className={
                        this.state.ActiveColorState === "Verified"
                          ? "active"
                          : ""
                      }
                    >
                      <a value="Verified" onClick={this.FilterProject}>
                        Verified{" "}
                        <span>({this.state.VerifiedProjectCounts})</span>
                      </a>
                    </li>
                    <li
                      className={
                        this.state.ActiveColorState === "Rejected"
                          ? "active"
                          : ""
                      }
                    >
                      <a value="Rejected" onClick={this.FilterProject}>
                        Rejected{" "}
                        <span>({this.state.RejectedProjectCount})</span>
                      </a>
                    </li>
                    <li
                      className={
                        this.state.ActiveColorState === "Unverified"
                          ? "active"
                          : ""
                      }
                    >
                      <a value="Unverified" onClick={this.FilterProject}>
                        Unverified{" "}
                        <span>({this.state.UnverifiedProjectCounts})</span>
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </li>
            <li className="addprobtn_container">
              {/* <AddProject parentMethod={this.GetProjectCounts} onClick={this.hideAddProjectValidation} /> */}
              <AddNewProject parentMethod={this.GetProjectCounts} />
            </li>
          </ul>
          <ul className="viewbtn_cont">
            <li
              className={
                activeTab === "GridView" ? "viewbtnli active" : "viewbtnli"
              }
            >
              <a
                className="viewbtn grid_btn"
                onClick={() => this.handleTabChange("GridView")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M1 4.33204C1 2.76109 1 1.97562 1.4887 1.4887C1.97473 1 2.7602 1 4.33204 1C5.90298 1 6.68845 1 7.17538 1.4887C7.66407 1.97562 7.66407 2.76109 7.66407 4.33204C7.66407 5.90298 7.66407 6.68845 7.17538 7.17538C6.68845 7.66408 5.90298 7.66408 4.33204 7.66408C2.76109 7.66408 1.97562 7.66408 1.4887 7.17538C1 6.68934 1 5.90387 1 4.33204ZM1 13.668C1 12.097 1 11.3115 1.4887 10.8246C1.97562 10.3359 2.76109 10.3359 4.33204 10.3359C5.90298 10.3359 6.68845 10.3359 7.17538 10.8246C7.66407 11.3115 7.66407 12.097 7.66407 13.668C7.66407 15.2389 7.66407 16.0244 7.17538 16.5113C6.68845 17 5.90298 17 4.33204 17C2.76109 17 1.97562 17 1.4887 16.5113C1 16.0253 1 15.2389 1 13.668ZM10.3297 4.33204C10.3297 2.76109 10.3297 1.97562 10.8184 1.4887C11.3053 1 12.0908 1 13.6617 1C15.2327 1 16.0182 1 16.5051 1.4887C16.9938 1.97562 16.9938 2.76109 16.9938 4.33204C16.9938 5.90298 16.9938 6.68845 16.5051 7.17538C16.0182 7.66408 15.2327 7.66408 13.6617 7.66408C12.0908 7.66408 11.3053 7.66408 10.8184 7.17538C10.3297 6.68845 10.3297 5.90298 10.3297 4.33204ZM10.3297 13.668C10.3297 12.097 10.3297 11.3115 10.8184 10.8246C11.3053 10.3359 12.0908 10.3359 13.6617 10.3359C15.2327 10.3359 16.0182 10.3359 16.5051 10.8246C16.9938 11.3115 16.9938 12.097 16.9938 13.668C16.9938 15.2389 16.9938 16.0244 16.5051 16.5113C16.0182 17 15.2327 17 13.6617 17C12.0908 17 11.3053 17 10.8184 16.5113C10.3297 16.0244 10.3297 15.2389 10.3297 13.668Z"
                    stroke="#AAB7C2"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </li>
            <li
              className={
                activeTab === "ListView" ? "viewbtnli active" : "viewbtnli"
              }
            >
              <a
                className="viewbtn list_btn"
                onClick={() => this.handleTabChange("ListView")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="14"
                  viewBox="0 0 19 14"
                  fill="none"
                >
                  <path
                    d="M18.4211 7C18.4211 7.29313 18.3046 7.57426 18.0973 7.78154C17.8901 7.98882 17.6089 8.10526 17.3158 8.10526H1.10526C0.812129 8.10526 0.531001 7.98882 0.323724 7.78154C0.116447 7.57426 0 7.29313 0 7C0 6.70687 0.116447 6.42574 0.323724 6.21846C0.531001 6.01118 0.812129 5.89474 1.10526 5.89474H17.3158C17.6089 5.89474 17.8901 6.01118 18.0973 6.21846C18.3046 6.42574 18.4211 6.70687 18.4211 7ZM1.10526 2.21053H17.3158C17.6089 2.21053 17.8901 2.09408 18.0973 1.8868C18.3046 1.67953 18.4211 1.3984 18.4211 1.10526C18.4211 0.812129 18.3046 0.531001 18.0973 0.323724C17.8901 0.116447 17.6089 0 17.3158 0H1.10526C0.812129 0 0.531001 0.116447 0.323724 0.323724C0.116447 0.531001 0 0.812129 0 1.10526C0 1.3984 0.116447 1.67953 0.323724 1.8868C0.531001 2.09408 0.812129 2.21053 1.10526 2.21053ZM17.3158 11.7895H1.10526C0.812129 11.7895 0.531001 11.9059 0.323724 12.1132C0.116447 12.3205 0 12.6016 0 12.8947C0 13.1879 0.116447 13.469 0.323724 13.6763C0.531001 13.8836 0.812129 14 1.10526 14H17.3158C17.6089 14 17.8901 13.8836 18.0973 13.6763C18.3046 13.469 18.4211 13.1879 18.4211 12.8947C18.4211 12.6016 18.3046 12.3205 18.0973 12.1132C17.8901 11.9059 17.6089 11.7895 17.3158 11.7895Z"
                    fill="#AAB7C2"
                  />
                </svg>
              </a>
            </li>
            <li
              className={
                activeTab === "MapView" ? "viewbtnli active" : "viewbtnli"
              }
            >
              <a
                className="viewbtn mapview_btn"
                onClick={() => this.handleTabChange("MapView")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                >
                  <path
                    d="M8 10C8.55 10 9.02083 9.80417 9.4125 9.4125C9.80417 9.02083 10 8.55 10 8C10 7.45 9.80417 6.97917 9.4125 6.5875C9.02083 6.19583 8.55 6 8 6C7.45 6 6.97917 6.19583 6.5875 6.5875C6.19583 6.97917 6 7.45 6 8C6 8.55 6.19583 9.02083 6.5875 9.4125C6.97917 9.80417 7.45 10 8 10ZM8 17.35C10.0333 15.4833 11.5417 13.7875 12.525 12.2625C13.5083 10.7375 14 9.38333 14 8.2C14 6.38333 13.4208 4.89583 12.2625 3.7375C11.1042 2.57917 9.68333 2 8 2C6.31667 2 4.89583 2.57917 3.7375 3.7375C2.57917 4.89583 2 6.38333 2 8.2C2 9.38333 2.49167 10.7375 3.475 12.2625C4.45833 13.7875 5.96667 15.4833 8 17.35ZM8 20C5.31667 17.7167 3.3125 15.5958 1.9875 13.6375C0.6625 11.6792 0 9.86667 0 8.2C0 5.7 0.804167 3.70833 2.4125 2.225C4.02083 0.741667 5.88333 0 8 0C10.1167 0 11.9792 0.741667 13.5875 2.225C15.1958 3.70833 16 5.7 16 8.2C16 9.86667 15.3375 11.6792 14.0125 13.6375C12.6875 15.5958 10.6833 17.7167 8 20Z"
                    fill="#AAB7C2"
                  />
                </svg>
              </a>
            </li>
            <li className="mapreseticon_cont">
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                >
                  <path
                    d="M11 14H16V19M7.00003 6H2.00003V1M16.418 7.003C15.8574 5.61519 14.9186 4.41251 13.7084 3.53166C12.4983 2.65082 11.0653 2.12714 9.5723 2.02016C8.07933 1.91318 6.5863 2.22718 5.26291 2.92648C3.93952 3.62577 2.83886 4.68231 2.08603 5.976M1.58203 12.997C2.14283 14.3846 3.0816 15.587 4.29166 16.4676C5.50172 17.3483 6.93455 17.8718 8.42732 17.9788C9.92009 18.0858 11.4129 17.7719 12.7362 17.0728C14.0595 16.3737 15.1601 15.3174 15.913 14.024"
                    stroke="#464545"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div
          id="projects_container"
          className={`projects_container ${
            loading ? "loadingprojects_cont" : ""
          }`}
        >
          {/* <div className="projectsinner_wrap">
            <ul className="filterlist">
              <li
                className={
                  this.state.ActiveColorState === "All" ? "active" : ""
                }
              >
                <a value="All" onClick={this.FilterProject}>
                  All ({this.state.AllProjectCounts})
                </a>
              </li>
              <li
                className={
                  this.state.ActiveColorState === "Verified" ? "active" : ""
                }
              >
                <a value="Verified" onClick={this.FilterProject}>
                  Verified ({this.state.VerifiedProjectCounts})
                </a>
              </li>
              <li
                className={
                  this.state.ActiveColorState === "Rejected" ? "active" : ""
                }
              >
                <a value="Rejected" onClick={this.FilterProject}>
                  Rejected ({this.state.RejectedProjectCount})
                </a>
              </li>
              <li
                className={
                  this.state.ActiveColorState === "Unverified" ? "active" : ""
                }
              >
                <a value="Unverified" onClick={this.FilterProject}>
                  Unverified ({this.state.UnverifiedProjectCounts})
                </a>
              </li>
            </ul>
          </div> */}
          {activeTab === "MapView" && (
            <div className="viewmap projectpg_mapcont">
              {
                <ProjectMapView
                  GoogleMapApiKey={this.state.GoogleMapApiKey}
                  ProjectList={this.state.ProjectList}
                  ProjectDetailData={this.state.ProjectDetailData}
                  ProjectStatus={this.state.ProjectStatus}
                  UserId={this.props.userData.userId}
                ></ProjectMapView>
              }
            </div>
          )}
          {activeTab === "GridView" && (
            <>
              <ul className="projects_list" id="projects_list">
                {this.state.ProjectList.map((item, index) => (
                  <li key={item.projectId}>
                    {/* {item.projectId==='bf33b663-d37f-4be7-b886-a0c06f120eac'? <span> <Button onClick={() => this.handleDownloadFile(item.projectReportFile)}>Download Report </Button></span>:""} */}
                    <div className="projectlistcards_parenttop">
                      <div className="projectlistcards_topcont">
                        <div className="projectlistcards_topleft">
                          <div className="prolistcards_pronmenvfycont">
                            <span className="project-name">
                              <span
                                className="prolist_name"
                                onClick={this.projectdetailclickNew(
                                  item.projectId,
                                  index,
                                  item.status,
                                  item
                                )}
                                style={{ cursor: "pointer" }}
                              >
                                {item.projectName}
                              </span>
                            </span>

                            <div
                              className={
                                item.status == "Verified"
                                  ? "prolistverified_btn"
                                  : "prolistverified_btn prolistunverified_btn"
                              }
                            >
                              {/* <span>{item.status}</span> */}
                              {item.status == "Verified" ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="15"
                                    viewBox="0 0 12 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M5.2125 10.1625L9.45 5.925L8.38125 4.85625L5.2125 8.025L3.6375 6.45L2.56875 7.51875L5.2125 10.1625ZM6 15C4.2625 14.5625 2.82813 13.5656 1.69688 12.0094C0.565625 10.4531 0 8.725 0 6.825V2.25L6 0L12 2.25V6.825C12 8.725 11.4344 10.4531 10.3031 12.0094C9.17188 13.5656 7.7375 14.5625 6 15Z"
                                      fill="#50B041"
                                    />
                                  </svg>
                                  <div className="icon_txtveriftedhovr">
                                    Verified
                                  </div>
                                </>
                              ) : (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                  >
                                    <g clip-path="url(#clip0_2468_4267)">
                                      <path
                                        d="M11.1756 12.9348C10.1826 13.9308 8.955 14.6658 7.59 15.0006C4.068 14.1378 1.4532 10.605 1.4532 6.8178V3.21L0 1.7586L1.152 0.606L14.2944 13.749L13.143 14.901L11.1756 12.9348ZM6.723 8.4816L6.225 8.9796L4.4628 7.2168L3.498 8.1816L6.225 10.9086L7.6878 9.4458L6.723 8.4816ZM9.9666 7.1676L13.014 10.2138C13.4718 9.1566 13.725 7.9992 13.725 6.8178V2.727L7.59 0L4.2726 1.4736L9.0024 6.2022L10.7154 4.4892L11.6802 5.4546L9.9666 7.1676Z"
                                        fill="#B45555"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_2468_4267">
                                        <rect
                                          width="14.2944"
                                          height="15"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <div className="icon_txtveriftedhovr">
                                    Unverified
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="prolisttime_cont">
                            <span>
                              <i className="icon icon-location-pin"></i>
                              {item.location}
                            </span>
                          </div>
                        </div>
                        <div className="projectlistcards_topright">
                          <a
                            className="downloadrept_icon"
                            // onClick={() =>
                            //   this.handleDownloadFile(item.projectReportFile)
                            // }
                            href={
                              config.BASE_URL +
                              "/projectsummaryreport/:" +
                              item.projectId +
                              "/:" +
                              index +
                              1
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
                                stroke="#6C6C6C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5.25 7.5L9 11.25L12.75 7.5"
                                stroke="#6C6C6C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 11.25V2.25"
                                stroke="#6C6C6C"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div className="icon_txtveriftedhovr">
                              Download Report
                            </div>
                          </a>
                          <a
                            className="ecosysdash_link"
                            onClick={this.goToPartnership(
                              item.projectId,
                              item.status,
                              index
                            )}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M4.09942 15.6C3.62017 15.6 3.21718 15.4367 2.89046 15.1099C2.56375 14.7832 2.40039 14.3802 2.40039 13.901V4.09905C2.40039 3.6198 2.56375 3.21681 2.89046 2.89009C3.21718 2.56337 3.62017 2.40002 4.09942 2.40002H9.25856C9.46481 2.40002 9.6195 2.46525 9.72262 2.59571C9.82575 2.72617 9.87731 2.86776 9.87731 3.02047C9.87731 3.17318 9.82613 3.3142 9.72377 3.44353C9.62142 3.57285 9.47115 3.63752 9.27298 3.63752H4.09942C3.98405 3.63752 3.87828 3.68559 3.78212 3.78174C3.68597 3.8779 3.63789 3.98367 3.63789 4.09905V13.901C3.63789 14.0164 3.68597 14.1221 3.78212 14.2183C3.87828 14.3144 3.98405 14.3625 4.09942 14.3625H13.9014C14.0167 14.3625 14.1225 14.3144 14.2187 14.2183C14.3148 14.1221 14.3629 14.0164 14.3629 13.901V8.66974C14.3629 8.46349 14.4281 8.3088 14.5586 8.20567C14.689 8.10255 14.8306 8.05099 14.9833 8.05099C15.1361 8.05099 15.2771 8.10255 15.4064 8.20567C15.5357 8.3088 15.6004 8.46349 15.6004 8.66974V13.901C15.6004 14.3802 15.437 14.7832 15.1103 15.1099C14.7836 15.4367 14.3806 15.6 13.9014 15.6H4.09942ZM7.25664 9.89426V9.1356C7.25664 8.9062 7.30238 8.68455 7.39385 8.47065C7.48532 8.25675 7.60929 8.07215 7.76578 7.91685L13.8898 1.7928C14.0225 1.66011 14.1606 1.56852 14.3043 1.51803C14.4479 1.46756 14.5963 1.44232 14.7495 1.44232C14.8994 1.44232 15.0525 1.47068 15.2088 1.52741C15.365 1.58415 15.5071 1.67405 15.635 1.79713L16.1499 2.28752C16.2797 2.4202 16.3747 2.56549 16.4348 2.72338C16.4949 2.88127 16.5249 3.04088 16.5249 3.20223C16.5249 3.36357 16.4978 3.51793 16.4436 3.6653C16.3894 3.81268 16.2949 3.9515 16.16 4.08176L10.0114 10.2491C9.8528 10.4077 9.66706 10.5298 9.45423 10.6154C9.24142 10.701 9.02091 10.7438 8.7927 10.7438H8.10615C7.86961 10.7438 7.66889 10.6613 7.50399 10.4964C7.33909 10.3315 7.25664 10.1308 7.25664 9.89426ZM8.49414 9.50627H8.97587L13.7499 4.75099L13.509 4.49137L13.2206 4.22165L8.49414 8.9481V9.50627Z"
                                fill="#6C6C6C"
                              />
                            </svg>
                            <div className="icon_txtveriftedhovr">
                              Edit Project Details
                            </div>
                          </a>
                        </div>
                      </div>
                      {item.isForestHealthy === true ? (
                        <div className="typeofverify_forest hlthyforbtn">
                          Forest is Healthy
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M13.5 15.75H6V6L11.25 0.75L12.1875 1.6875C12.275 1.775 12.347 1.89375 12.4035 2.04375C12.46 2.19375 12.488 2.3375 12.4875 2.475V2.7375L11.6625 6H15.75C16.15 6 16.5 6.15 16.8 6.45C17.1 6.75 17.25 7.1 17.25 7.5V9C17.25 9.0875 17.2407 9.18125 17.2222 9.28125C17.2037 9.38125 17.1755 9.475 17.1375 9.5625L14.8875 14.85C14.775 15.1 14.5875 15.3125 14.325 15.4875C14.0625 15.6625 13.7875 15.75 13.5 15.75ZM4.5 6V15.75H1.5V6H4.5Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="typeofverify_forest unhlthyforbtn">
                          Forest is UnHealthy
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M4.5 2.25H12V12L6.75 17.25L5.8125 16.3125C5.725 16.225 5.653 16.1062 5.5965 15.9562C5.54 15.8062 5.512 15.6625 5.5125 15.525V15.2625L6.3375 12H2.25C1.85 12 1.5 11.85 1.2 11.55C0.9 11.25 0.75 10.9 0.75 10.5V9C0.75 8.9125 0.75925 8.81875 0.77775 8.71875C0.79625 8.61875 0.8245 8.525 0.8625 8.4375L3.1125 3.15C3.225 2.9 3.4125 2.6875 3.675 2.5125C3.9375 2.3375 4.2125 2.25 4.5 2.25ZM13.5 12V2.25H16.5V12H13.5Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      )}
                    </div>

                    <a
                      onClick={this.projectdetailclickNew(
                        item.projectId,
                        index,
                        item.status,
                        item
                      )}
                      onClickCapture={this.projdetonload}
                    >
                      {/* <div className="prolist_no">Project <b>{index + 1}</b></div> */}
                      <div className="prolist_det"></div>
                      {/* Added by Shobana - Disturbance and Opportunity Calculation start */}
                      <div className="prolistinner_list opportunity">
                        <div className="prolist_tree">
                          <img src={opportunity} alt="trees plant" />
                        </div>
                        <div className="prolist_treedet">
                          <span style={{ color: item.opportunityColorValue }}>
                            Opportunity Score
                          </span>
                          <span
                            style={{
                              color: item.opportunityColorValue,
                              "font-weight": "bold",
                            }}
                          >
                            {item.opportunityScore}
                          </span>
                          <ul className="ScoreCard">
                            <h5 className="cardTitle">
                              Following parameters are considered.
                            </h5>
                            {item.disturbanceOpportunityScore !== null
                              ? item.disturbanceOpportunityScore.map(
                                  (opportunityScore) => (
                                    <div>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Project Type
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.projectType}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Project Period
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.projectPeriod}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          State
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.district}
                                          </span>
                                          <span>-</span>
                                          <span>{opportunityScore.state}</span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Land Ownership
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.ownerShip}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          No. of Owners
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.noOfOwner}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Site Ownership Disputed?
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.ownerDisputed}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Site Encroachment
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.siteEncroachment}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Ecosystem
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.ecosystemType}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Area Impacted(Hectares)
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.areaImpacted}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Disturbance Level
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.disturbanceLevel}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Scope
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>{opportunityScore.scope}</span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Enterprise Type
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.enterpriseType}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Complexity-Social
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.complexitySocial}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Complexity-Objectives
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {
                                              opportunityScore.complexityObjectives
                                            }
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Risk
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.overAllRisk}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Cost Per Hectare
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.costPerHectare}
                                          </span>
                                        </span>
                                      </li>
                                    </div>
                                  )
                                )
                              : null}
                          </ul>
                        </div>
                      </div>
                      <div className="prolistinner_list disturbanceImpact">
                        <div className="prolist_tree">
                          <img src={disturbanceImpact} alt="trees plant" />
                        </div>
                        <div className="prolist_treedet">
                          <span style={{ color: item.disturbanceColorValue }}>
                            Disturbance Impact Level
                          </span>
                          <span
                            style={{
                              color: item.disturbanceColorValue,
                              "font-weight": "bold",
                            }}
                          >
                            {item.disturbanceImpactScore}
                          </span>
                          <div className="tooltiptext">
                            This score indicates the net destruction caused by
                            the disturbance to the ecosystem. It takes into
                            account the difference of severity level on
                            different areas impacted within the project site.
                          </div>
                        </div>
                      </div>
                      {/* Added by Shobana - Disturbance and Opportunity Calculation start */}
                      <div className="prolistinner_list">
                        <div className="prolist_tree">
                          <img src={treesplantnew} alt="trees plant" />
                        </div>
                        <div className="prolist_treedet">
                          <span className="prolisttreedet_lbl">
                            Trees Planted Till Date
                          </span>
                          <span className="prolisttreedet_val">
                            {item.treePlantedTillDate}{" "}
                            <span class="prolisttreedet_valsource">
                              {item?.treeBySourceUsed !== "None"
                                ? `(${item.treeBySourceUsed})`
                                : ""}
                            </span>
                          </span>
                        </div>
                        {/* <div className="prolist_progress">
                      <div className="curvtext">
                        <span className="c1">f</span>
                        <span className="c2">o</span>
                        <span className="c3">r</span>
                        <span className="c4">e</span>
                        <span className="c5">s</span>
                        <span className="c6">t</span>
                        <span className="c7">h</span>
                        <span className="c8">e</span>
                        <span className="c9">a</span>
                        <span className="c10">l</span>
                        <span className="c11">t</span>
                        <span className="c12">h</span>
                        <span className="c13">s</span>
                        <span className="c14">c</span>
                        <span className="c15">o</span>
                        <span className="c16">r</span>
                        <span className="c17">e</span>
                      </div>

                      <CircularProgressbar
                        width={150}
                        height={150}
                        value={item.forestHealthyPercentage}
                        text={`${Number(item.forestHealthyPercentage).toFixed(
                          1
                        )}%`}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          textColor: "#333",
                          pathColor:
                            item.isForestHealthy == true
                              ? "#95C13D"
                              : "#B54E56",
                          trailColor: "#D9D9D9",
                        })}
                      />
                    </div> */}
                      </div>

                      {/* <div className="prolistinner_list coemicont">
                      <div className="prolist_tree">
                        <img src={co2emission} alt="CO2 emission" />
                      </div>
                      <div className="prolist_treedet">
                        <span className="prolisttreedet_lbl">
                          Carbon Sequestered - Till Now
                        </span>
                        <span className="prolisttreedet_val">
                          {(item.co2Nutrilized * 1000).toFixed(2)} Kg
                        </span>
                      </div>
                    </div> */}

                      {item?.amountRaised !== "0" && (
                        <div className="prolistinner_list fundraisedlistiutem_cont">
                          <div className="prolist_tree">
                            <img src={amtraised} alt="trees plant" />
                          </div>
                          <div className="prolist_treedet">
                            <span className="prolisttreedet_lbl">
                              Amount Raised
                            </span>
                            <span className="prolisttreedet_val">
                              RS. {Math.ceil(item?.amountRaised / 100000)} L
                            </span>
                            <span className="totalfndrsedamt_cont">
                              ₹ {Math.ceil(item?.fundraiserGoal / 100000)} L
                            </span>
                            <div className="prolist_progress">
                              <Progress
                                value={
                                  (item?.amountRaised / item?.fundraiserGoal) *
                                  100
                                }
                                size={ProgressSize.sm}
                                width="100%"
                                fill="#333"
                                path="#fff"
                              />
                            </div>
                          </div>

                          {/* <CircularProgressbar
                        width={150}
                        height={150}
                        value={
                          (item.amountRaised === "0" || item.amountRaised === ""
                            ? 0
                            : item.amountRaised / item.fundraiserGoal) * 100
                        }
                        text={
                          item.amountRaised === "0" || item.amountRaised === ""
                            ? 0
                            : `${Number(
                                (item.amountRaised / item.fundraiserGoal) * 100
                              ).toFixed(1)}%`
                        }
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          textColor: "#333",
                          pathColor: "#95C13D",
                          trailColor: "#D9D9D9",
                        })}
                      /> */}
                        </div>
                      )}
                      {item?.area && (
                        <div className="prolistinner_list">
                          <div className="prolist_tree">
                            <img src={disturbanceImpact} alt="trees plant" />
                          </div>
                          <div className="prolist_treedet">
                            <span className="prolisttreedet_lbl">
                              Land Area(in acres)
                            </span>{" "}
                            <span className="prolisttreedet_val">
                              {" "}
                              {/* {(item.landareasqm !== ""
                            ? item.landareasqm * 0.000247105
                            : 0
                          ).toFixed(2)} */}
                              {typeof item?.area === "number"
                                ? item?.area.toFixed(2)
                                : parseFloat(item?.area).toFixed(2)}{" "}
                            </span>
                          </div>
                        </div>
                      )}
                      {/* <div className="campaign_txt">Campaign closes in 12 months 10 days</div> */}
                    </a>
                    <div>
                      {this.state.ProjectList[0]?.regression?.filter(
                        (reg) => reg.projectId === item.projectId
                      )?.length > 0 && (
                        <table>
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Estimated Range</th>
                              <th>Average</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ProjectList[0]?.regression
                              ?.filter(
                                (reg) => reg.projectId === item.projectId
                              )
                              ?.map((reg, i) => (
                                <tr key={i}>
                                  <td>{reg?.type}</td>
                                  <td>{reg?.estimatedRange}</td>
                                  <td>{reg?.average}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
          {activeTab === "ListView" && (
            <>
              <ul className="projects_list projlistview" id="projects_list">
                {this.state.ProjectList.map((item, index) => (
                  <li key={item.projectId}>
                    {/* {item.projectId==='bf33b663-d37f-4be7-b886-a0c06f120eac'? <span> <Button onClick={() => this.handleDownloadFile(item.projectReportFile)}>Download Report </Button></span>:""} */}
                    <div className="projectlistcards_parenttop">
                      <div className="projectlistcards_topcont">
                        <div className="projectlistcards_topleft">
                          <div className="prolistcards_pronmenvfycont">
                            <span className="project-name">
                              <span
                                className="prolist_name"
                                onClick={this.projectdetailclickNew(
                                  item.projectId,
                                  index,
                                  item.status,
                                  item
                                )}
                                style={{ cursor: "pointer" }}
                              >
                                {item.projectName}
                              </span>
                            </span>

                            <div
                              className={
                                item.status == "Verified"
                                  ? "prolistverified_btn"
                                  : "prolistverified_btn prolistunverified_btn"
                              }
                            >
                              {/* <span>{item.status}</span> */}
                              {item.status == "Verified" ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="15"
                                    viewBox="0 0 12 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M5.2125 10.1625L9.45 5.925L8.38125 4.85625L5.2125 8.025L3.6375 6.45L2.56875 7.51875L5.2125 10.1625ZM6 15C4.2625 14.5625 2.82813 13.5656 1.69688 12.0094C0.565625 10.4531 0 8.725 0 6.825V2.25L6 0L12 2.25V6.825C12 8.725 11.4344 10.4531 10.3031 12.0094C9.17188 13.5656 7.7375 14.5625 6 15Z"
                                      fill="#50B041"
                                    />
                                  </svg>
                                  <div className="icon_txtveriftedhovr">
                                    Verified
                                  </div>
                                </>
                              ) : (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                  >
                                    <g clip-path="url(#clip0_2468_4267)">
                                      <path
                                        d="M11.1756 12.9348C10.1826 13.9308 8.955 14.6658 7.59 15.0006C4.068 14.1378 1.4532 10.605 1.4532 6.8178V3.21L0 1.7586L1.152 0.606L14.2944 13.749L13.143 14.901L11.1756 12.9348ZM6.723 8.4816L6.225 8.9796L4.4628 7.2168L3.498 8.1816L6.225 10.9086L7.6878 9.4458L6.723 8.4816ZM9.9666 7.1676L13.014 10.2138C13.4718 9.1566 13.725 7.9992 13.725 6.8178V2.727L7.59 0L4.2726 1.4736L9.0024 6.2022L10.7154 4.4892L11.6802 5.4546L9.9666 7.1676Z"
                                        fill="#B45555"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_2468_4267">
                                        <rect
                                          width="14.2944"
                                          height="15"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <div className="icon_txtveriftedhovr">
                                    Unverified
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="prolisttime_cont">
                            <span>
                              <i className="icon icon-location-pin"></i>
                              {item.location}
                            </span>
                          </div>
                        </div>
                        <div className="projectlistcards_topright">
                          {item.isShowLayersOnMap === true ? (
                            <a
                              className="downloadrept_icon"
                              // onClick={() =>
                              //   this.handleDownloadFile(item.projectReportFile)
                              // }
                              href={
                                config.BASE_URL +
                                "/projectsummaryreport/:" +
                                item.projectId +
                                "/:" +
                                index +
                                1
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
                                  stroke="#6C6C6C"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M5.25 7.5L9 11.25L12.75 7.5"
                                  stroke="#6C6C6C"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9 11.25V2.25"
                                  stroke="#6C6C6C"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <div className="icon_txtveriftedhovr">
                                Download Report
                              </div>
                            </a>
                          ) : (
                            ""
                          )}
                          <a
                            className="ecosysdash_link"
                            onClick={this.goToPartnership(
                              item.projectId,
                              item.status,
                              index
                            )}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M4.09942 15.6C3.62017 15.6 3.21718 15.4367 2.89046 15.1099C2.56375 14.7832 2.40039 14.3802 2.40039 13.901V4.09905C2.40039 3.6198 2.56375 3.21681 2.89046 2.89009C3.21718 2.56337 3.62017 2.40002 4.09942 2.40002H9.25856C9.46481 2.40002 9.6195 2.46525 9.72262 2.59571C9.82575 2.72617 9.87731 2.86776 9.87731 3.02047C9.87731 3.17318 9.82613 3.3142 9.72377 3.44353C9.62142 3.57285 9.47115 3.63752 9.27298 3.63752H4.09942C3.98405 3.63752 3.87828 3.68559 3.78212 3.78174C3.68597 3.8779 3.63789 3.98367 3.63789 4.09905V13.901C3.63789 14.0164 3.68597 14.1221 3.78212 14.2183C3.87828 14.3144 3.98405 14.3625 4.09942 14.3625H13.9014C14.0167 14.3625 14.1225 14.3144 14.2187 14.2183C14.3148 14.1221 14.3629 14.0164 14.3629 13.901V8.66974C14.3629 8.46349 14.4281 8.3088 14.5586 8.20567C14.689 8.10255 14.8306 8.05099 14.9833 8.05099C15.1361 8.05099 15.2771 8.10255 15.4064 8.20567C15.5357 8.3088 15.6004 8.46349 15.6004 8.66974V13.901C15.6004 14.3802 15.437 14.7832 15.1103 15.1099C14.7836 15.4367 14.3806 15.6 13.9014 15.6H4.09942ZM7.25664 9.89426V9.1356C7.25664 8.9062 7.30238 8.68455 7.39385 8.47065C7.48532 8.25675 7.60929 8.07215 7.76578 7.91685L13.8898 1.7928C14.0225 1.66011 14.1606 1.56852 14.3043 1.51803C14.4479 1.46756 14.5963 1.44232 14.7495 1.44232C14.8994 1.44232 15.0525 1.47068 15.2088 1.52741C15.365 1.58415 15.5071 1.67405 15.635 1.79713L16.1499 2.28752C16.2797 2.4202 16.3747 2.56549 16.4348 2.72338C16.4949 2.88127 16.5249 3.04088 16.5249 3.20223C16.5249 3.36357 16.4978 3.51793 16.4436 3.6653C16.3894 3.81268 16.2949 3.9515 16.16 4.08176L10.0114 10.2491C9.8528 10.4077 9.66706 10.5298 9.45423 10.6154C9.24142 10.701 9.02091 10.7438 8.7927 10.7438H8.10615C7.86961 10.7438 7.66889 10.6613 7.50399 10.4964C7.33909 10.3315 7.25664 10.1308 7.25664 9.89426ZM8.49414 9.50627H8.97587L13.7499 4.75099L13.509 4.49137L13.2206 4.22165L8.49414 8.9481V9.50627Z"
                                fill="#6C6C6C"
                              />
                            </svg>
                            <div className="icon_txtveriftedhovr">
                              Edit Project Details
                            </div>
                          </a>
                        </div>
                      </div>
                      {item.isForestHealthy === true ? (
                        <div className="typeofverify_forest hlthyforbtn">
                          Forest is Healthy
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M13.5 15.75H6V6L11.25 0.75L12.1875 1.6875C12.275 1.775 12.347 1.89375 12.4035 2.04375C12.46 2.19375 12.488 2.3375 12.4875 2.475V2.7375L11.6625 6H15.75C16.15 6 16.5 6.15 16.8 6.45C17.1 6.75 17.25 7.1 17.25 7.5V9C17.25 9.0875 17.2407 9.18125 17.2222 9.28125C17.2037 9.38125 17.1755 9.475 17.1375 9.5625L14.8875 14.85C14.775 15.1 14.5875 15.3125 14.325 15.4875C14.0625 15.6625 13.7875 15.75 13.5 15.75ZM4.5 6V15.75H1.5V6H4.5Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="typeofverify_forest unhlthyforbtn">
                          Forest is UnHealthy
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M4.5 2.25H12V12L6.75 17.25L5.8125 16.3125C5.725 16.225 5.653 16.1062 5.5965 15.9562C5.54 15.8062 5.512 15.6625 5.5125 15.525V15.2625L6.3375 12H2.25C1.85 12 1.5 11.85 1.2 11.55C0.9 11.25 0.75 10.9 0.75 10.5V9C0.75 8.9125 0.75925 8.81875 0.77775 8.71875C0.79625 8.61875 0.8245 8.525 0.8625 8.4375L3.1125 3.15C3.225 2.9 3.4125 2.6875 3.675 2.5125C3.9375 2.3375 4.2125 2.25 4.5 2.25ZM13.5 12V2.25H16.5V12H13.5Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      )}
                    </div>

                    <a
                      onClick={this.projectdetailclickNew(
                        item.projectId,
                        index,
                        item.status,
                        item
                      )}
                      onClickCapture={this.projdetonload}
                    >
                      {/* <div className="prolist_no">Project <b>{index + 1}</b></div> */}
                      <div className="prolist_det"></div>
                      {/* Added by Shobana - Disturbance and Opportunity Calculation start */}
                      <div className="prolistinner_list opportunity">
                        <div className="prolist_tree">
                          <img src={opportunity} alt="trees plant" />
                        </div>
                        <div className="prolist_treedet">
                          <span style={{ color: item.opportunityColorValue }}>
                            Opportunity Score
                          </span>
                          <span
                            style={{
                              color: item.opportunityColorValue,
                              "font-weight": "bold",
                            }}
                          >
                            {item.opportunityScore}
                          </span>
                          <ul className="ScoreCard">
                            <h5 className="cardTitle">
                              Following parameters are considered.
                            </h5>
                            {item.disturbanceOpportunityScore !== null
                              ? item.disturbanceOpportunityScore.map(
                                  (opportunityScore) => (
                                    <div>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Project Type
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.projectType}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Project Period
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.projectPeriod}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          State
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.district}
                                          </span>
                                          <span>-</span>
                                          <span>{opportunityScore.state}</span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Land Ownership
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.ownerShip}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          No. of Owners
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.noOfOwner}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Site Ownership Disputed?
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.ownerDisputed}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Site Encroachment
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.siteEncroachment}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Ecosystem
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.ecosystemType}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Area Impacted(Hectares)
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.areaImpacted}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Disturbance Level
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.disturbanceLevel}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Scope
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>{opportunityScore.scope}</span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Enterprise Type
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.enterpriseType}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Complexity-Social
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.complexitySocial}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Complexity-Objectives
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {
                                              opportunityScore.complexityObjectives
                                            }
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Risk
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.overAllRisk}
                                          </span>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="cardInnerTitle">
                                          Cost Per Hectare
                                        </span>
                                        <span className="cardScore">
                                          <span className="colon">: </span>
                                          <span>
                                            {opportunityScore.costPerHectare}
                                          </span>
                                        </span>
                                      </li>
                                    </div>
                                  )
                                )
                              : null}
                          </ul>
                        </div>
                      </div>
                      <div className="prolistinner_list disturbanceImpact">
                        <div className="prolist_tree">
                          <img src={disturbanceImpact} alt="trees plant" />
                        </div>
                        <div className="prolist_treedet">
                          <span style={{ color: item.disturbanceColorValue }}>
                            Disturbance Impact Level
                          </span>
                          <span
                            style={{
                              color: item.disturbanceColorValue,
                              "font-weight": "bold",
                            }}
                          >
                            {item.disturbanceImpactScore}
                          </span>
                          <div className="tooltiptext">
                            This score indicates the net destruction caused by
                            the disturbance to the ecosystem. It takes into
                            account the difference of severity level on
                            different areas impacted within the project site.
                          </div>
                        </div>
                      </div>
                      {/* Added by Shobana - Disturbance and Opportunity Calculation start */}
                      <div className="prolistinner_list">
                        <div className="prolist_tree">
                          <img src={treesplantnew} alt="trees plant" />
                        </div>
                        <div className="prolist_treedet">
                          <span className="prolisttreedet_lbl">
                            Trees Planted Till Date
                          </span>
                          <span className="prolisttreedet_val">
                            {item.treePlantedTillDate}{" "}
                            <span className="prolisttreedet_valsource">
                              {item?.treeBySourceUsed !== "None"
                                ? `(${item.treeBySourceUsed})`
                                : ""}
                            </span>
                          </span>
                        </div>
                        {/* <div className="prolist_progress">
                      <div className="curvtext">
                        <span className="c1">f</span>
                        <span className="c2">o</span>
                        <span className="c3">r</span>
                        <span className="c4">e</span>
                        <span className="c5">s</span>
                        <span className="c6">t</span>
                        <span className="c7">h</span>
                        <span className="c8">e</span>
                        <span className="c9">a</span>
                        <span className="c10">l</span>
                        <span className="c11">t</span>
                        <span className="c12">h</span>
                        <span className="c13">s</span>
                        <span className="c14">c</span>
                        <span className="c15">o</span>
                        <span className="c16">r</span>
                        <span className="c17">e</span>
                      </div>

                      <CircularProgressbar
                        width={150}
                        height={150}
                        value={item.forestHealthyPercentage}
                        text={`${Number(item.forestHealthyPercentage).toFixed(
                          1
                        )}%`}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          textColor: "#333",
                          pathColor:
                            item.isForestHealthy == true
                              ? "#95C13D"
                              : "#B54E56",
                          trailColor: "#D9D9D9",
                        })}
                      />
                    </div> */}
                      </div>

                      {/* <div className="prolistinner_list coemicont">
                      <div className="prolist_tree">
                        <img src={co2emission} alt="CO2 emission" />
                      </div>
                      <div className="prolist_treedet">
                        <span className="prolisttreedet_lbl">
                          Carbon Sequestered - Till Now
                        </span>
                        <span className="prolisttreedet_val">
                          {(item.co2Nutrilized * 1000).toFixed(2)} Kg
                        </span>
                      </div>
                    </div> */}

                      {item?.amountRaised !== "0" && (
                        <div className="prolistinner_list fundraisedlistiutem_cont">
                          <div className="prolist_tree">
                            <img src={amtraised} alt="amount raised" />
                          </div>
                          <div className="prolist_treedet">
                            <span className="prolisttreedet_lbl">
                              Amount Raised
                            </span>
                            <span className="prolisttreedet_val">
                              RS. {Math.ceil(item?.amountRaised / 100000)} L
                            </span>
                            <span className="totalfndrsedamt_cont">
                              ₹ {Math.ceil(item?.fundraiserGoal / 100000)} L
                            </span>
                            <div className="prolist_progress">
                              <Progress
                                value={
                                  (item?.amountRaised / item?.fundraiserGoal) *
                                  100
                                }
                                size={ProgressSize.sm}
                                width="100%"
                                fill="#333"
                                path="#fff"
                              />
                            </div>
                          </div>

                          {/* <CircularProgressbar
                        width={150}
                        height={150}
                        value={
                          (item.amountRaised === "0" || item.amountRaised === ""
                            ? 0
                            : item.amountRaised / item.fundraiserGoal) * 100
                        }
                        text={
                          item.amountRaised === "0" || item.amountRaised === ""
                            ? 0
                            : `${Number(
                                (item.amountRaised / item.fundraiserGoal) * 100
                              ).toFixed(1)}%`
                        }
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          textColor: "#333",
                          pathColor: "#95C13D",
                          trailColor: "#D9D9D9",
                        })}
                      /> */}
                        </div>
                      )}
                      {item?.area && (
                        <div className="prolistinner_list">
                          <div className="prolist_tree">
                            <img src={disturbanceImpact} alt="Land Area" />
                          </div>
                          <div className="prolist_treedet">
                            <span className="prolisttreedet_lbl">
                              Land Area(in acres)
                            </span>{" "}
                            <span className="prolisttreedet_val">
                              {" "}
                              {/* {(item.landareasqm !== ""
                            ? item.landareasqm * 0.000247105
                            : 0
                          ).toFixed(2)} */}
                              {typeof item?.area === "number"
                                ? item?.area.toFixed(2)
                                : parseFloat(item?.area).toFixed(2)}{" "}
                            </span>
                          </div>
                        </div>
                      )}
                      {/* <div className="campaign_txt">Campaign closes in 12 months 10 days</div> */}
                    </a>
                    <div>
                      {this.state.ProjectList[0]?.regression?.filter(
                        (reg) => reg.projectId === item.projectId
                      )?.length > 0 && (
                        <table>
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Estimated Range</th>
                              <th>Average</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ProjectList[0]?.regression
                              ?.filter(
                                (reg) => reg.projectId === item.projectId
                              )
                              ?.map((reg, i) => (
                                <tr key={i}>
                                  <td>{reg?.type}</td>
                                  <td>{reg?.estimatedRange}</td>
                                  <td>{reg?.average}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(Projects);
