import { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import totaltreesplanted from "../../../assets/totaltrees_planted.png";
import treesplantnew from "../../../assets/prolist_newtree.png";
import disturbanceImpact from "../../../assets/disturbanceImpact.png";
import co2emission from "../../../assets/CO2-emission.png";
import amtraised from "../../../assets/amount-raised.png";
import config from "../../../utils/config";
import { navigate } from "@reach/router";
import ProjectBoundary from "./ProjectBoundary";

export default function GoogleMapNew(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: props.GoogleMapApiKey,
  });

  if (isLoaded == false) return <div>Loading...</div>;
  return (
    <Map
      ProjectList={props.ProjectList}
      ProjectDetailData={props.ProjectDetailData}
      ProjectStatus={props.ProjectStatus}
      UserId={props.UserId}
    ></Map>
  );
}

function Map(props) {
  const [zoomLevel, setZoomLevel] = useState(2);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [projectSiderbar, setProjectSiderbar] = useState(false);
  const [singleMarkerData, setSingleMarkerData] = useState([]);
  const [allProjectDetails, setAllProjectDetails] = useState([]);
  const [markerData, setMarkerData] = useState([]);
  const [center, setCenter] = useState({ lat: "", lng: "" });
  const [showBoundary, setShowBoundary] = useState(false);
  const [filterLocationCords, setFilterLocationCords] = useState([]);
  const [projectIndex, setProjectIndex] = useState(0);

  useEffect(() => {
    if (props?.ProjectList) {
      setAllProjectDetails(props.ProjectList);
      let newCords = [];
      let newCenterCords = [];
      props?.ProjectList?.map((item) => {
        let obj = {};
        obj["id"] = item.projectId;
        obj["lat"] = item?.locationCords?.[1];
        obj["lng"] = item?.locationCords?.[0];
        newCords.push(obj);
        let centerCords = {};
        centerCords["lat"] = item?.locationCords?.[1];
        centerCords["lng"] = item?.locationCords?.[0];
        newCenterCords.push(centerCords);
      });
      setMarkerData(newCords);
      setCenter(newCenterCords[0]);
    }
  }, [props.ProjectList, props.ProjectDetailData]);

  const mapOption = {
    mapTypeControl: false,
    mapTypeId: window.google.maps.MapTypeId.SATELLITE,
    mapTypeControlOptions: {
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      mapTypeIds: [
        window.google.maps.MapTypeId.ROADMAP,
        window.google.maps.MapTypeId.SATELLITE,
        window.google.maps.MapTypeId.HYBRID,
      ],
    },
    scaleControl: true,
    keyboardShortcuts: false,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: window.google.maps.ControlPosition.BOTTOM_LEFT,
    },
  };

  const toggleSidebar = (open) => {
    setIsSidebarOpen(open);
  };

  const markerChanges = (proid, index) => {
    setProjectIndex(index);
    setProjectSiderbar(true);
    setShowBoundary(true);

    let filterProject = allProjectDetails.filter((p) => p.projectId === proid);
    setFilterLocationCords(filterProject[0].locationCords);

    let centerCords = {};
    centerCords["lat"] = filterProject[0].locationCords?.[1];
    centerCords["lng"] = filterProject[0].locationCords?.[0];

    setCenter(centerCords);
    setZoomLevel(16);

    GetAllProjectDetails(props.ProjectStatus, proid, props.UserId);
  };

  const GetAllProjectDetails = (ProjectStatus, ProjectId, UserId) => {
    let UserDetail = {
      ProjectId: ProjectId,
      Status: ProjectStatus,
      UserId: UserId,
    };
    let url = `${config.API_HOST_URL}/api/Projects/GetProjectDetails`;
    fetch(url, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(UserDetail),
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        if (responseData !== null) {
          setSingleMarkerData(responseData);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const goToViewPage = (projectId) => {
    navigate("/projectdetailsnew/:" + projectId + "/:" + (projectIndex + 1));
  };

  const resetView = () => {
    let newCords = [];
    let newCenterCords = [];
    props?.ProjectList?.map((item) => {
      let obj = {};
      obj["id"] = item.projectId;
      obj["lat"] = item?.locationCords?.[1];
      obj["lng"] = item?.locationCords?.[0];
      newCords.push(obj);
      let centerCords = {};
      centerCords["lat"] = item?.locationCords?.[1];
      centerCords["lng"] = item?.locationCords?.[0];
      newCenterCords.push(centerCords);
    });
    setMarkerData(newCords);
    setCenter(newCenterCords[0]);
    setZoomLevel(2);
    setProjectSiderbar(false);
  };

  return (
    <div className="mapClass">
      {projectSiderbar ? (
        <div className="root-hamburger-menu">
          <button
            className={`hamburger-menu ${
              isSidebarOpen ? "iconmapsidebar" : ""
            }`}
          >
            <a
              className="opensidebarbtn"
              onClick={() => toggleSidebar(!isSidebarOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <mask
                  id="mask0_2937_7915"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2937_7915)">
                  <path
                    d="M2 21V19H22V21H2ZM3 18V11H6V18H3ZM8 18V6H11V18H8ZM13 18V9H16V18H13ZM18 18V3H21V18H18Z"
                    fill="white"
                  />
                </g>
              </svg>
            </a>

            <span>
              {singleMarkerData?.projectName
                ? singleMarkerData?.projectName
                : ""}
            </span>
            <a
              className="prosumrymodal_close"
              onClick={() => toggleSidebar(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M1.42539 9.98745L0.0253906 8.58745L3.62539 4.98745L0.0253906 1.41245L1.42539 0.0124512L5.02539 3.61245L8.60039 0.0124512L10.0004 1.41245L6.40039 4.98745L10.0004 8.58745L8.60039 9.98745L5.02539 6.38745L1.42539 9.98745Z"
                  fill="white"
                />
              </svg>
            </a>
          </button>

          <aside
            className={`hamburger-menu-sidebar ${
              isSidebarOpen ? "togglemapsidebar" : ""
            }`}
          >
            {/* <a className="backmainmapview" onClick={() => resetView()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10.9991 11.9993L17.9988 18.9991L15.9993 21L7 11.9993L15.9993 3L17.9988 4.99953L10.9991 11.9993Z"
                  fill="#50B041"
                />
              </svg>
              <span>Back</span>
            </a> */}
            <div className="prolisttime_cont">
              <span>
                <i className="icon icon-location-pin"></i>
                {singleMarkerData?.location ? singleMarkerData?.location : ""}
              </span>
            </div>
            {singleMarkerData?.isForestHealthy === true ? (
              <div className="typeofverify_forest hlthyforbtn">
                Forest is Healthy
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M13.5 15.75H6V6L11.25 0.75L12.1875 1.6875C12.275 1.775 12.347 1.89375 12.4035 2.04375C12.46 2.19375 12.488 2.3375 12.4875 2.475V2.7375L11.6625 6H15.75C16.15 6 16.5 6.15 16.8 6.45C17.1 6.75 17.25 7.1 17.25 7.5V9C17.25 9.0875 17.2407 9.18125 17.2222 9.28125C17.2037 9.38125 17.1755 9.475 17.1375 9.5625L14.8875 14.85C14.775 15.1 14.5875 15.3125 14.325 15.4875C14.0625 15.6625 13.7875 15.75 13.5 15.75ZM4.5 6V15.75H1.5V6H4.5Z"
                    fill="white"
                  />
                </svg>
              </div>
            ) : (
              <div className="typeofverify_forest unhlthyforbtn">
                Forest is UnHealthy
              </div>
            )}

            <ul className="statisticslist_cont">
              <li className="prolistinner_list">
                <div className="prolist_tree">
                  <img src={treesplantnew} alt="Totaltrees planted" />
                </div>
                <div className="prolist_treedet">
                  <span className="prolisttreedet_lbl">
                    Total Trees Planted
                  </span>
                  <span className="prolisttreedet_val">
                    {singleMarkerData?.totalTreesPlanted} K{" "}
                    {singleMarkerData?.treeBySourceUsed !== "None"
                      ? `(${singleMarkerData?.treeBySourceUsed})`
                      : ""}
                  </span>
                </div>
              </li>
              <li className="prolistinner_list">
                <div className="prolist_tree">
                  <img src={co2emission} alt="CO2 emission" />
                </div>
                {/* <div className="prolist_treedet">
                  <span className="prolisttreedet_lbl">
                    Carbon Sequestered - Till now
                  </span>
                  <span className="prolisttreedet_val">
                    {singleMarkerData?.carbonSequestered
                      ? (singleMarkerData?.carbonSequestered * 1000).toFixed(2)
                      : 0}{" "}
                    Kg
                  </span>
                </div> */}
              </li>
              <li className="prolistinner_list">
                <div className="prolist_tree">
                  <img src={amtraised} alt="Amount raised" />
                </div>
                <div className="prolist_treedet">
                  <span className="prolisttreedet_lbl">Amount Raised</span>
                  <span className="prolisttreedet_val">
                    ₹ {singleMarkerData?.amountRaised?.toLocaleString()}
                  </span>
                </div>
              </li>
              <li className="prolistinner_list">
                <div className="prolist_tree">
                  <img src={disturbanceImpact} alt="Land Area" />
                </div>
                <div className="prolist_treedet">
                  <span className="prolisttreedet_lbl">
                    Land Area (in Acres)
                  </span>
                  <span className="prolisttreedet_val">
                    {singleMarkerData.landArea}
                  </span>
                </div>
              </li>
            </ul>
            <div className="sidebarfoot_cont">
              <a className="backtoprojects_btn" onClick={() => resetView()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M5 10L0 5L5 0L5.8875 0.8875L1.775 5L5.8875 9.1125L5 10Z"
                    fill="#50B041"
                  />
                </svg>
                <span>Back to projects</span>
              </a>
              <a
                className="projectspg_viewdetlsbtn"
                onClick={() => goToViewPage(singleMarkerData.projectId)}
              >
                <span>Project Details</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                >
                  <path
                    d="M0 4.11614V5.88386H10.7824L8.05656 8.75022L9.24592 10L14 5L9.24592 0L8.05656 1.24978L10.7824 4.11614H0Z"
                    fill="#50B041"
                  />
                </svg>
              </a>
            </div>
          </aside>
        </div>
      ) : (
        <div className="root-hamburger-menu">
          <button
            className={`hamburger-menu ${
              isSidebarOpen ? "iconmapsidebar" : ""
            }`}
          >
            <a
              className="opensidebarbtn"
              onClick={() => toggleSidebar(!isSidebarOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <mask
                  id="mask0_2937_7915"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2937_7915)">
                  <path
                    d="M2 21V19H22V21H2ZM3 18V11H6V18H3ZM8 18V6H11V18H8ZM13 18V9H16V18H13ZM18 18V3H21V18H18Z"
                    fill="white"
                  />
                </g>
              </svg>
            </a>

            <span>Statistics</span>
            <a
              className="prosumrymodal_close"
              onClick={() => toggleSidebar(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M1.42539 9.98745L0.0253906 8.58745L3.62539 4.98745L0.0253906 1.41245L1.42539 0.0124512L5.02539 3.61245L8.60039 0.0124512L10.0004 1.41245L6.40039 4.98745L10.0004 8.58745L8.60039 9.98745L5.02539 6.38745L1.42539 9.98745Z"
                  fill="white"
                />
              </svg>
            </a>
          </button>

          <aside
            className={`hamburger-menu-sidebar ${
              isSidebarOpen ? "togglemapsidebar" : ""
            }`}
          >
            {/* <a className="close-sidebar-btn" onClick={() => resetView()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10.9991 11.9993L17.9988 18.9991L15.9993 21L7 11.9993L15.9993 3L17.9988 4.99953L10.9991 11.9993Z"
                  fill="white"
                />
              </svg>
              <span>Back</span> 
            </a>*/}
            <ul className="statisticslist_cont">
              <li className="prolistinner_list">
                <div className="prolist_tree">
                  <img src={totaltreesplanted} alt="Totaltrees planted" />
                </div>
                <div className="prolist_treedet">
                  <span className="prolisttreedet_lbl">Total Projects</span>
                  <span className="prolisttreedet_val">
                    {props?.ProjectDetailData?.totalProjects}
                  </span>
                </div>
              </li>
              <li className="prolistinner_list">
                <div className="prolist_tree">
                  <img src={treesplantnew} alt="Totaltrees planted" />
                </div>
                <div className="prolist_treedet">
                  <span className="prolisttreedet_lbl">
                    Total Trees Planted
                  </span>
                  <span className="prolisttreedet_val">
                    {props?.ProjectDetailData?.totalTreesPlanted} K
                  </span>
                </div>
              </li>
              <li className="prolistinner_list">
                <div className="prolist_tree">
                  <img src={co2emission} alt="CO2 emission" />
                </div>
                {/* <div className="prolist_treedet">
                  <span className="prolisttreedet_lbl">
                    Carbon Sequestered - Till now
                  </span>
                  <span className="prolisttreedet_val">
                    {props?.ProjectDetailData?.carbonSequestered
                      ? (
                          props?.ProjectDetailData?.carbonSequestered * 1000
                        ).toFixed(2)
                      : 0}
                    Kg
                  </span>
                </div> */}
              </li>
              <li className="prolistinner_list">
                <div className="prolist_tree">
                  <img src={amtraised} alt="Amount raised" />
                </div>
                <div className="prolist_treedet">
                  <span className="prolisttreedet_lbl">Amount Raised</span>
                  <span className="prolisttreedet_val">
                    ₹ {props?.ProjectDetailData?.amountRaised?.toLocaleString()}
                  </span>
                </div>
              </li>
              <li className="prolistinner_list">
                <div className="prolist_tree">
                  <img src={disturbanceImpact} alt="Land Area" />
                </div>
                <div className="prolist_treedet">
                  <span className="prolisttreedet_lbl">
                    Land Area (in Acres)
                  </span>
                  <span className="prolisttreedet_val">
                    {props?.ProjectDetailData?.landArea}
                  </span>
                </div>
              </li>
            </ul>
          </aside>
        </div>
      )}

      <GoogleMap
        options={mapOption}
        defaultZoom={zoomLevel}
        zoom={zoomLevel}
        defaultCenter={center}
        center={center}
        mapContainerStyle={mapStyle.map_container}
      >
        <MarkerClusterer>
          {(clusterer) =>
            markerData.map((marker, index) => (
              <Marker
                key={marker.id}
                //key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                clusterer={clusterer}
                onClick={() => markerChanges(marker.id, index)}
              />
            ))
          }
        </MarkerClusterer>
        {showBoundary && (
          <ProjectBoundary locationCords={filterLocationCords} />
        )}
      </GoogleMap>
    </div>
  );
}

const mapStyle = {
  map_container: {
    width: "100%",
    height: "100vh",
  },
};
